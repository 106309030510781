import type { CTextLink } from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import { createContext, useContext, type PropsWithChildren } from 'react';

const CustomFooterContext = createContext<Props['config']>({
  language: 'en',
  country: 'au',

  theme: 'default',
});

export const useCustomFooterContext = () => {
  const context = useContext(CustomFooterContext);

  if (context === null) {
    throw new Error(
      'useCustomFooterContext must be used within a CustomFooterProvider',
    );
  }

  return context;
};

type Props = PropsWithChildren<{
  config: {
    language: Language;
    country: Country;

    links?: CTextLink[];
    backgroundColor?: string;
    theme?: 'default' | 'sara' | 'star';
  };
}>;

export const CustomFooterProvider = ({ config, children }: Props) => (
  <CustomFooterContext.Provider value={config}>
    {children}
  </CustomFooterContext.Provider>
);
