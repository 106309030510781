import {
  useGetLatestArticlesByCategoryQuery,
  Locale,
  Stage,
  type Category,
  type LatestArticlesByCategoryFragment,
} from '@seek/cmsu-cms-connect';
import { LatestArticlesByCategory } from '@seek/cmsu-components';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import React from 'react';
import { useConfig } from 'src/client/shared/hooks';
import { getHygraphLocales } from 'src/client/shared/utils/helper';
import { filterObjectsByContentLocaleOrDefault } from '@seek/cmsu-cms-connect/src/helpers/localizationHelper';
import { getHighestValuesFrom } from 'node_modules/@seek/cmsu-components/src/helpers/helper';

type Props = {
  latestArticlesByCategory: LatestArticlesByCategoryFragment & {
    ignoreArticleId?: string;
  };
};

export const LatestArticlesByCategoryLoader = ({
  latestArticlesByCategory,
}: Props) => {
  const { hygraphSite, hygraphLocale, language, brand } = useConfig();
  const {
    category: categoryInfo,
    maximumArticlesToDisplay,
    stage = Stage.Published,
    ignoreArticleId,
  } = latestArticlesByCategory;
  const categorySlug = categoryInfo?.slug;

  const totalArticles =
    getHighestValuesFrom(
      maximumArticlesToDisplay ?? { mobile: 3, tablet: 2, desktop: 3 },
    ) + 1;

  const { data } = useGetLatestArticlesByCategoryQuery({
    variables: {
      where: {
        slug: categorySlug,
        sites_some: { name: hygraphSite },
      },
      totalArticles,
      site: hygraphSite,
      locale: getHygraphLocales(hygraphLocale),
      localesWithDefault: [hygraphLocale, Locale.Default],
      stage,
      ignoredArticleId: ignoreArticleId,
    },
  });

  if (
    !(
      data &&
      data.categories.length > 0 &&
      data.categories[0].localizations.length > 0
    )
  )
    return;

  const category = (
    filterObjectsByContentLocaleOrDefault(
      data.categories[0].localizations,
      hygraphLocale,
    ) as Category[]
  )[0];

  return (
    <CMSUProvider config={{ language }}>
      <LatestArticlesByCategory
        category={category}
        language={language}
        brand={brand}
        latestArticlesByCategory={latestArticlesByCategory}
      />
    </CMSUProvider>
  );
};
