import {
  ApacCandidateHeader,
  ApacCandidateFooter,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';
import { useMelwaysLink } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';

import { useAuth, useConfig } from 'src/client/shared/hooks';
import { linkRenderer } from '../modules/renderers/linkRenderer';
import { Theme, type Section } from '@seek/cmsu-cms-connect';
import { CustomPageHeader, CustomPageFooter } from '@seek/cmsu-components';
import {
  getFooterConfig,
  getHeaderConfig,
} from 'src/client/shared/utils/helper';
import { RootSiteFooter } from '../Footers/RootSiteFooter';
import { Page } from 'braid-design-system';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
interface PageProps {
  children: React.ReactNode;
  section?: Partial<Section>;
  layout?: 'full' | 'basic' | 'none';
}

export const CandidateLayout = ({ children, section }: PageProps) => {
  const { country, language, hygraphLocale, route, routeArray, site } =
    useConfig();
  const { authenticationStatus, caAccountContext } = useAuth();
  const { sectionName, headerOptions, footerOptions, theme } = section || {};

  const urlResolver = useMelwaysLink();
  const path = route.split(`/${country}`).pop() || route;

  const getLanguageSwitcher = (c: Country) => {
    if (c !== 'id' && c !== 'th') {
      return undefined;
    }

    return {
      en: {
        url: urlResolver({ language: 'en', path }),
      },

      [country]: {
        url: urlResolver({ language: c, path }),
      },
    };
  };

  const { type, layout, logo, links, divider, linksAlignment } =
    getHeaderConfig(headerOptions);

  const footerConfig = getFooterConfig(footerOptions);

  let footer;
  if (footerConfig?.type === 'CustomFooter') {
    const {
      logo: footerLogo,
      links: footerLinks,
      backgroundColor: footerBackgroundColor,
    } = footerConfig;
    footer = (
      <CustomPageFooter
        country={country}
        locale={hygraphLocale}
        backgroundColor={footerBackgroundColor}
        logo={footerLogo}
        links={footerLinks}
        language={language}
      />
    );
  } else if (site !== 'candidate-seek-root') {
    /* Root sites are not compatible with the candiate-header-footer */
    footer = (
      <ApacCandidateFooter authenticationStatus={authenticationStatus} />
    );
  } else {
    footer = <RootSiteFooter />;
  }

  return (
    <CMSUProvider config={{ language, country }}>
      <ApacCandidateHeaderFooterLinkProvider value={linkRenderer}>
        <Page footer={footer}>
          {/* Root sites are not compatible with the candiate-header-footer
      due to melways-sites functions needing more information than exists
      for the root sites */}
          {type === 'SeekHeader' &&
          layout !== 'none' &&
          site !== 'candidate-seek-root' ? (
            <ApacCandidateHeader
              activePrimaryTab={
                sectionName === 'profile' ? sectionName : undefined
              }
              authenticationStatus={authenticationStatus}
              leanHeader={layout === 'basic' && true}
              languageSwitcher={getLanguageSwitcher(country)}
              userProfile={caAccountContext}
            />
          ) : (
            <CustomPageHeader
              divider={divider}
              logo={logo}
              links={links}
              language={language}
              route={routeArray}
              country={country}
              locale={hygraphLocale}
              linksAlignment={linksAlignment}
              theme={theme || Theme.SeekJobs}
            />
          )}
          {children}
        </Page>
      </ApacCandidateHeaderFooterLinkProvider>
    </CMSUProvider>
  );
};
