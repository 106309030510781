import React from 'react';
import type { ArticleItemFieldVisibility } from '../ArticleItem/articleItemHelper';
import type { Article } from '@seek/cmsu-cms-connect';
import {
  articleActionByType,
  getArticleCardFooter,
} from '../../helpers/articleHelper';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { Text } from 'braid-design-system';

type Props = {
  article: Article;
  fieldVisibility: ArticleItemFieldVisibility;
};

export const ArticleItemFooter = ({ article, fieldVisibility }: Props) => {
  const { t } = useTranslations(translations);

  const articleActionTranslated = t(articleActionByType(article.articleType));
  const articleItemFooterText = getArticleCardFooter({
    publishDate: fieldVisibility.showPublishDate
      ? article.publishDate
      : undefined,
    timeToRead: fieldVisibility.showTimeToRead ? article.timeToRead : undefined,
    articleActionTranslated,
  });

  if (!Boolean(articleItemFooterText)) return;

  return (
    <Text
      tone="secondary"
      size="small"
      data={{ testid: 'ArticleFooterTestId' }}
    >
      <div suppressHydrationWarning>{articleItemFooterText}</div>
    </Text>
  );
};
