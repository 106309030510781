import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Heading,
  IconChevron,
  IconMinus,
  IconProfile,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import translations from './.vocab';
import type { ProfileVisibilityConfigType } from './ProfileVisibilityCardGroup';

export const ProfileSampleCard = ({
  profileConfig,
}: {
  profileConfig: ProfileVisibilityConfigType;
}) => {
  const { t } = useTranslations(translations);
  const { canViewResume, isProfileHidden } = profileConfig;
  return (
    <Stack space={!isProfileHidden ? 'medium' : 'small'}>
      <Stack space="small">
        <Inline space="none">
          <Box background="neutralLight" borderRadius="full" padding="xsmall">
            {!isProfileHidden ? (
              <IconProfile size="large" />
            ) : (
              <IconMinus size="large" />
            )}
          </Box>
        </Inline>
        {!isProfileHidden && (
          <Stack space="xsmall">
            <Heading level="4">John Smith</Heading>
            <Text size="small" tone="secondary">
              {t('Updated 6 mths ago')}
            </Text>
            <Text size="small" tone="secondary">
              {t('Salary $60k+')}
            </Text>
          </Stack>
        )}
      </Stack>
      {!isProfileHidden ? (
        <ProfileSampleDetails canViewResume={canViewResume} />
      ) : (
        <HiddenDetails />
      )}
    </Stack>
  );
};

const ProfileSampleDetails = ({
  canViewResume,
}: {
  canViewResume: boolean;
}) => (
  <Stack space="medium">
    <WorkingExperience />
    <ViewCVButton canViewResume={canViewResume} />
    <MoreInfoButton />
  </Stack>
);

const WorkingExperience = () =>
  profileSampleDetailsData.map((data, i) => (
    <Stack space="xsmall" key={i}>
      <Text size="small" weight="medium">
        {data.position}
      </Text>
      <Text size="small">{data.period}</Text>
    </Stack>
  ));

const HiddenDetails = () => {
  const { t } = useTranslations(translations);
  return (
    <Text tone="secondary">
      {t(
        'The career history, skills, education and licences and certifications sections of your SEEK Profile will be available to employers with your job applications. Proactively searching employers will not be able to otherwise access your Profile.',
      )}
    </Text>
  );
};

const ViewCVButton = ({ canViewResume }: { canViewResume: boolean }) => {
  const { t } = useTranslations(translations);
  return canViewResume ? (
    <Inline space="none">
      <Button variant="ghost">{t('View CV')}</Button>
    </Inline>
  ) : (
    <Text size="small" tone="secondary">
      {t('CV not available')}
    </Text>
  );
};

const MoreInfoButton = () => {
  const { t } = useTranslations(translations);
  return (
    <Inline space={'xsmall'} alignY={'center'}>
      <Text size="small" weight="medium">
        {t('More')}
      </Text>
      <IconChevron direction="down" size="xsmall" />
    </Inline>
  );
};

const profileSampleDetailsData = [
  { position: 'Manager - Omni', period: 'Feb 12 - now' },
  { position: 'Team Leader - Omni', period: 'Dec 09 - Jan 12' },
  { position: 'Telesales - Omni', period: 'Jun 08 - Oct 09' },
];
