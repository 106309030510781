import { Box, ContentBlock } from 'braid-design-system';
import type {
  ArticleAdditionalContentFragment,
  LatestArticlesByCategoryFragment,
} from '@seek/cmsu-cms-connect';
import {
  BlockItemRenderer,
  getBoxBackgroundProps,
  getBoxShadowProps,
} from '@seek/cmsu-components';
import React from 'react';
import { useConfig } from 'src/client/shared/hooks';
import { pageRenderers } from 'src/helpers/pageRenderers';

interface Props {
  articleAdditionalContent: ArticleAdditionalContentFragment;
  articleId?: string;
  data: any;
}

export const AdditionalContent = ({
  articleAdditionalContent,
  articleId,
  data,
}: Props) => {
  const { fullURL } = useConfig();
  return (
    <Box
      maxWidth={articleAdditionalContent.box?.width || undefined}
      textAlign={articleAdditionalContent.box?.textAlign || 'left'}
      borderRadius={articleAdditionalContent.box?.borderRadius || 'none'}
      paddingY={
        articleAdditionalContent.box?.paddingHeight ||
        articleAdditionalContent.box?.background
          ? 'large'
          : 'none'
      }
      paddingX={
        articleAdditionalContent.box?.paddingWidth ||
        articleAdditionalContent.box?.background
          ? 'gutter'
          : { mobile: 'small', tablet: 'medium', desktop: 'none' }
      }
      {...getBoxBackgroundProps(articleAdditionalContent.box?.background)}
      {...getBoxShadowProps(articleAdditionalContent.box?.blockBorder)}
    >
      <ContentBlock width={articleAdditionalContent.box?.width || 'medium'}>
        {articleAdditionalContent.components &&
          articleAdditionalContent.components.map((c, index) => {
            let component = c;
            if (component.__typename === 'LatestArticlesByCategory') {
              component = {
                ...component,
                ignoreArticleId: articleId,
              } as LatestArticlesByCategoryFragment;
            }
            return (
              <BlockItemRenderer
                key={index}
                block={component as Required<typeof component>}
                renderers={pageRenderers}
                additionalData={{
                  page: data,
                  fullURL,
                }}
              />
            );
          })}
      </ContentBlock>
    </Box>
  );
};
