import React, { Fragment } from 'react';

import { defaultElements } from './defaultElements';
import type { RichTextProps } from './types';
import { RenderElements } from './RenderElements';
import { Box, Stack } from 'braid-design-system';
import { richTextContainer } from './RichText.css';

const Wrapper: RichTextProps['wrapper'] = ({ children }) => (
  <Stack space="gutter">{children}</Stack>
);

export function RichText({
  content,
  renderers: resolvers,
  wrapper = Wrapper,
  references,
  data,
}: RichTextProps) {
  // Shallow merge doensn't work here because if we spread over the elements, the
  // Asset object will be completly overriden by the resolvers. We need to keep
  // the default elements for the Asset that hasn't been writen.
  const assetRenderers = {
    ...defaultElements?.Asset,
    ...resolvers?.Asset,
  };

  const renderers: RichTextProps['renderers'] = {
    ...defaultElements,
    ...resolvers,
    Asset: assetRenderers,
  };

  if (!content) {
    return <Fragment />;
  }

  if (!Array.isArray(content) && !content.children) {
    return <Fragment />;
  }

  return (
    <Box className={richTextContainer}
      style={{ whiteSpace: 'pre-wrap' }}
      data={{ ...data, 'hygraph-field-api-id': 'content' }}
    >
      <RenderElements
        content={content}
        renderers={renderers}
        references={references}
        wrapper={wrapper}
      />
    </Box>
  );
}
