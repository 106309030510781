import type { HeadingFragment } from '@seek/cmsu-cms-connect';
import { Box, Heading as BraidHeading } from 'braid-design-system';
import { toLevel } from '../../utils';
import { renderIcon } from '../IconBlockGroup/renderer';
import { vars } from 'braid-design-system/css';
import { insertBetweenArrayItems } from '../../helpers/helper';

import * as styles from './Heading.css';
import { getResponsiveSpace } from '../../shared/helper';

type Props = Pick<HeadingFragment, 'text'> &
  Partial<
    Pick<
      HeadingFragment,
      | 'component'
      | 'level'
      | 'weight'
      | 'icon'
      | 'tone'
      | 'customHeadingSize'
      | 'paddingTopOverride'
    >
  > & {
    data?: React.ComponentProps<typeof BraidHeading>['data'];
  };

export const Heading = ({
  level,
  text,
  component,
  weight,
  icon,
  tone,
  data,
  customHeadingSize,
  paddingTopOverride,
}: Props) => {
  // Create an array of text chunks separated by the new line character (\n)
  // interspersed with <br /> elements.
  const textChunksWithLineBreaks = insertBetweenArrayItems(
    text.split('\\n'),
    <br />,
  );

  return (
    <Box paddingTop={getResponsiveSpace(paddingTopOverride)}>
      <BraidHeading
        level={toLevel(level)}
        // Using `as` here because we cannot type-check if a string is React.ElementType.
        // This will error if someone puts in the wrong type. Onus is on the user to make sure it's correct.
        component={(component as React.ElementType<any>) || undefined}
        weight={weight || 'regular'}
        icon={(icon && renderIcon(icon)) || undefined}
        data={data}
      >
        <Box
          component="span"
          style={{
            color: vars.foregroundColor[tone!] || undefined,
          }}
          className={[
            customHeadingSize ? styles.customSize[customHeadingSize] : '',
          ]}
        >
          {textChunksWithLineBreaks}
        </Box>
      </BraidHeading>
    </Box>
  );
};
