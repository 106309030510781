import type { TopJobGrowthData } from '../types';

// THIS IS GENERATED DATA FROM SCRIPT.JS
export const AU_TOP_GROWTH_JOBS_CLASSIFICATION_DATA: Record<
  string,
  TopJobGrowthData[]
> = {
  Accounting: [
    {
      rank: 1,
      roleTitle: 'Accounts Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 52,
      changeInJobAds: 33.3,
      averageSalary: 83187,
      changeInSalary: 7.83,
    },
    {
      rank: 2,
      roleTitle: 'Taxation Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 139,
      changeInJobAds: 26.4,
      averageSalary: 145174,
      changeInSalary: -3.67,
    },
    {
      rank: 3,
      roleTitle: 'Financial Planning and Analysis Analyst',
      industryTitle: 'Accounting',
      numberOfJobAds: 69,
      changeInJobAds: 25.5,
      averageSalary: 127567,
      changeInSalary: -2.42,
    },
    {
      rank: 4,
      roleTitle: 'Taxation Consultant',
      industryTitle: 'Accounting',
      numberOfJobAds: 46,
      changeInJobAds: 15,
      averageSalary: 97708,
      changeInSalary: 3.83,
    },
    {
      rank: 5,
      roleTitle: 'Client Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 81,
      changeInJobAds: 6.6,
      averageSalary: 119990,
      changeInSalary: 8.93,
    },
    {
      rank: 6,
      roleTitle: 'Business Services Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 147,
      changeInJobAds: 5.8,
      averageSalary: 134202,
      changeInSalary: 1.17,
    },
    {
      rank: 7,
      roleTitle: 'Financial Assistant',
      industryTitle: 'Accounting',
      numberOfJobAds: 39,
      changeInJobAds: -7.1,
      averageSalary: 68522,
      changeInSalary: 2.01,
    },
    {
      rank: 8,
      roleTitle: 'Business Advisory Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 32,
      changeInJobAds: -8.6,
      averageSalary: 143572,
      changeInSalary: 9.96,
    },
    {
      rank: 9,
      roleTitle: 'Financial Administrator',
      industryTitle: 'Accounting',
      numberOfJobAds: 58,
      changeInJobAds: -9.4,
      averageSalary: 74183,
      changeInSalary: -1.58,
    },
    {
      rank: 10,
      roleTitle: 'Payroll Administrator',
      industryTitle: 'Accounting',
      numberOfJobAds: 56,
      changeInJobAds: -9.7,
      averageSalary: 76022,
      changeInSalary: 0.56,
    },
    {
      rank: 11,
      roleTitle: 'Finance and Administration Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 32,
      changeInJobAds: -11.1,
      averageSalary: 75323,
      changeInSalary: 3.19,
    },
    {
      rank: 12,
      roleTitle: 'Office Administrator',
      industryTitle: 'Accounting',
      numberOfJobAds: 32,
      changeInJobAds: -13.5,
      averageSalary: 68833,
      changeInSalary: 1.59,
    },
    {
      rank: 13,
      roleTitle: 'Payroll Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 842,
      changeInJobAds: -15.5,
      averageSalary: 86763,
      changeInSalary: 5.54,
    },
    {
      rank: 14,
      roleTitle: 'Financial Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 478,
      changeInJobAds: -16.4,
      averageSalary: 78468,
      changeInSalary: 4.31,
    },
    {
      rank: 15,
      roleTitle: 'Accounts Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 405,
      changeInJobAds: -18,
      averageSalary: 72332,
      changeInSalary: 4.17,
    },
    {
      rank: 16,
      roleTitle: 'Support Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 44,
      changeInJobAds: -18.5,
      averageSalary: 77715,
      changeInSalary: 10.96,
    },
    {
      rank: 17,
      roleTitle: 'Accounts Assistant',
      industryTitle: 'Accounting',
      numberOfJobAds: 160,
      changeInJobAds: -18.8,
      averageSalary: 67494,
      changeInSalary: 2.04,
    },
    {
      rank: 18,
      roleTitle: 'Bookkeeper',
      industryTitle: 'Accounting',
      numberOfJobAds: 664,
      changeInJobAds: -20.1,
      averageSalary: 77024,
      changeInSalary: 4.9,
    },
    {
      rank: 19,
      roleTitle: 'Accounts Receivable Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 329,
      changeInJobAds: -24.2,
      averageSalary: 73316,
      changeInSalary: 3.61,
    },
    {
      rank: 20,
      roleTitle: 'Collections Officer',
      industryTitle: 'Accounting',
      numberOfJobAds: 36,
      changeInJobAds: -25,
      averageSalary: 74164,
      changeInSalary: 5.78,
    },
  ],
  'Administration & Office Support': [
    {
      rank: 1,
      roleTitle: 'Management Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 51,
      changeInJobAds: 37.8,
      averageSalary: 83866,
      changeInSalary: 3.72,
    },
    {
      rank: 2,
      roleTitle: 'Operations Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 62,
      changeInJobAds: 37.8,
      averageSalary: 82141,
      changeInSalary: 15.24,
    },
    {
      rank: 3,
      roleTitle: 'Customer Service Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 39,
      changeInJobAds: 30,
      averageSalary: 59634,
      changeInSalary: 4.92,
    },
    {
      rank: 4,
      roleTitle: 'Legal Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 74,
      changeInJobAds: 27.6,
      averageSalary: 71701,
      changeInSalary: 7.78,
    },
    {
      rank: 5,
      roleTitle: 'Sales Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 53,
      changeInJobAds: 26.2,
      averageSalary: 60652,
      changeInSalary: 2.03,
    },
    {
      rank: 6,
      roleTitle: 'Administration Allrounder',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 51,
      changeInJobAds: 24.4,
      averageSalary: 65157,
      changeInSalary: 5.91,
    },
    {
      rank: 7,
      roleTitle: 'Contracts Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 36,
      changeInJobAds: 20,
      averageSalary: 95592,
      changeInSalary: -2.11,
    },
    {
      rank: 8,
      roleTitle: 'Operations Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 106,
      changeInJobAds: 11.6,
      averageSalary: 71599,
      changeInSalary: 3.66,
    },
    {
      rank: 9,
      roleTitle: 'Scheduling Coordinator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 37,
      changeInJobAds: 8.8,
      averageSalary: 70056,
      changeInSalary: 7.41,
    },
    {
      rank: 10,
      roleTitle: 'Team Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 100,
      changeInJobAds: 8.7,
      averageSalary: 72929,
      changeInSalary: 1.96,
    },
    {
      rank: 11,
      roleTitle: 'Compliance Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 52,
      changeInJobAds: 8.3,
      averageSalary: 77673,
      changeInSalary: -0.42,
    },
    {
      rank: 12,
      roleTitle: 'Legal Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 45,
      changeInJobAds: 7.1,
      averageSalary: 68243,
      changeInSalary: 8.53,
    },
    {
      rank: 13,
      roleTitle: 'Services Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 258,
      changeInJobAds: 5.7,
      averageSalary: 69022,
      changeInSalary: 6.07,
    },
    {
      rank: 14,
      roleTitle: 'Customer Service Coordinator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 71,
      changeInJobAds: 4.4,
      averageSalary: 65834,
      changeInSalary: 2.07,
    },
    {
      rank: 15,
      roleTitle: 'Construction Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 50,
      changeInJobAds: 4.2,
      averageSalary: 76082,
      changeInSalary: 2.73,
    },
    {
      rank: 16,
      roleTitle: 'Service Manager',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 34,
      changeInJobAds: 3,
      averageSalary: 93416,
      changeInSalary: 7.02,
    },
    {
      rank: 17,
      roleTitle: 'Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 54,
      changeInJobAds: 0,
      averageSalary: 69802,
      changeInSalary: 5.55,
    },
    {
      rank: 18,
      roleTitle: 'Finance and Administration Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 35,
      changeInJobAds: 0,
      averageSalary: 74173,
      changeInSalary: 9.52,
    },
    {
      rank: 19,
      roleTitle: 'Support Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 136,
      changeInJobAds: -1.4,
      averageSalary: 67695,
      changeInSalary: -0.24,
    },
    {
      rank: 20,
      roleTitle: 'Relationship Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 51,
      changeInJobAds: -1.9,
      averageSalary: 68856,
      changeInSalary: 0.86,
    },
  ],
  'Advertising, Arts & Media': [
    {
      rank: 1,
      roleTitle: 'Photographer',
      industryTitle: 'Advertising, Arts & Media',
      numberOfJobAds: 82,
      changeInJobAds: -23.4,
      averageSalary: 63872,
      changeInSalary: -1.47,
    },
    {
      rank: 2,
      roleTitle: 'Account Manager',
      industryTitle: 'Advertising, Arts & Media',
      numberOfJobAds: 71,
      changeInJobAds: -24.5,
      averageSalary: 93279,
      changeInSalary: 8.02,
    },
    {
      rank: 3,
      roleTitle: 'Reporter',
      industryTitle: 'Advertising, Arts & Media',
      numberOfJobAds: 45,
      changeInJobAds: -25,
      averageSalary: 78012,
      changeInSalary: 1.7,
    },
    {
      rank: 4,
      roleTitle: 'Journalist',
      industryTitle: 'Advertising, Arts & Media',
      numberOfJobAds: 66,
      changeInJobAds: -34.7,
      averageSalary: 70922,
      changeInSalary: -4.18,
    },
  ],
  'Banking & Financial Services': [
    {
      rank: 1,
      roleTitle: 'Lending Manager',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 79,
      changeInJobAds: 163.3,
      averageSalary: 133903,
      changeInSalary: 7.82,
    },
    {
      rank: 2,
      roleTitle: 'Lending Specialist',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 112,
      changeInJobAds: 55.6,
      averageSalary: 87112,
      changeInSalary: 3.84,
    },
    {
      rank: 3,
      roleTitle: 'Compliance Officer',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 60,
      changeInJobAds: 36.4,
      averageSalary: 92990,
      changeInSalary: 0.12,
    },
    {
      rank: 4,
      roleTitle: 'Banking Consultant',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 198,
      changeInJobAds: 31.1,
      averageSalary: 63462,
      changeInSalary: 5.5,
    },
    {
      rank: 5,
      roleTitle: 'Customer Service Specialist',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 43,
      changeInJobAds: 26.5,
      averageSalary: 75193,
      changeInSalary: 9.74,
    },
    {
      rank: 6,
      roleTitle: 'Customer Adviser',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 183,
      changeInJobAds: 23.6,
      averageSalary: 64259,
      changeInSalary: 3.35,
    },
    {
      rank: 7,
      roleTitle: 'Services Officer',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 195,
      changeInJobAds: 16.1,
      averageSalary: 74919,
      changeInSalary: 6.11,
    },
    {
      rank: 8,
      roleTitle: 'Credit Analyst',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 107,
      changeInJobAds: 3.9,
      averageSalary: 92362,
      changeInSalary: 0.53,
    },
    {
      rank: 9,
      roleTitle: 'Banking Specialist',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 144,
      changeInJobAds: 2.9,
      averageSalary: 67487,
      changeInSalary: 5.41,
    },
    {
      rank: 10,
      roleTitle: 'Paraplanner',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 56,
      changeInJobAds: -1.8,
      averageSalary: 87027,
      changeInSalary: 5.34,
    },
    {
      rank: 11,
      roleTitle: 'Customer Service Representative',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 173,
      changeInJobAds: -2.3,
      averageSalary: 63444,
      changeInSalary: 4.68,
    },
    {
      rank: 12,
      roleTitle: 'Settlements Officer',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 35,
      changeInJobAds: -5.4,
      averageSalary: 71830,
      changeInSalary: -2.5,
    },
    {
      rank: 13,
      roleTitle: 'Adviser',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 56,
      changeInJobAds: -6.7,
      averageSalary: 93740,
      changeInSalary: 5.21,
    },
    {
      rank: 14,
      roleTitle: 'Branch Manager',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 87,
      changeInJobAds: -16.3,
      averageSalary: 100561,
      changeInSalary: 1.56,
    },
    {
      rank: 15,
      roleTitle: 'Financial Analyst',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 33,
      changeInJobAds: -21.4,
      averageSalary: 121247,
      changeInSalary: 12.28,
    },
    {
      rank: 16,
      roleTitle: 'Support Officer',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 51,
      changeInJobAds: -22.7,
      averageSalary: 72435,
      changeInSalary: 8.73,
    },
    {
      rank: 17,
      roleTitle: 'Service Manager',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 85,
      changeInJobAds: -26.1,
      averageSalary: 86743,
      changeInSalary: 5.27,
    },
    {
      rank: 18,
      roleTitle: 'Processor',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 33,
      changeInJobAds: -26.7,
      averageSalary: 75750,
      changeInSalary: 9.72,
    },
    {
      rank: 19,
      roleTitle: 'Risk Analyst',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 34,
      changeInJobAds: -32,
      averageSalary: 112609,
      changeInSalary: -2.81,
    },
  ],
  'Call Centre & Customer Service': [
    {
      rank: 1,
      roleTitle: 'Relationship Officer',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 60,
      changeInJobAds: 53.8,
      averageSalary: 66092,
      changeInSalary: 0.56,
    },
    {
      rank: 2,
      roleTitle: 'Customer Experience Specialist',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 55,
      changeInJobAds: 44.7,
      averageSalary: 66637,
      changeInSalary: 5.38,
    },
    {
      rank: 3,
      roleTitle: 'Customer Service and Sales Consultant',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 90,
      changeInJobAds: 40.6,
      averageSalary: 64861,
      changeInSalary: 3.55,
    },
    {
      rank: 4,
      roleTitle: 'Customer Service Coordinator',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 84,
      changeInJobAds: 10.5,
      averageSalary: 68203,
      changeInSalary: 1.59,
    },
    {
      rank: 5,
      roleTitle: 'Sales Representative',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 107,
      changeInJobAds: 8.1,
      averageSalary: 68345,
      changeInSalary: 6.49,
    },
    {
      rank: 6,
      roleTitle: 'Customer Service Manager',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 92,
      changeInJobAds: 4.5,
      averageSalary: 93912,
      changeInSalary: 0.12,
    },
    {
      rank: 7,
      roleTitle: 'Support Specialist',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 60,
      changeInJobAds: 3.4,
      averageSalary: 63882,
      changeInSalary: -1.26,
    },
    {
      rank: 8,
      roleTitle: 'Claims Consultant',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 32,
      changeInJobAds: 3.2,
      averageSalary: 64946,
      changeInSalary: 1.96,
    },
    {
      rank: 9,
      roleTitle: 'Customer Service Administrator',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 34,
      changeInJobAds: 3,
      averageSalary: 65983,
      changeInSalary: 8.38,
    },
    {
      rank: 10,
      roleTitle: 'Team Leader',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 69,
      changeInJobAds: 3,
      averageSalary: 84627,
      changeInSalary: -2.77,
    },
    {
      rank: 11,
      roleTitle: 'Collections Officer',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 90,
      changeInJobAds: 1.1,
      averageSalary: 69584,
      changeInSalary: 0.59,
    },
    {
      rank: 12,
      roleTitle: 'Service Adviser',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 47,
      changeInJobAds: 0,
      averageSalary: 74152,
      changeInSalary: 7.43,
    },
    {
      rank: 13,
      roleTitle: 'Customer Service Adviser',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 68,
      changeInJobAds: -4.2,
      averageSalary: 64727,
      changeInSalary: 4.05,
    },
    {
      rank: 14,
      roleTitle: 'Services Officer',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 103,
      changeInJobAds: -7.2,
      averageSalary: 68804,
      changeInSalary: 3.76,
    },
    {
      rank: 15,
      roleTitle: 'Centre Operator',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 212,
      changeInJobAds: -8.6,
      averageSalary: 62730,
      changeInSalary: 2.96,
    },
    {
      rank: 16,
      roleTitle: 'Service Coordinator',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 30,
      changeInJobAds: -9.1,
      averageSalary: 71269,
      changeInSalary: 4.36,
    },
    {
      rank: 17,
      roleTitle: 'Care Consultant',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 186,
      changeInJobAds: -10.6,
      averageSalary: 61159,
      changeInSalary: 2.19,
    },
    {
      rank: 18,
      roleTitle: 'Customer Service Team Leader',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 69,
      changeInJobAds: -12.7,
      averageSalary: 81345,
      changeInSalary: -3.59,
    },
    {
      rank: 19,
      roleTitle: 'Appointment Setter',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 93,
      changeInJobAds: -13.9,
      averageSalary: 68053,
      changeInSalary: 6.13,
    },
    {
      rank: 20,
      roleTitle: 'Support Officer',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 178,
      changeInJobAds: -18.3,
      averageSalary: 67952,
      changeInSalary: 9.36,
    },
  ],
  'Community Services & Development': [
    {
      rank: 1,
      roleTitle: 'Assessor',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 85,
      changeInJobAds: 142.9,
      averageSalary: 88660,
      changeInSalary: 14.59,
    },
    {
      rank: 2,
      roleTitle: 'Support Practitioner',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 250,
      changeInJobAds: 127.3,
      averageSalary: 90714,
      changeInSalary: 2.31,
    },
    {
      rank: 3,
      roleTitle: 'Engagement Coordinator',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 81,
      changeInJobAds: 62,
      averageSalary: 85787,
      changeInSalary: 7.79,
    },
    {
      rank: 4,
      roleTitle: 'Mentor',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 262,
      changeInJobAds: 57.8,
      averageSalary: 71760,
      changeInSalary: 0.79,
    },
    {
      rank: 5,
      roleTitle: 'Peer Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 73,
      changeInJobAds: 52.1,
      averageSalary: 79861,
      changeInSalary: 7.9,
    },
    {
      rank: 6,
      roleTitle: 'Scheduler',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 57,
      changeInJobAds: 50,
      averageSalary: 70456,
      changeInSalary: 4.25,
    },
    {
      rank: 7,
      roleTitle: 'Wellbeing Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 51,
      changeInJobAds: 45.7,
      averageSalary: 80880,
      changeInSalary: 3.12,
    },
    {
      rank: 8,
      roleTitle: 'Domestic Assistant',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 186,
      changeInJobAds: 39.8,
      averageSalary: 64361,
      changeInSalary: 7.58,
    },
    {
      rank: 9,
      roleTitle: 'Carer',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 396,
      changeInJobAds: 38,
      averageSalary: 68134,
      changeInSalary: 5,
    },
    {
      rank: 10,
      roleTitle: 'Practitioner',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 225,
      changeInJobAds: 36.4,
      averageSalary: 94913,
      changeInSalary: 2.86,
    },
    {
      rank: 11,
      roleTitle: 'Partner',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 112,
      changeInJobAds: 34.9,
      averageSalary: 76740,
      changeInSalary: -1.92,
    },
    {
      rank: 12,
      roleTitle: 'Business Development Manager',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 50,
      changeInJobAds: 31.6,
      averageSalary: 108165,
      changeInSalary: 10.41,
    },
    {
      rank: 13,
      roleTitle: 'Care Manager',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 153,
      changeInJobAds: 29.7,
      averageSalary: 96208,
      changeInSalary: 3.61,
    },
    {
      rank: 14,
      roleTitle: 'Services Officer',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 120,
      changeInJobAds: 25,
      averageSalary: 75691,
      changeInSalary: 5.71,
    },
    {
      rank: 15,
      roleTitle: 'Family Violence Practitioner',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 66,
      changeInJobAds: 22.2,
      averageSalary: 101367,
      changeInSalary: 8,
    },
    {
      rank: 16,
      roleTitle: 'Specialist',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 84,
      changeInJobAds: 21.7,
      averageSalary: 94993,
      changeInSalary: 2.55,
    },
    {
      rank: 17,
      roleTitle: 'Cleaner',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 104,
      changeInJobAds: 16.9,
      averageSalary: 61342,
      changeInSalary: 1.89,
    },
    {
      rank: 18,
      roleTitle: 'Health Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 118,
      changeInJobAds: 15.7,
      averageSalary: 84245,
      changeInSalary: 5.07,
    },
    {
      rank: 19,
      roleTitle: 'Youth Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 763,
      changeInJobAds: 14.4,
      averageSalary: 84036,
      changeInSalary: 2.64,
    },
    {
      rank: 20,
      roleTitle: 'Area Coordinator',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 63,
      changeInJobAds: 12.5,
      averageSalary: 85520,
      changeInSalary: 0.41,
    },
  ],
  Construction: [
    {
      rank: 1,
      roleTitle: 'Certifier',
      industryTitle: 'Construction',
      numberOfJobAds: 52,
      changeInJobAds: 73.3,
      averageSalary: 114317,
      changeInSalary: 26.83,
    },
    {
      rank: 2,
      roleTitle: 'Building Supervisor',
      industryTitle: 'Construction',
      numberOfJobAds: 131,
      changeInJobAds: 72.4,
      averageSalary: 106840,
      changeInSalary: 0.55,
    },
    {
      rank: 3,
      roleTitle: 'Estimator Supervisor',
      industryTitle: 'Construction',
      numberOfJobAds: 67,
      changeInJobAds: 59.5,
      averageSalary: 102054,
      changeInSalary: 4.52,
    },
    {
      rank: 4,
      roleTitle: 'Bobcat Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 47,
      changeInJobAds: 34.3,
      averageSalary: 81071,
      changeInSalary: 1.46,
    },
    {
      rank: 5,
      roleTitle: 'Health and Safety Adviser',
      industryTitle: 'Construction',
      numberOfJobAds: 58,
      changeInJobAds: 20.8,
      averageSalary: 137468,
      changeInSalary: -4.25,
    },
    {
      rank: 6,
      roleTitle: 'Project Administrator',
      industryTitle: 'Construction',
      numberOfJobAds: 64,
      changeInJobAds: 20.8,
      averageSalary: 86291,
      changeInSalary: -1.18,
    },
    {
      rank: 7,
      roleTitle: 'Driller',
      industryTitle: 'Construction',
      numberOfJobAds: 61,
      changeInJobAds: 17.3,
      averageSalary: 98000,
      changeInSalary: 10.38,
    },
    {
      rank: 8,
      roleTitle: 'Health Safety Environment and Quality Adviser',
      industryTitle: 'Construction',
      numberOfJobAds: 86,
      changeInJobAds: 16.2,
      averageSalary: 121495,
      changeInSalary: -0.64,
    },
    {
      rank: 9,
      roleTitle: 'Civil Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 61,
      changeInJobAds: 15.1,
      averageSalary: 94140,
      changeInSalary: 3.2,
    },
    {
      rank: 10,
      roleTitle: 'Machine Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 145,
      changeInJobAds: 7.4,
      averageSalary: 82014,
      changeInSalary: 8.56,
    },
    {
      rank: 11,
      roleTitle: 'Pump Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 48,
      changeInJobAds: 6.7,
      averageSalary: 90885,
      changeInSalary: -1.3,
    },
    {
      rank: 12,
      roleTitle: 'Cadet',
      industryTitle: 'Construction',
      numberOfJobAds: 38,
      changeInJobAds: 5.6,
      averageSalary: 60200,
      changeInSalary: 4.34,
    },
    {
      rank: 13,
      roleTitle: 'Loader Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 73,
      changeInJobAds: 4.3,
      averageSalary: 84010,
      changeInSalary: 5.74,
    },
    {
      rank: 14,
      roleTitle: 'Excavator Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 581,
      changeInJobAds: 1.4,
      averageSalary: 86573,
      changeInSalary: 5.67,
    },
    {
      rank: 15,
      roleTitle: 'Crew Member',
      industryTitle: 'Construction',
      numberOfJobAds: 31,
      changeInJobAds: 0,
      averageSalary: 70175,
      changeInSalary: 4.01,
    },
    {
      rank: 16,
      roleTitle: 'Leading Hand',
      industryTitle: 'Construction',
      numberOfJobAds: 241,
      changeInJobAds: -1.6,
      averageSalary: 96010,
      changeInSalary: 3.23,
    },
    {
      rank: 17,
      roleTitle: 'Roller Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 41,
      changeInJobAds: -2.4,
      averageSalary: 80872,
      changeInSalary: 10.97,
    },
    {
      rank: 18,
      roleTitle: 'Locator',
      industryTitle: 'Construction',
      numberOfJobAds: 38,
      changeInJobAds: -5,
      averageSalary: 86242,
      changeInSalary: 6.06,
    },
    {
      rank: 19,
      roleTitle: 'Plant Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 573,
      changeInJobAds: -6.4,
      averageSalary: 77867,
      changeInSalary: 1.54,
    },
    {
      rank: 20,
      roleTitle: 'Rigger',
      industryTitle: 'Construction',
      numberOfJobAds: 72,
      changeInJobAds: -6.5,
      averageSalary: 100026,
      changeInSalary: 2.28,
    },
  ],
  'Consulting & Strategy': [
    {
      rank: 1,
      roleTitle: 'Policy Officer',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 56,
      changeInJobAds: 9.8,
      averageSalary: 113565,
      changeInSalary: 1.59,
    },
    {
      rank: 2,
      roleTitle: 'Consultant',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 89,
      changeInJobAds: -12.7,
      averageSalary: 112104,
      changeInSalary: -2.98,
    },
    {
      rank: 3,
      roleTitle: 'Analyst',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 34,
      changeInJobAds: -12.8,
      averageSalary: 113553,
      changeInSalary: 3.33,
    },
    {
      rank: 4,
      roleTitle: 'Project Officer',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 43,
      changeInJobAds: -17.3,
      averageSalary: 106707,
      changeInSalary: 6.35,
    },
    {
      rank: 5,
      roleTitle: 'Project Manager',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 34,
      changeInJobAds: -20.9,
      averageSalary: 132072,
      changeInSalary: 0.28,
    },
    {
      rank: 6,
      roleTitle: 'Planner',
      industryTitle: 'Consulting & Strategy',
      numberOfJobAds: 54,
      changeInJobAds: -37.2,
      averageSalary: 107445,
      changeInSalary: -12,
    },
  ],
  'Design & Architecture': [
    {
      rank: 1,
      roleTitle: 'Draftsperson',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 275,
      changeInJobAds: 7.4,
      averageSalary: 83233,
      changeInSalary: 4.6,
    },
    {
      rank: 2,
      roleTitle: 'Architect',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 138,
      changeInJobAds: 3.8,
      averageSalary: 101845,
      changeInSalary: 2.38,
    },
    {
      rank: 3,
      roleTitle: 'Graduate of Architecture',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 55,
      changeInJobAds: 0,
      averageSalary: 76542,
      changeInSalary: 2.47,
    },
    {
      rank: 4,
      roleTitle: 'Planner',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 232,
      changeInJobAds: -9,
      averageSalary: 106144,
      changeInSalary: 7.95,
    },
    {
      rank: 5,
      roleTitle: 'Designer',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 927,
      changeInJobAds: -11.4,
      averageSalary: 85735,
      changeInSalary: 1.42,
    },
    {
      rank: 6,
      roleTitle: 'Project Architect',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 69,
      changeInJobAds: -16.9,
      averageSalary: 111396,
      changeInSalary: 7.28,
    },
    {
      rank: 7,
      roleTitle: 'Landscape Architect',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 72,
      changeInJobAds: -21.7,
      averageSalary: 92143,
      changeInSalary: -3.95,
    },
  ],
  'Education & Training': [
    {
      rank: 1,
      roleTitle: 'Chaplain',
      industryTitle: 'Education & Training',
      numberOfJobAds: 51,
      changeInJobAds: 59.4,
      averageSalary: 86332,
      changeInSalary: 2.06,
    },
    {
      rank: 2,
      roleTitle: 'School Officer',
      industryTitle: 'Education & Training',
      numberOfJobAds: 48,
      changeInJobAds: 50,
      averageSalary: 65631,
      changeInSalary: 6.09,
    },
    {
      rank: 3,
      roleTitle: 'Leader',
      industryTitle: 'Education & Training',
      numberOfJobAds: 143,
      changeInJobAds: 28.8,
      averageSalary: 100346,
      changeInSalary: 0.56,
    },
    {
      rank: 4,
      roleTitle: 'Outside School Hours Care Coordinator',
      industryTitle: 'Education & Training',
      numberOfJobAds: 494,
      changeInJobAds: 13.8,
      averageSalary: 72517,
      changeInSalary: 3.41,
    },
    {
      rank: 5,
      roleTitle: 'Coordinator',
      industryTitle: 'Education & Training',
      numberOfJobAds: 314,
      changeInJobAds: 10.2,
      averageSalary: 98424,
      changeInSalary: 5.85,
    },
    {
      rank: 6,
      roleTitle: 'English and Humanities Teacher',
      industryTitle: 'Education & Training',
      numberOfJobAds: 38,
      changeInJobAds: 8.6,
      averageSalary: 100515,
      changeInSalary: 2.69,
    },
    {
      rank: 7,
      roleTitle: 'Support Assistant',
      industryTitle: 'Education & Training',
      numberOfJobAds: 42,
      changeInJobAds: 7.7,
      averageSalary: 66628,
      changeInSalary: 5.24,
    },
    {
      rank: 8,
      roleTitle: 'Educational Leader',
      industryTitle: 'Education & Training',
      numberOfJobAds: 446,
      changeInJobAds: 4.7,
      averageSalary: 77496,
      changeInSalary: 6.62,
    },
    {
      rank: 9,
      roleTitle: 'Facilitator',
      industryTitle: 'Education & Training',
      numberOfJobAds: 80,
      changeInJobAds: 3.9,
      averageSalary: 87997,
      changeInSalary: 1.47,
    },
    {
      rank: 10,
      roleTitle: 'Library Officer',
      industryTitle: 'Education & Training',
      numberOfJobAds: 38,
      changeInJobAds: 2.7,
      averageSalary: 73652,
      changeInSalary: 2.08,
    },
    {
      rank: 11,
      roleTitle: 'Assistant Director',
      industryTitle: 'Education & Training',
      numberOfJobAds: 227,
      changeInJobAds: 0.9,
      averageSalary: 77389,
      changeInSalary: 4.64,
    },
    {
      rank: 12,
      roleTitle: 'Centre Director',
      industryTitle: 'Education & Training',
      numberOfJobAds: 430,
      changeInJobAds: 0.7,
      averageSalary: 97342,
      changeInSalary: 6.38,
    },
    {
      rank: 13,
      roleTitle: 'Room Leader',
      industryTitle: 'Education & Training',
      numberOfJobAds: 527,
      changeInJobAds: 0.6,
      averageSalary: 69140,
      changeInSalary: 3.69,
    },
    {
      rank: 14,
      roleTitle: 'Centre Manager',
      industryTitle: 'Education & Training',
      numberOfJobAds: 393,
      changeInJobAds: -0.5,
      averageSalary: 88662,
      changeInSalary: 5.37,
    },
    {
      rank: 15,
      roleTitle: 'Training Manager',
      industryTitle: 'Education & Training',
      numberOfJobAds: 37,
      changeInJobAds: -2.6,
      averageSalary: 112236,
      changeInSalary: 3.3,
    },
    {
      rank: 16,
      roleTitle: 'Youth Worker',
      industryTitle: 'Education & Training',
      numberOfJobAds: 31,
      changeInJobAds: -3.1,
      averageSalary: 74911,
      changeInSalary: 0.33,
    },
    {
      rank: 17,
      roleTitle: 'Librarian',
      industryTitle: 'Education & Training',
      numberOfJobAds: 136,
      changeInJobAds: -3.5,
      averageSalary: 94501,
      changeInSalary: 5.56,
    },
    {
      rank: 18,
      roleTitle: 'Teachers Aide',
      industryTitle: 'Education & Training',
      numberOfJobAds: 142,
      changeInJobAds: -4.7,
      averageSalary: 66114,
      changeInSalary: 7.22,
    },
    {
      rank: 19,
      roleTitle: 'Library Technician',
      industryTitle: 'Education & Training',
      numberOfJobAds: 37,
      changeInJobAds: -5.1,
      averageSalary: 75365,
      changeInSalary: 11.09,
    },
    {
      rank: 20,
      roleTitle: 'Support Coordinator',
      industryTitle: 'Education & Training',
      numberOfJobAds: 30,
      changeInJobAds: -6.3,
      averageSalary: 97206,
      changeInSalary: 3.31,
    },
  ],
  Engineering: [
    {
      rank: 1,
      roleTitle: 'Hydraulic Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 67,
      changeInJobAds: 45.7,
      averageSalary: 128496,
      changeInSalary: 13.55,
    },
    {
      rank: 2,
      roleTitle: 'Maintenance Technician',
      industryTitle: 'Engineering',
      numberOfJobAds: 44,
      changeInJobAds: 41.9,
      averageSalary: 96199,
      changeInSalary: 13.15,
    },
    {
      rank: 3,
      roleTitle: 'Fitter',
      industryTitle: 'Engineering',
      numberOfJobAds: 114,
      changeInJobAds: 9.6,
      averageSalary: 96392,
      changeInSalary: 4.4,
    },
    {
      rank: 4,
      roleTitle: 'Technical Officer',
      industryTitle: 'Engineering',
      numberOfJobAds: 76,
      changeInJobAds: 5.6,
      averageSalary: 100236,
      changeInSalary: 15.94,
    },
    {
      rank: 5,
      roleTitle: 'Electrician',
      industryTitle: 'Engineering',
      numberOfJobAds: 48,
      changeInJobAds: 2.1,
      averageSalary: 102694,
      changeInSalary: -7.7,
    },
    {
      rank: 6,
      roleTitle: 'Engineering Technician',
      industryTitle: 'Engineering',
      numberOfJobAds: 45,
      changeInJobAds: -2.2,
      averageSalary: 86742,
      changeInSalary: -3.97,
    },
    {
      rank: 7,
      roleTitle: 'Licenced Aircraft Maintenance Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 121,
      changeInJobAds: -6.2,
      averageSalary: 131876,
      changeInSalary: 2.58,
    },
    {
      rank: 8,
      roleTitle: 'Maintenance Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 88,
      changeInJobAds: -9.3,
      averageSalary: 97289,
      changeInSalary: -14.01,
    },
    {
      rank: 9,
      roleTitle: 'Manufacturing Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 34,
      changeInJobAds: -10.5,
      averageSalary: 109030,
      changeInSalary: -0.11,
    },
    {
      rank: 10,
      roleTitle: 'Site Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 240,
      changeInJobAds: -10.8,
      averageSalary: 109643,
      changeInSalary: 2.76,
    },
    {
      rank: 11,
      roleTitle: 'Modeller',
      industryTitle: 'Engineering',
      numberOfJobAds: 43,
      changeInJobAds: -14,
      averageSalary: 111078,
      changeInSalary: -0.92,
    },
    {
      rank: 12,
      roleTitle: 'Project Coordinator',
      industryTitle: 'Engineering',
      numberOfJobAds: 39,
      changeInJobAds: -26.4,
      averageSalary: 97024,
      changeInSalary: 1.01,
    },
    {
      rank: 13,
      roleTitle: 'Draftsperson',
      industryTitle: 'Engineering',
      numberOfJobAds: 374,
      changeInJobAds: -29.8,
      averageSalary: 89299,
      changeInSalary: 0.03,
    },
    {
      rank: 14,
      roleTitle: 'Field Service Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 53,
      changeInJobAds: -32.1,
      averageSalary: 93842,
      changeInSalary: -1.5,
    },
    {
      rank: 15,
      roleTitle: 'Development Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 60,
      changeInJobAds: -36.8,
      averageSalary: 109177,
      changeInSalary: -2.24,
    },
    {
      rank: 16,
      roleTitle: 'Aircraft Maintenance Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 75,
      changeInJobAds: -40.5,
      averageSalary: 92797,
      changeInSalary: 4.96,
    },
  ],
  'Farming, Animals & Conservation': [
    {
      rank: 1,
      roleTitle: 'Groomer',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 117,
      changeInJobAds: 60.3,
      averageSalary: 64256,
      changeInSalary: 5.64,
    },
    {
      rank: 2,
      roleTitle: 'Assistant',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 43,
      changeInJobAds: 34.4,
      averageSalary: 67146,
      changeInSalary: 10.92,
    },
    {
      rank: 3,
      roleTitle: 'Nursery Hand',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 73,
      changeInJobAds: 28.1,
      averageSalary: 56681,
      changeInSalary: 5.41,
    },
    {
      rank: 4,
      roleTitle: 'Leading Hand',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 41,
      changeInJobAds: 10.8,
      averageSalary: 70697,
      changeInSalary: 12.59,
    },
    {
      rank: 5,
      roleTitle: 'Farmhand',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 298,
      changeInJobAds: 4.2,
      averageSalary: 63923,
      changeInSalary: 4.16,
    },
    {
      rank: 6,
      roleTitle: 'Nurse',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 240,
      changeInJobAds: 1.7,
      averageSalary: 64176,
      changeInSalary: 6.19,
    },
    {
      rank: 7,
      roleTitle: 'Attendant',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 34,
      changeInJobAds: 0,
      averageSalary: 60874,
      changeInSalary: 4.71,
    },
    {
      rank: 8,
      roleTitle: 'Cellarhand',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 33,
      changeInJobAds: 0,
      averageSalary: 61434,
      changeInSalary: 4.2,
    },
    {
      rank: 9,
      roleTitle: 'Field Officer',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 42,
      changeInJobAds: -8.7,
      averageSalary: 69742,
      changeInSalary: 4.29,
    },
    {
      rank: 10,
      roleTitle: 'Farm Manager',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 105,
      changeInJobAds: -15.3,
      averageSalary: 95593,
      changeInSalary: -0.4,
    },
    {
      rank: 11,
      roleTitle: 'Station Hand',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 32,
      changeInJobAds: -15.8,
      averageSalary: 59258,
      changeInSalary: -3.9,
    },
    {
      rank: 12,
      roleTitle: 'Receptionist',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 31,
      changeInJobAds: -16.2,
      averageSalary: 60067,
      changeInSalary: 8.06,
    },
    {
      rank: 13,
      roleTitle: 'Veterinarian',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 74,
      changeInJobAds: -22.9,
      averageSalary: 110247,
      changeInSalary: -2.94,
    },
    {
      rank: 14,
      roleTitle: 'Machine Operator',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 43,
      changeInJobAds: -24.6,
      averageSalary: 68971,
      changeInSalary: 5.44,
    },
    {
      rank: 15,
      roleTitle: 'Project Officer',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 34,
      changeInJobAds: -26.1,
      averageSalary: 104731,
      changeInSalary: 7.75,
    },
    {
      rank: 16,
      roleTitle: 'Ranger',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 72,
      changeInJobAds: -26.5,
      averageSalary: 81904,
      changeInSalary: 14.89,
    },
  ],
  'Government & Defence': [
    {
      rank: 1,
      roleTitle: 'Data Analyst',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 53,
      changeInJobAds: 60.6,
      averageSalary: 105093,
      changeInSalary: 1.79,
    },
    {
      rank: 2,
      roleTitle: 'Executive Assistant',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 47,
      changeInJobAds: 38.2,
      averageSalary: 84676,
      changeInSalary: 4.95,
    },
    {
      rank: 3,
      roleTitle: 'Policy Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 99,
      changeInJobAds: 33.8,
      averageSalary: 107859,
      changeInSalary: 5.13,
    },
    {
      rank: 4,
      roleTitle: 'Inspector',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 68,
      changeInJobAds: 23.6,
      averageSalary: 100861,
      changeInSalary: 3.58,
    },
    {
      rank: 5,
      roleTitle: 'Project Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 233,
      changeInJobAds: 20.7,
      averageSalary: 105322,
      changeInSalary: -0.3,
    },
    {
      rank: 6,
      roleTitle: 'Administration Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 69,
      changeInJobAds: 16.9,
      averageSalary: 66656,
      changeInSalary: -12.34,
    },
    {
      rank: 7,
      roleTitle: 'Support Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 207,
      changeInJobAds: 16.3,
      averageSalary: 85781,
      changeInSalary: 3.64,
    },
    {
      rank: 8,
      roleTitle: 'Health Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 53,
      changeInJobAds: 10.4,
      averageSalary: 96893,
      changeInSalary: 5.75,
    },
    {
      rank: 9,
      roleTitle: 'Procurement Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 49,
      changeInJobAds: 8.9,
      averageSalary: 97515,
      changeInSalary: 1.46,
    },
    {
      rank: 10,
      roleTitle: 'Assistant Director',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 348,
      changeInJobAds: 8.1,
      averageSalary: 123979,
      changeInSalary: 4.11,
    },
    {
      rank: 11,
      roleTitle: 'Governance Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 44,
      changeInJobAds: 7.3,
      averageSalary: 95428,
      changeInSalary: 6.03,
    },
    {
      rank: 12,
      roleTitle: 'Program Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 75,
      changeInJobAds: 7.1,
      averageSalary: 99534,
      changeInSalary: 9.87,
    },
    {
      rank: 13,
      roleTitle: 'Investigator',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 50,
      changeInJobAds: 6.4,
      averageSalary: 123586,
      changeInSalary: 15.62,
    },
    {
      rank: 14,
      roleTitle: 'Manager',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 167,
      changeInJobAds: -0.6,
      averageSalary: 148150,
      changeInSalary: 3.88,
    },
    {
      rank: 15,
      roleTitle: 'Services Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 60,
      changeInJobAds: -4.8,
      averageSalary: 85375,
      changeInSalary: 14.6,
    },
    {
      rank: 16,
      roleTitle: 'Parking Officer',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 30,
      changeInJobAds: -6.3,
      averageSalary: 76580,
      changeInSalary: 12.16,
    },
    {
      rank: 17,
      roleTitle: 'Team Leader',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 96,
      changeInJobAds: -9.4,
      averageSalary: 104025,
      changeInSalary: 6.66,
    },
    {
      rank: 18,
      roleTitle: 'Ranger',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 55,
      changeInJobAds: -9.8,
      averageSalary: 81356,
      changeInSalary: 1.11,
    },
    {
      rank: 19,
      roleTitle: 'Contracts Manager',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 31,
      changeInJobAds: -13.9,
      averageSalary: 113453,
      changeInSalary: 8.34,
    },
    {
      rank: 20,
      roleTitle: 'Coordinator',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 83,
      changeInJobAds: -21.7,
      averageSalary: 112895,
      changeInSalary: 4.98,
    },
  ],
  'Healthcare & Medical': [
    {
      rank: 1,
      roleTitle: 'Assessor',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 117,
      changeInJobAds: 165.9,
      averageSalary: 96154,
      changeInSalary: 13.82,
    },
    {
      rank: 2,
      roleTitle: 'Coach',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 55,
      changeInJobAds: 57.1,
      averageSalary: 80272,
      changeInSalary: -6.71,
    },
    {
      rank: 3,
      roleTitle: 'Medical Practitioner',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 59,
      changeInJobAds: 51.3,
      averageSalary: 234347,
      changeInSalary: 19.3,
    },
    {
      rank: 4,
      roleTitle: 'Optical Assistant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 45,
      changeInJobAds: 45.2,
      averageSalary: 55640,
      changeInSalary: 5.72,
    },
    {
      rank: 5,
      roleTitle: 'Treatment Coordinator',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 52,
      changeInJobAds: 36.8,
      averageSalary: 70249,
      changeInSalary: 1.24,
    },
    {
      rank: 6,
      roleTitle: 'Therapy Assistant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 70,
      changeInJobAds: 32.1,
      averageSalary: 64224,
      changeInSalary: 4.77,
    },
    {
      rank: 7,
      roleTitle: 'Hygienist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 89,
      changeInJobAds: 29,
      averageSalary: 113000,
      changeInSalary: 1.97,
    },
    {
      rank: 8,
      roleTitle: 'Health Team Leader',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 55,
      changeInJobAds: 27.9,
      averageSalary: 113259,
      changeInSalary: -2.09,
    },
    {
      rank: 9,
      roleTitle: 'Sales Representative',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 101,
      changeInJobAds: 27.8,
      averageSalary: 89670,
      changeInSalary: -9.08,
    },
    {
      rank: 10,
      roleTitle: 'Collector',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 272,
      changeInJobAds: 25.9,
      averageSalary: 56025,
      changeInSalary: 1.25,
    },
    {
      rank: 11,
      roleTitle: 'Dispenser',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 146,
      changeInJobAds: 25.9,
      averageSalary: 57150,
      changeInSalary: -2.56,
    },
    {
      rank: 12,
      roleTitle: 'Dental Technician',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 72,
      changeInJobAds: 18,
      averageSalary: 78196,
      changeInSalary: 4.14,
    },
    {
      rank: 13,
      roleTitle: 'Territory Manager',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 131,
      changeInJobAds: 17,
      averageSalary: 112030,
      changeInSalary: 7.04,
    },
    {
      rank: 14,
      roleTitle: 'Store Manager',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 78,
      changeInJobAds: 16.4,
      averageSalary: 63033,
      changeInSalary: 5.44,
    },
    {
      rank: 15,
      roleTitle: 'Partner',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 64,
      changeInJobAds: 16.4,
      averageSalary: 107342,
      changeInSalary: 5.34,
    },
    {
      rank: 16,
      roleTitle: 'Product Specialist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 60,
      changeInJobAds: 13.2,
      averageSalary: 102232,
      changeInSalary: -1.93,
    },
    {
      rank: 17,
      roleTitle: 'Rehabilitation Consultant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 624,
      changeInJobAds: 12.4,
      averageSalary: 87946,
      changeInSalary: 3.43,
    },
    {
      rank: 18,
      roleTitle: 'Nursing Assistant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 55,
      changeInJobAds: 12.2,
      averageSalary: 70843,
      changeInSalary: 15.22,
    },
    {
      rank: 19,
      roleTitle: 'Program Manager',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 46,
      changeInJobAds: 12.2,
      averageSalary: 126585,
      changeInSalary: 6.1,
    },
    {
      rank: 20,
      roleTitle: 'Customer Service Representative',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 45,
      changeInJobAds: 9.8,
      averageSalary: 63830,
      changeInSalary: 1.49,
    },
  ],
  'Hospitality & Tourism': [
    {
      rank: 1,
      roleTitle: 'Artist',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 57,
      changeInJobAds: 90,
      averageSalary: 55050,
      changeInSalary: 11.32,
    },
    {
      rank: 2,
      roleTitle: 'Hospitality Assistant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 64,
      changeInJobAds: 82.9,
      averageSalary: null,
      changeInSalary: null,
    },
    {
      rank: 3,
      roleTitle: 'Front of House Supervisor',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 67,
      changeInJobAds: 52.3,
      averageSalary: 69668,
      changeInSalary: 7.2,
    },
    {
      rank: 4,
      roleTitle: 'Floor Staff',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 62,
      changeInJobAds: 40.9,
      averageSalary: 67692,
      changeInSalary: 5.54,
    },
    {
      rank: 5,
      roleTitle: 'Motel Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 48,
      changeInJobAds: 37.1,
      averageSalary: 84581,
      changeInSalary: -1.91,
    },
    {
      rank: 6,
      roleTitle: 'Housekeeping Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 107,
      changeInJobAds: 27.4,
      averageSalary: 61613,
      changeInSalary: 2.89,
    },
    {
      rank: 7,
      roleTitle: 'Kitchen Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 55,
      changeInJobAds: 25,
      averageSalary: 61100,
      changeInSalary: 5.09,
    },
    {
      rank: 8,
      roleTitle: 'Services Officer',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 138,
      changeInJobAds: 22.1,
      averageSalary: 57194,
      changeInSalary: 7.76,
    },
    {
      rank: 9,
      roleTitle: 'Service Agent',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 37,
      changeInJobAds: 19.4,
      averageSalary: 59938,
      changeInSalary: -0.11,
    },
    {
      rank: 10,
      roleTitle: 'Front of House Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 51,
      changeInJobAds: 18.6,
      averageSalary: 71467,
      changeInSalary: -0.4,
    },
    {
      rank: 11,
      roleTitle: 'Room Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 236,
      changeInJobAds: 18.6,
      averageSalary: 63506,
      changeInSalary: 0.31,
    },
    {
      rank: 12,
      roleTitle: 'Events Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 53,
      changeInJobAds: 17.8,
      averageSalary: 80062,
      changeInSalary: -0.81,
    },
    {
      rank: 13,
      roleTitle: 'Food and Beverage Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 112,
      changeInJobAds: 16.7,
      averageSalary: 80700,
      changeInSalary: 0.83,
    },
    {
      rank: 14,
      roleTitle: 'Service Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 49,
      changeInJobAds: 16.7,
      averageSalary: 59108,
      changeInSalary: 9.09,
    },
    {
      rank: 15,
      roleTitle: 'Gaming Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 157,
      changeInJobAds: 14.6,
      averageSalary: 68191,
      changeInSalary: 6.24,
    },
    {
      rank: 16,
      roleTitle: 'Food and Beverage Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 681,
      changeInJobAds: 12.2,
      averageSalary: 63159,
      changeInSalary: 3.89,
    },
    {
      rank: 17,
      roleTitle: 'Chef de Partie',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 1,
      changeInJobAds: 11.7,
      averageSalary: 71139,
      changeInSalary: 3.81,
    },
    {
      rank: 18,
      roleTitle: 'Cafe Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 48,
      changeInJobAds: 11.6,
      averageSalary: 56580,
      changeInSalary: 1.12,
    },
    {
      rank: 19,
      roleTitle: 'Park Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 78,
      changeInJobAds: 11.4,
      averageSalary: 81809,
      changeInSalary: 4.47,
    },
    {
      rank: 20,
      roleTitle: 'Bar and Gaming Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 121,
      changeInJobAds: 11,
      averageSalary: 68342,
      changeInSalary: 7.08,
    },
  ],
  'Human Resources & Recruitment': [
    {
      rank: 1,
      roleTitle: 'Employee Relations Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 48,
      changeInJobAds: 50,
      averageSalary: 111848,
      changeInSalary: -0.58,
    },
    {
      rank: 2,
      roleTitle: 'Occupational Health and Safety Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 100,
      changeInJobAds: 16.3,
      averageSalary: 102875,
      changeInSalary: 3.88,
    },
    {
      rank: 3,
      roleTitle: 'Return to Work Coordinator',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 34,
      changeInJobAds: 0,
      averageSalary: 89737,
      changeInSalary: 1.43,
    },
    {
      rank: 4,
      roleTitle: 'Safety Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 42,
      changeInJobAds: -2.3,
      averageSalary: 108009,
      changeInSalary: 2.32,
    },
    {
      rank: 5,
      roleTitle: 'People and Culture Administrator',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 34,
      changeInJobAds: -2.9,
      averageSalary: 69966,
      changeInSalary: 2.19,
    },
    {
      rank: 6,
      roleTitle: 'Human Resources Officer',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 199,
      changeInJobAds: -3.9,
      averageSalary: 84895,
      changeInSalary: 6.94,
    },
    {
      rank: 7,
      roleTitle: 'Work Health and Safety Coordinator',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 61,
      changeInJobAds: -4.7,
      averageSalary: 94131,
      changeInSalary: -0.78,
    },
    {
      rank: 8,
      roleTitle: 'Support Officer',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 50,
      changeInJobAds: -5.7,
      averageSalary: 74072,
      changeInSalary: -3.39,
    },
    {
      rank: 9,
      roleTitle: 'Health and Safety Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 59,
      changeInJobAds: -6.3,
      averageSalary: 110339,
      changeInSalary: -0.91,
    },
    {
      rank: 10,
      roleTitle: 'Resourcer',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 52,
      changeInJobAds: -11.9,
      averageSalary: 65782,
      changeInSalary: -2.73,
    },
    {
      rank: 11,
      roleTitle: 'Human Resources Generalist',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 74,
      changeInJobAds: -11.9,
      averageSalary: 103045,
      changeInSalary: 6.8,
    },
    {
      rank: 12,
      roleTitle: 'Human Resources Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 379,
      changeInJobAds: -15.8,
      averageSalary: 105059,
      changeInSalary: 4.49,
    },
    {
      rank: 13,
      roleTitle: 'General Manager',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 30,
      changeInJobAds: -16.7,
      averageSalary: 225962,
      changeInSalary: 24.89,
    },
    {
      rank: 14,
      roleTitle: 'People and Culture Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 150,
      changeInJobAds: -16.7,
      averageSalary: 97756,
      changeInSalary: 1.17,
    },
    {
      rank: 15,
      roleTitle: 'Recruitment Officer',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 76,
      changeInJobAds: -18.3,
      averageSalary: 75867,
      changeInSalary: 1.35,
    },
    {
      rank: 16,
      roleTitle: 'Recruitment Consultant',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 854,
      changeInJobAds: -18.6,
      averageSalary: 81492,
      changeInSalary: 3.11,
    },
    {
      rank: 17,
      roleTitle: 'Recruitment Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 54,
      changeInJobAds: -20.6,
      averageSalary: 93387,
      changeInSalary: -1.25,
    },
    {
      rank: 18,
      roleTitle: 'People and Culture Officer',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 33,
      changeInJobAds: -21.4,
      averageSalary: 77477,
      changeInSalary: 0.43,
    },
    {
      rank: 19,
      roleTitle: 'Talent Acquisition Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 31,
      changeInJobAds: -22.5,
      averageSalary: 93742,
      changeInSalary: 3.95,
    },
    {
      rank: 20,
      roleTitle: 'Work Health and Safety Manager',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 54,
      changeInJobAds: -25,
      averageSalary: 138548,
      changeInSalary: 1.92,
    },
  ],
  'Information & Communication Technology': [
    {
      rank: 1,
      roleTitle: 'Technology Officer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 37,
      changeInJobAds: 2.8,
      averageSalary: 97953,
      changeInSalary: 3.08,
    },
    {
      rank: 2,
      roleTitle: 'Support Specialist',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 158,
      changeInJobAds: 0,
      averageSalary: 87789,
      changeInSalary: 1.05,
    },
    {
      rank: 3,
      roleTitle: 'Functional Consultant',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 75,
      changeInJobAds: -1.3,
      averageSalary: 153072,
      changeInSalary: 4.51,
    },
    {
      rank: 4,
      roleTitle: 'Business Intelligence Software Developer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 37,
      changeInJobAds: -5.1,
      averageSalary: 131485,
      changeInSalary: 3.28,
    },
    {
      rank: 5,
      roleTitle: 'Team Leader',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 70,
      changeInJobAds: -9.1,
      averageSalary: 122377,
      changeInSalary: -4.94,
    },
    {
      rank: 6,
      roleTitle: 'Testing Analyst',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 76,
      changeInJobAds: -12.6,
      averageSalary: 107046,
      changeInSalary: -7.27,
    },
    {
      rank: 7,
      roleTitle: 'Project Officer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 58,
      changeInJobAds: -13.4,
      averageSalary: 108832,
      changeInSalary: 5.12,
    },
    {
      rank: 8,
      roleTitle: 'Support Officer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 352,
      changeInJobAds: -16.2,
      averageSalary: 79494,
      changeInSalary: 2.01,
    },
    {
      rank: 9,
      roleTitle: 'Support Technician',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 140,
      changeInJobAds: -17.6,
      averageSalary: 73148,
      changeInSalary: 2.75,
    },
    {
      rank: 10,
      roleTitle: 'Security Architect',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 54,
      changeInJobAds: -18.2,
      averageSalary: 174408,
      changeInSalary: -3.54,
    },
    {
      rank: 11,
      roleTitle: 'Service Desk Analyst',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 140,
      changeInJobAds: -20,
      averageSalary: 74330,
      changeInSalary: 0.51,
    },
    {
      rank: 12,
      roleTitle: 'Support Engineer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 294,
      changeInJobAds: -21.2,
      averageSalary: 89503,
      changeInSalary: 5.15,
    },
    {
      rank: 13,
      roleTitle: 'Engineering Lead',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 35,
      changeInJobAds: -22.2,
      averageSalary: 178374,
      changeInSalary: 16.11,
    },
    {
      rank: 14,
      roleTitle: 'Web Developer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 62,
      changeInJobAds: -22.5,
      averageSalary: 94503,
      changeInSalary: -3.88,
    },
    {
      rank: 15,
      roleTitle: 'Service Desk Team Leader',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 32,
      changeInJobAds: -23.8,
      averageSalary: 102296,
      changeInSalary: 1.39,
    },
    {
      rank: 16,
      roleTitle: 'Information Technology Manager',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 99,
      changeInJobAds: -25.6,
      averageSalary: 130800,
      changeInSalary: -0.1,
    },
    {
      rank: 17,
      roleTitle: 'Programmer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 41,
      changeInJobAds: -26.8,
      averageSalary: 98945,
      changeInSalary: -9.11,
    },
    {
      rank: 18,
      roleTitle: 'Application Specialist',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 40,
      changeInJobAds: -29.8,
      averageSalary: 120601,
      changeInSalary: 4.25,
    },
    {
      rank: 19,
      roleTitle: 'Business Intelligence Analyst',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 31,
      changeInJobAds: -32.6,
      averageSalary: 116949,
      changeInSalary: 3.25,
    },
    {
      rank: 20,
      roleTitle: 'Information Technology Technician',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 39,
      changeInJobAds: -32.8,
      averageSalary: 76297,
      changeInSalary: 12.25,
    },
  ],
  'Insurance & Superannuation': [
    {
      rank: 1,
      roleTitle: 'Investigator',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 40,
      changeInJobAds: 25,
      averageSalary: 86375,
      changeInSalary: -0.31,
    },
    {
      rank: 2,
      roleTitle: 'Adjuster',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 47,
      changeInJobAds: 20.5,
      averageSalary: 97560,
      changeInSalary: -3.45,
    },
    {
      rank: 3,
      roleTitle: 'Claims Adviser',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 68,
      changeInJobAds: 13.3,
      averageSalary: 84598,
      changeInSalary: 12.9,
    },
    {
      rank: 4,
      roleTitle: 'Case Manager',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 123,
      changeInJobAds: 12.8,
      averageSalary: 86261,
      changeInSalary: 4.18,
    },
    {
      rank: 5,
      roleTitle: 'Claims Consultant',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 180,
      changeInJobAds: 6.5,
      averageSalary: 80634,
      changeInSalary: -2.11,
    },
    {
      rank: 6,
      roleTitle: 'Team Leader',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 35,
      changeInJobAds: 2.9,
      averageSalary: 109240,
      changeInSalary: 4.35,
    },
    {
      rank: 7,
      roleTitle: 'Assessor',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 151,
      changeInJobAds: -9.6,
      averageSalary: 100171,
      changeInSalary: -1.89,
    },
    {
      rank: 8,
      roleTitle: 'Account Executive',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 142,
      changeInJobAds: -23.2,
      averageSalary: 97462,
      changeInSalary: 7.61,
    },
  ],
  Legal: [
    {
      rank: 1,
      roleTitle: 'Migration Agent',
      industryTitle: 'Legal',
      numberOfJobAds: 97,
      changeInJobAds: 56.5,
      averageSalary: 93371,
      changeInSalary: 12.95,
    },
    {
      rank: 2,
      roleTitle: 'Law Graduate',
      industryTitle: 'Legal',
      numberOfJobAds: 67,
      changeInJobAds: 45.7,
      averageSalary: 77803,
      changeInSalary: 11.37,
    },
    {
      rank: 3,
      roleTitle: 'Conveyancer',
      industryTitle: 'Legal',
      numberOfJobAds: 174,
      changeInJobAds: 40.3,
      averageSalary: 83091,
      changeInSalary: 5.23,
    },
    {
      rank: 4,
      roleTitle: 'Paralegal',
      industryTitle: 'Legal',
      numberOfJobAds: 474,
      changeInJobAds: 15.6,
      averageSalary: 78144,
      changeInSalary: 5.97,
    },
    {
      rank: 5,
      roleTitle: 'Law Clerk',
      industryTitle: 'Legal',
      numberOfJobAds: 76,
      changeInJobAds: 13.4,
      averageSalary: 69070,
      changeInSalary: 5.65,
    },
    {
      rank: 6,
      roleTitle: 'Legal Administrator',
      industryTitle: 'Legal',
      numberOfJobAds: 60,
      changeInJobAds: 13.2,
      averageSalary: 72923,
      changeInSalary: 7.15,
    },
    {
      rank: 7,
      roleTitle: 'Legal Assistant',
      industryTitle: 'Legal',
      numberOfJobAds: 645,
      changeInJobAds: 11,
      averageSalary: 80193,
      changeInSalary: 3.65,
    },
    {
      rank: 8,
      roleTitle: 'Secretary',
      industryTitle: 'Legal',
      numberOfJobAds: 468,
      changeInJobAds: -17,
      averageSalary: 79101,
      changeInSalary: 4.08,
    },
    {
      rank: 9,
      roleTitle: 'Personal Assistant',
      industryTitle: 'Legal',
      numberOfJobAds: 46,
      changeInJobAds: -25.8,
      averageSalary: 85783,
      changeInSalary: 8.55,
    },
    {
      rank: 10,
      roleTitle: 'Administration Officer',
      industryTitle: 'Legal',
      numberOfJobAds: 38,
      changeInJobAds: -28.3,
      averageSalary: 67196,
      changeInSalary: -0.2,
    },
  ],
  'Manufacturing, Transport & Logistics': [
    {
      rank: 1,
      roleTitle: 'Installer',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 51,
      changeInJobAds: 50,
      averageSalary: 63066,
      changeInSalary: 4.02,
    },
    {
      rank: 2,
      roleTitle: 'Sorter',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 83,
      changeInJobAds: 48.2,
      averageSalary: 65820,
      changeInSalary: 1.95,
    },
    {
      rank: 3,
      roleTitle: 'Production Coordinator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 57,
      changeInJobAds: 46.2,
      averageSalary: 79547,
      changeInSalary: -7.91,
    },
    {
      rank: 4,
      roleTitle: 'Serviceperson',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 44,
      changeInJobAds: 37.5,
      averageSalary: 69843,
      changeInSalary: 1.34,
    },
    {
      rank: 5,
      roleTitle: 'Removalist',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 103,
      changeInJobAds: 33.8,
      averageSalary: 67574,
      changeInSalary: 1.49,
    },
    {
      rank: 6,
      roleTitle: 'Truck and Dog Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 115,
      changeInJobAds: 30.7,
      averageSalary: 76920,
      changeInSalary: 8.73,
    },
    {
      rank: 7,
      roleTitle: 'Warehouse Staff',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 58,
      changeInJobAds: 28.9,
      averageSalary: 63753,
      changeInSalary: 5.12,
    },
    {
      rank: 8,
      roleTitle: 'Production Team Leader',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 54,
      changeInJobAds: 25.6,
      averageSalary: 74257,
      changeInSalary: 3.61,
    },
    {
      rank: 9,
      roleTitle: 'Offsider',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 67,
      changeInJobAds: 24.1,
      averageSalary: 61452,
      changeInSalary: -0.9,
    },
    {
      rank: 10,
      roleTitle: 'Support Officer',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 62,
      changeInJobAds: 24,
      averageSalary: 74508,
      changeInSalary: 2.97,
    },
    {
      rank: 11,
      roleTitle: 'Processor',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 54,
      changeInJobAds: 22.7,
      averageSalary: 62886,
      changeInSalary: 6.3,
    },
    {
      rank: 12,
      roleTitle: 'Manufacturing Operator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 41,
      changeInJobAds: 20.6,
      averageSalary: 73041,
      changeInSalary: 15.36,
    },
    {
      rank: 13,
      roleTitle: 'Courier Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 87,
      changeInJobAds: 17.6,
      averageSalary: 70999,
      changeInSalary: 11.54,
    },
    {
      rank: 14,
      roleTitle: 'Owner Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 150,
      changeInJobAds: 17.2,
      averageSalary: 87636,
      changeInSalary: 8.83,
    },
    {
      rank: 15,
      roleTitle: 'Broker',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 56,
      changeInJobAds: 16.7,
      averageSalary: 110208,
      changeInSalary: -0.96,
    },
    {
      rank: 16,
      roleTitle: 'Business Development Manager',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 51,
      changeInJobAds: 15.9,
      averageSalary: 120130,
      changeInSalary: -1.89,
    },
    {
      rank: 17,
      roleTitle: 'Yardhand',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 91,
      changeInJobAds: 15.2,
      averageSalary: 63454,
      changeInSalary: 2.52,
    },
    {
      rank: 18,
      roleTitle: 'Production Technician',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 56,
      changeInJobAds: 14.3,
      averageSalary: 72741,
      changeInSalary: 13.87,
    },
    {
      rank: 19,
      roleTitle: 'Fabricator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 112,
      changeInJobAds: 13.1,
      averageSalary: 68672,
      changeInSalary: 6.27,
    },
    {
      rank: 20,
      roleTitle: 'Factory Worker',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 97,
      changeInJobAds: 12.8,
      averageSalary: 59815,
      changeInSalary: 3.32,
    },
  ],
  'Marketing & Communications': [
    {
      rank: 1,
      roleTitle: 'Content Creator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 127,
      changeInJobAds: 17.6,
      averageSalary: 73088,
      changeInSalary: 2.75,
    },
    {
      rank: 2,
      roleTitle: 'Marketing Specialist',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 485,
      changeInJobAds: 16.6,
      averageSalary: 87261,
      changeInSalary: 1.01,
    },
    {
      rank: 3,
      roleTitle: 'Communications Adviser',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 107,
      changeInJobAds: 12.6,
      averageSalary: 110274,
      changeInSalary: 6.33,
    },
    {
      rank: 4,
      roleTitle: 'Media Specialist',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 74,
      changeInJobAds: 10.4,
      averageSalary: 84691,
      changeInSalary: 6.72,
    },
    {
      rank: 5,
      roleTitle: 'Media Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 106,
      changeInJobAds: 1.9,
      averageSalary: 71531,
      changeInSalary: 3.29,
    },
    {
      rank: 6,
      roleTitle: 'eCommerce Specialist',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 32,
      changeInJobAds: -5.9,
      averageSalary: 89083,
      changeInSalary: 7.44,
    },
    {
      rank: 7,
      roleTitle: 'eCommerce Manager',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 44,
      changeInJobAds: -6.4,
      averageSalary: 114198,
      changeInSalary: 3.15,
    },
    {
      rank: 8,
      roleTitle: 'Content Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 53,
      changeInJobAds: -7,
      averageSalary: 74262,
      changeInSalary: 2.44,
    },
    {
      rank: 9,
      roleTitle: 'Marketing and Communications Officer',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 53,
      changeInJobAds: -8.6,
      averageSalary: 84671,
      changeInSalary: 5.1,
    },
    {
      rank: 10,
      roleTitle: 'Communications Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 76,
      changeInJobAds: -10.6,
      averageSalary: 88345,
      changeInSalary: 7.92,
    },
    {
      rank: 11,
      roleTitle: 'Specialist',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 40,
      changeInJobAds: -11.1,
      averageSalary: 100185,
      changeInSalary: -0.16,
    },
    {
      rank: 12,
      roleTitle: 'Events Officer',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 41,
      changeInJobAds: -12.8,
      averageSalary: 86946,
      changeInSalary: 12.42,
    },
    {
      rank: 13,
      roleTitle: 'Events Manager',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 72,
      changeInJobAds: -13.3,
      averageSalary: 95871,
      changeInSalary: 8.32,
    },
    {
      rank: 14,
      roleTitle: 'Marketing Assistant',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 163,
      changeInJobAds: -13.3,
      averageSalary: 64797,
      changeInSalary: 5.27,
    },
    {
      rank: 15,
      roleTitle: 'Adviser',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 41,
      changeInJobAds: -14.6,
      averageSalary: 123123,
      changeInSalary: 9.64,
    },
    {
      rank: 16,
      roleTitle: 'Engagement Officer',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 36,
      changeInJobAds: -16.3,
      averageSalary: 90392,
      changeInSalary: 4.37,
    },
    {
      rank: 17,
      roleTitle: 'Producer',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 63,
      changeInJobAds: -17.1,
      averageSalary: 88854,
      changeInSalary: 1.69,
    },
    {
      rank: 18,
      roleTitle: 'Events Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 135,
      changeInJobAds: -17.7,
      averageSalary: 74670,
      changeInSalary: 3.43,
    },
    {
      rank: 19,
      roleTitle: 'Marketing Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 662,
      changeInJobAds: -18.1,
      averageSalary: 74276,
      changeInSalary: 2.07,
    },
    {
      rank: 20,
      roleTitle: 'Communications Officer',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 164,
      changeInJobAds: -23.7,
      averageSalary: 92044,
      changeInSalary: -0.21,
    },
  ],
  'Mining, Resources & Energy': [
    {
      rank: 1,
      roleTitle: 'Mining Operator',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 62,
      changeInJobAds: 34.8,
      averageSalary: 113982,
      changeInSalary: 0.75,
    },
    {
      rank: 2,
      roleTitle: 'Serviceperson',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 143,
      changeInJobAds: 8.3,
      averageSalary: 119742,
      changeInSalary: 6.3,
    },
    {
      rank: 3,
      roleTitle: 'Drillers Assistant',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 37,
      changeInJobAds: 2.8,
      averageSalary: 94688,
      changeInSalary: 0.14,
    },
    {
      rank: 4,
      roleTitle: 'Crew',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 109,
      changeInJobAds: 0.9,
      averageSalary: 115737,
      changeInSalary: 6.44,
    },
    {
      rank: 5,
      roleTitle: 'Site Administrator',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 32,
      changeInJobAds: -3,
      averageSalary: 101343,
      changeInSalary: 11,
    },
    {
      rank: 6,
      roleTitle: 'Lineworker',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 61,
      changeInJobAds: -4.7,
      averageSalary: 118553,
      changeInSalary: 7.9,
    },
    {
      rank: 7,
      roleTitle: 'Health and Safety Adviser',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 59,
      changeInJobAds: -4.8,
      averageSalary: 140368,
      changeInSalary: 4.9,
    },
    {
      rank: 8,
      roleTitle: 'Water Cart Operator',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 31,
      changeInJobAds: -6.1,
      averageSalary: 112034,
      changeInSalary: 5.74,
    },
    {
      rank: 9,
      roleTitle: 'Planning Engineer',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 49,
      changeInJobAds: -7.5,
      averageSalary: 167587,
      changeInSalary: -4.3,
    },
    {
      rank: 10,
      roleTitle: 'Scheduler',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 41,
      changeInJobAds: -8.9,
      averageSalary: 152340,
      changeInSalary: 8.7,
    },
    {
      rank: 11,
      roleTitle: 'Scaffolder',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 92,
      changeInJobAds: -9.8,
      averageSalary: 107678,
      changeInSalary: -3.88,
    },
    {
      rank: 12,
      roleTitle: 'Blaster',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 44,
      changeInJobAds: -10.2,
      averageSalary: 112581,
      changeInSalary: -1.03,
    },
    {
      rank: 13,
      roleTitle: 'Dozer Operator',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 90,
      changeInJobAds: -13.5,
      averageSalary: 120487,
      changeInSalary: -2.15,
    },
    {
      rank: 14,
      roleTitle: 'Process Operator',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 61,
      changeInJobAds: -14.1,
      averageSalary: 116580,
      changeInSalary: 7.71,
    },
    {
      rank: 15,
      roleTitle: 'Fitter',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 1,
      changeInJobAds: -15.6,
      averageSalary: 146511,
      changeInSalary: 3.29,
    },
    {
      rank: 16,
      roleTitle: 'Services Officer',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 53,
      changeInJobAds: -17.2,
      averageSalary: 127160,
      changeInSalary: 1.19,
    },
    {
      rank: 17,
      roleTitle: 'Electrician',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 701,
      changeInJobAds: -19.1,
      averageSalary: 149804,
      changeInSalary: 3.57,
    },
    {
      rank: 18,
      roleTitle: 'Safety Adviser',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 47,
      changeInJobAds: -25.4,
      averageSalary: 133934,
      changeInSalary: 0.16,
    },
    {
      rank: 19,
      roleTitle: 'Production Engineer',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 32,
      changeInJobAds: -25.6,
      averageSalary: 159414,
      changeInSalary: -0.15,
    },
    {
      rank: 20,
      roleTitle: 'Boilermaker',
      industryTitle: 'Mining, Resources & Energy',
      numberOfJobAds: 228,
      changeInJobAds: -26.7,
      averageSalary: 132430,
      changeInSalary: 3.08,
    },
  ],
  'Real Estate & Property': [
    {
      rank: 1,
      roleTitle: 'Sales Associate',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 387,
      changeInJobAds: 39.7,
      averageSalary: 63919,
      changeInSalary: 5.13,
    },
    {
      rank: 2,
      roleTitle: 'Executive Assistant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 76,
      changeInJobAds: 38.2,
      averageSalary: 91579,
      changeInSalary: 9.92,
    },
    {
      rank: 3,
      roleTitle: 'Leasing Consultant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 174,
      changeInJobAds: 34.9,
      averageSalary: 67103,
      changeInSalary: 8.3,
    },
    {
      rank: 4,
      roleTitle: 'Strata Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 222,
      changeInJobAds: 34.5,
      averageSalary: 93738,
      changeInSalary: 7.33,
    },
    {
      rank: 5,
      roleTitle: 'Sales Administrator',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 110,
      changeInJobAds: 15.8,
      averageSalary: 69637,
      changeInSalary: 5.91,
    },
    {
      rank: 6,
      roleTitle: 'Management Assistant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 75,
      changeInJobAds: 15.4,
      averageSalary: 60782,
      changeInSalary: 6.81,
    },
    {
      rank: 7,
      roleTitle: 'Property Officer',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 101,
      changeInJobAds: 11,
      averageSalary: 71421,
      changeInSalary: 10.12,
    },
    {
      rank: 8,
      roleTitle: 'Building Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 122,
      changeInJobAds: 8.9,
      averageSalary: 85513,
      changeInSalary: 6.1,
    },
    {
      rank: 9,
      roleTitle: 'Facilities Coordinator',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 98,
      changeInJobAds: 5.4,
      averageSalary: 80241,
      changeInSalary: -1.47,
    },
    {
      rank: 10,
      roleTitle: 'Assistant Development Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 56,
      changeInJobAds: 3.7,
      averageSalary: 109814,
      changeInSalary: 4.68,
    },
    {
      rank: 11,
      roleTitle: 'Sales Executive',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 34,
      changeInJobAds: 3,
      averageSalary: 84073,
      changeInSalary: -13.62,
    },
    {
      rank: 12,
      roleTitle: 'Property Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 2,
      changeInJobAds: -0.6,
      averageSalary: 80126,
      changeInSalary: 4.34,
    },
    {
      rank: 13,
      roleTitle: 'Business Development Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 137,
      changeInJobAds: -0.7,
      averageSalary: 91492,
      changeInSalary: 1.25,
    },
    {
      rank: 14,
      roleTitle: 'Facilities Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 245,
      changeInJobAds: -10.9,
      averageSalary: 111719,
      changeInSalary: 4.57,
    },
    {
      rank: 15,
      roleTitle: 'Leasing Agent',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 30,
      changeInJobAds: -14.3,
      averageSalary: 66905,
      changeInSalary: 5.94,
    },
    {
      rank: 16,
      roleTitle: 'Accountant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 32,
      changeInJobAds: -15.8,
      averageSalary: 87565,
      changeInSalary: 0.92,
    },
    {
      rank: 17,
      roleTitle: 'Sales Assistant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 66,
      changeInJobAds: -16.5,
      averageSalary: 63027,
      changeInSalary: 3.71,
    },
    {
      rank: 18,
      roleTitle: 'Personal Assistant',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 47,
      changeInJobAds: -26.6,
      averageSalary: 70631,
      changeInSalary: 1.86,
    },
    {
      rank: 19,
      roleTitle: 'Receptionist',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 66,
      changeInJobAds: -34,
      averageSalary: 57297,
      changeInSalary: 3.21,
    },
    {
      rank: 20,
      roleTitle: 'Administration Officer',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 47,
      changeInJobAds: -47.8,
      averageSalary: 63951,
      changeInSalary: -0.21,
    },
  ],
  'Retail & Consumer Products': [
    {
      rank: 1,
      roleTitle: 'Trolley Return Assistant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 234,
      changeInJobAds: 172.1,
      averageSalary: null,
      changeInSalary: null,
    },
    {
      rank: 2,
      roleTitle: 'Dispenser',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 115,
      changeInJobAds: 64.3,
      averageSalary: 59300,
      changeInSalary: 8.36,
    },
    {
      rank: 3,
      roleTitle: 'Optical Assistant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 202,
      changeInJobAds: 62.9,
      averageSalary: 54778,
      changeInSalary: 4.07,
    },
    {
      rank: 4,
      roleTitle: 'Sales Representative',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 127,
      changeInJobAds: 58.8,
      averageSalary: 70627,
      changeInSalary: 5.17,
    },
    {
      rank: 5,
      roleTitle: 'Console Operator',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 124,
      changeInJobAds: 51.2,
      averageSalary: 64523,
      changeInSalary: 1.89,
    },
    {
      rank: 6,
      roleTitle: 'Duty Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 152,
      changeInJobAds: 49,
      averageSalary: 66995,
      changeInSalary: -3.1,
    },
    {
      rank: 7,
      roleTitle: 'Concession Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 87,
      changeInJobAds: 45,
      averageSalary: 64774,
      changeInSalary: 4.44,
    },
    {
      rank: 8,
      roleTitle: 'Cluster Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 45,
      changeInJobAds: 40.6,
      averageSalary: 77405,
      changeInSalary: 8.88,
    },
    {
      rank: 9,
      roleTitle: 'Client Advisor',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 226,
      changeInJobAds: 38.7,
      averageSalary: 58997,
      changeInSalary: -0.66,
    },
    {
      rank: 10,
      roleTitle: 'Department Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 244,
      changeInJobAds: 35.6,
      averageSalary: 75641,
      changeInSalary: 2.91,
    },
    {
      rank: 11,
      roleTitle: 'Team Member',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 720,
      changeInJobAds: 18.2,
      averageSalary: 51943,
      changeInSalary: -1.42,
    },
    {
      rank: 12,
      roleTitle: 'Forklift Driver',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 44,
      changeInJobAds: 15.8,
      averageSalary: 57320,
      changeInSalary: 4.59,
    },
    {
      rank: 13,
      roleTitle: 'Management Trainee',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 61,
      changeInJobAds: 15.1,
      averageSalary: 66075,
      changeInSalary: 5.64,
    },
    {
      rank: 14,
      roleTitle: 'Client Assistant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 300,
      changeInJobAds: 14.1,
      averageSalary: null,
      changeInSalary: null,
    },
    {
      rank: 15,
      roleTitle: 'Boutique Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 166,
      changeInJobAds: 9.9,
      averageSalary: 71272,
      changeInSalary: 4.45,
    },
    {
      rank: 16,
      roleTitle: 'Ambassador',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 103,
      changeInJobAds: 5.1,
      averageSalary: 66776,
      changeInSalary: 2.01,
    },
    {
      rank: 17,
      roleTitle: 'Second in Charge',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 74,
      changeInJobAds: 2.8,
      averageSalary: 60996,
      changeInSalary: 4.16,
    },
    {
      rank: 18,
      roleTitle: 'Assistant Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 328,
      changeInJobAds: 0.3,
      averageSalary: 64343,
      changeInSalary: 4,
    },
    {
      rank: 19,
      roleTitle: 'Pharmacy Assistant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 188,
      changeInJobAds: 0,
      averageSalary: 49012,
      changeInSalary: 5.22,
    },
    {
      rank: 20,
      roleTitle: 'Sales Associate',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 1,
      changeInJobAds: -3.7,
      averageSalary: 61373,
      changeInSalary: 5.91,
    },
  ],
  Sales: [
    {
      rank: 1,
      roleTitle: 'Appointment Setter',
      industryTitle: 'Sales',
      numberOfJobAds: 59,
      changeInJobAds: 90.3,
      averageSalary: 70985,
      changeInSalary: -2.96,
    },
    {
      rank: 2,
      roleTitle: 'Travel Consultant',
      industryTitle: 'Sales',
      numberOfJobAds: 210,
      changeInJobAds: 87.5,
      averageSalary: 53407,
      changeInSalary: null,
    },
    {
      rank: 3,
      roleTitle: 'Recruitment Consultant',
      industryTitle: 'Sales',
      numberOfJobAds: 101,
      changeInJobAds: 46.4,
      averageSalary: 76047,
      changeInSalary: -0.54,
    },
    {
      rank: 4,
      roleTitle: 'Sales Associate',
      industryTitle: 'Sales',
      numberOfJobAds: 341,
      changeInJobAds: 39.2,
      averageSalary: 58888,
      changeInSalary: 2.22,
    },
    {
      rank: 5,
      roleTitle: 'Interpreter',
      industryTitle: 'Sales',
      numberOfJobAds: 41,
      changeInJobAds: 36.7,
      averageSalary: 66539,
      changeInSalary: 1.61,
    },
    {
      rank: 6,
      roleTitle: 'Sales and Business Development Manager',
      industryTitle: 'Sales',
      numberOfJobAds: 70,
      changeInJobAds: 29.6,
      averageSalary: 106717,
      changeInSalary: 3.91,
    },
    {
      rank: 7,
      roleTitle: 'Salesperson',
      industryTitle: 'Sales',
      numberOfJobAds: 552,
      changeInJobAds: 29.3,
      averageSalary: 64911,
      changeInSalary: -5.27,
    },
    {
      rank: 8,
      roleTitle: 'Sales Specialist',
      industryTitle: 'Sales',
      numberOfJobAds: 208,
      changeInJobAds: 26.8,
      averageSalary: 86134,
      changeInSalary: -2.36,
    },
    {
      rank: 9,
      roleTitle: 'Sales Adviser',
      industryTitle: 'Sales',
      numberOfJobAds: 39,
      changeInJobAds: 25.8,
      averageSalary: 68079,
      changeInSalary: -4.63,
    },
    {
      rank: 10,
      roleTitle: 'Customer Service Representative',
      industryTitle: 'Sales',
      numberOfJobAds: 86,
      changeInJobAds: 19.4,
      averageSalary: 65007,
      changeInSalary: 2.35,
    },
    {
      rank: 11,
      roleTitle: 'Business Development and Account Manager',
      industryTitle: 'Sales',
      numberOfJobAds: 38,
      changeInJobAds: 18.8,
      averageSalary: 101592,
      changeInSalary: -4.38,
    },
    {
      rank: 12,
      roleTitle: 'Business Development Officer',
      industryTitle: 'Sales',
      numberOfJobAds: 38,
      changeInJobAds: 15.2,
      averageSalary: 80510,
      changeInSalary: -6.98,
    },
    {
      rank: 13,
      roleTitle: 'Representative',
      industryTitle: 'Sales',
      numberOfJobAds: 50,
      changeInJobAds: 11.1,
      averageSalary: 74468,
      changeInSalary: -2.76,
    },
    {
      rank: 14,
      roleTitle: 'Sales Assistant',
      industryTitle: 'Sales',
      numberOfJobAds: 102,
      changeInJobAds: 7.4,
      averageSalary: 61876,
      changeInSalary: 0.58,
    },
    {
      rank: 15,
      roleTitle: 'Business Development Consultant',
      industryTitle: 'Sales',
      numberOfJobAds: 31,
      changeInJobAds: 3.3,
      averageSalary: 85716,
      changeInSalary: -6.55,
    },
    {
      rank: 16,
      roleTitle: 'Membership Consultant',
      industryTitle: 'Sales',
      numberOfJobAds: 53,
      changeInJobAds: 1.9,
      averageSalary: 59546,
      changeInSalary: -1.86,
    },
    {
      rank: 17,
      roleTitle: 'Relationship Manager',
      industryTitle: 'Sales',
      numberOfJobAds: 127,
      changeInJobAds: -5.9,
      averageSalary: 98478,
      changeInSalary: -1.71,
    },
    {
      rank: 18,
      roleTitle: 'Sales Agent',
      industryTitle: 'Sales',
      numberOfJobAds: 44,
      changeInJobAds: -8.3,
      averageSalary: 63337,
      changeInSalary: 5,
    },
    {
      rank: 19,
      roleTitle: 'Business Development Specialist',
      industryTitle: 'Sales',
      numberOfJobAds: 36,
      changeInJobAds: -10,
      averageSalary: 94000,
      changeInSalary: -5.9,
    },
    {
      rank: 20,
      roleTitle: 'Sales Representative',
      industryTitle: 'Sales',
      numberOfJobAds: 1,
      changeInJobAds: -10.7,
      averageSalary: 79941,
      changeInSalary: 4.43,
    },
  ],
  'Science & Technology': [
    {
      rank: 1,
      roleTitle: 'Laboratory Technician',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 229,
      changeInJobAds: -10.2,
      averageSalary: 67318,
      changeInSalary: 0.25,
    },
    {
      rank: 2,
      roleTitle: 'Laboratory Assistant',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 53,
      changeInJobAds: -20.9,
      averageSalary: 63859,
      changeInSalary: 3.09,
    },
    {
      rank: 3,
      roleTitle: 'Technologist',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 60,
      changeInJobAds: -23.1,
      averageSalary: 91393,
      changeInSalary: -0.99,
    },
    {
      rank: 4,
      roleTitle: 'Technical Officer',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 75,
      changeInJobAds: -24.2,
      averageSalary: 86360,
      changeInSalary: 5.68,
    },
    {
      rank: 5,
      roleTitle: 'Chemist',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 83,
      changeInJobAds: -28.4,
      averageSalary: 84550,
      changeInSalary: -3.03,
    },
  ],
  'Sport & Recreation': [
    {
      rank: 1,
      roleTitle: 'Instructor',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 446,
      changeInJobAds: 9,
      averageSalary: 75104,
      changeInSalary: 4.91,
    },
    {
      rank: 2,
      roleTitle: 'Coach',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 430,
      changeInJobAds: 6.4,
      averageSalary: 72759,
      changeInSalary: 4.18,
    },
    {
      rank: 3,
      roleTitle: 'Studio Manager',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 38,
      changeInJobAds: -15.6,
      averageSalary: 72303,
      changeInSalary: 7.37,
    },
    {
      rank: 4,
      roleTitle: 'Trainer',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 330,
      changeInJobAds: -16.9,
      averageSalary: 80553,
      changeInSalary: 7.46,
    },
    {
      rank: 5,
      roleTitle: 'Teacher',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 121,
      changeInJobAds: -28.8,
      averageSalary: 64773,
      changeInSalary: 6.74,
    },
    {
      rank: 6,
      roleTitle: 'Club Manager',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 163,
      changeInJobAds: -30,
      averageSalary: 71750,
      changeInSalary: 1.3,
    },
    {
      rank: 7,
      roleTitle: 'Coordinator',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 38,
      changeInJobAds: -32.1,
      averageSalary: 75930,
      changeInSalary: -1.84,
    },
    {
      rank: 8,
      roleTitle: 'Lifeguard',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 97,
      changeInJobAds: -42.6,
      averageSalary: 64347,
      changeInSalary: 4.7,
    },
    {
      rank: 9,
      roleTitle: 'Duty Manager',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 37,
      changeInJobAds: -47.1,
      averageSalary: 73918,
      changeInSalary: 10.93,
    },
  ],
  'Trades & Services': [
    {
      rank: 1,
      roleTitle: 'Maintenance Operator',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 59,
      changeInJobAds: 68.6,
      averageSalary: 63877,
      changeInSalary: 2.9,
    },
    {
      rank: 2,
      roleTitle: 'Maintenance Team Leader',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 123,
      changeInJobAds: 61.8,
      averageSalary: 69491,
      changeInSalary: 0.22,
    },
    {
      rank: 3,
      roleTitle: 'Contractor',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 50,
      changeInJobAds: 51.5,
      averageSalary: 91201,
      changeInSalary: -2.38,
    },
    {
      rank: 4,
      roleTitle: 'Arborist',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 153,
      changeInJobAds: 47.1,
      averageSalary: 77899,
      changeInSalary: 9.42,
    },
    {
      rank: 5,
      roleTitle: 'Formworker',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 73,
      changeInJobAds: 46,
      averageSalary: 99947,
      changeInSalary: 3.8,
    },
    {
      rank: 6,
      roleTitle: 'Pipefitter',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 50,
      changeInJobAds: 42.9,
      averageSalary: 106199,
      changeInSalary: 1.31,
    },
    {
      rank: 7,
      roleTitle: 'Refrigeration Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 164,
      changeInJobAds: 40.2,
      averageSalary: 103755,
      changeInSalary: 8.16,
    },
    {
      rank: 8,
      roleTitle: 'Roofer',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 133,
      changeInJobAds: 40,
      averageSalary: 87350,
      changeInSalary: 5.78,
    },
    {
      rank: 9,
      roleTitle: 'Drainer',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 73,
      changeInJobAds: 37.7,
      averageSalary: 91033,
      changeInSalary: 11.55,
    },
    {
      rank: 10,
      roleTitle: 'Inspector',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 118,
      changeInJobAds: 35.6,
      averageSalary: 84475,
      changeInSalary: -1.97,
    },
    {
      rank: 11,
      roleTitle: 'Security Supervisor',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 54,
      changeInJobAds: 35,
      averageSalary: 84320,
      changeInSalary: 25.88,
    },
    {
      rank: 12,
      roleTitle: 'Pest Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 74,
      changeInJobAds: 34.5,
      averageSalary: 66630,
      changeInSalary: 6.41,
    },
    {
      rank: 13,
      roleTitle: 'Heating, Ventilation and Air Conditioning Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 221,
      changeInJobAds: 30,
      averageSalary: 99060,
      changeInSalary: 0.79,
    },
    {
      rank: 14,
      roleTitle: 'Tree Worker',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 45,
      changeInJobAds: 28.6,
      averageSalary: 68061,
      changeInSalary: 4.45,
    },
    {
      rank: 15,
      roleTitle: 'Air Conditioning and Refrigeration Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 107,
      changeInJobAds: 27.4,
      averageSalary: 95631,
      changeInSalary: 5.27,
    },
    {
      rank: 16,
      roleTitle: 'Irrigation Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 53,
      changeInJobAds: 26.2,
      averageSalary: 71061,
      changeInSalary: -1.39,
    },
    {
      rank: 17,
      roleTitle: 'Decorator',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 82,
      changeInJobAds: 26.2,
      averageSalary: 73794,
      changeInSalary: 1.78,
    },
    {
      rank: 18,
      roleTitle: 'Truck Driver',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 155,
      changeInJobAds: 25,
      averageSalary: 70514,
      changeInSalary: 2.76,
    },
    {
      rank: 19,
      roleTitle: 'Crew',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 78,
      changeInJobAds: 23.8,
      averageSalary: 72311,
      changeInSalary: 0.68,
    },
    {
      rank: 20,
      roleTitle: 'Traffic Controller',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 245,
      changeInJobAds: 23.1,
      averageSalary: 73381,
      changeInSalary: 7.81,
    },
  ],
};
