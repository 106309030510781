import {
  Box,
  Heading,
  IconNewWindow,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import translations from './.vocab';
import { useTranslations } from '@vocab/react';
import logo from './images/logo.png';
import { useScreenSize } from '../../hooks/useScreenSize';
import type { DataAttributeMap } from '@seek/cmsu-components/src/modules/types';

export type Podcast = {
  appleUrl?: string | null;
  googleUrl?: string | null;
  spotifyUrl?: string | null;
};

type PodcastNames = 'Apple podcasts' | 'Google podcasts' | 'Spotify podcasts';
export type PodcastUrl = {
  name: PodcastNames;
  url: string;
  fieldApi: string;
};

type Props = {
  podcast: Podcast | null | undefined;
  data?: DataAttributeMap | undefined;
};

export const PodcastBanner = ({ podcast, data }: Props) => {
  const { t } = useTranslations(translations);
  const screenSize = useScreenSize();

  const podcastUrls = podcastUrlsFrom(podcast);

  if (podcastUrls.length === 0) return;

  const title = t('Subscribe to our podcast');

  return (
    <Box
      background="neutralLight"
      borderRadius="large"
      padding="large"
      display={{ mobile: 'block', tablet: 'flex' }}
      justifyContent="spaceBetween"
      alignItems="center"
      data={{ testid: 'PodcastBannerTestId' }}
    >
      <Stack space="medium">
        {screenSize.isMobile && <PodcastLogo alt={title} />}
        <Heading level="3" data={{ testid: 'PodcastTitleTestId' }}>
          {title}
        </Heading>
        {podcastUrls.map((podcastUrl, index) => (
          <Text
            size="standard"
            key={index}
            data={{ ...data, 'hygraph-field-api-id': podcastUrl.fieldApi }}
          >
            <TextLink
              href={podcastUrl.url}
              icon={<IconNewWindow />}
              iconPosition="trailing"
            >
              <span data-testid={`PodcastItem${index + 1}TestId`}>
                {t(podcastUrl.name)}
              </span>
            </TextLink>
          </Text>
        ))}
      </Stack>
      {!screenSize.isMobile && <PodcastLogo alt={title} />}
    </Box>
  );
};

const PodcastLogo = ({ alt }: { alt: string }) => (
  <Box paddingBottom={{ mobile: 'medium', tablet: 'none' }}>
    <img
      src={logo}
      alt={alt}
      style={{
        display: 'block',
        width: '120px',
      }}
    />
  </Box>
);

export const podcastUrlsFrom = (
  podcast: Podcast | null | undefined,
): PodcastUrl[] => {
  if (!podcast) return [];

  const podcastUrls: PodcastUrl[] = [];

  handleAddPodcastUrl(
    podcastUrls,
    'Apple podcasts',
    podcast.appleUrl,
    'appleUrl',
  );
  handleAddPodcastUrl(
    podcastUrls,
    'Google podcasts',
    podcast.googleUrl,
    'googleUrl',
  );
  handleAddPodcastUrl(
    podcastUrls,
    'Spotify podcasts',
    podcast.spotifyUrl,
    'spotifyUrl',
  );

  return podcastUrls;
};

const handleAddPodcastUrl = (
  podcastUrls: PodcastUrl[],
  name: PodcastNames,
  url: string | null | undefined,
  fieldApi: string,
) => {
  if (url) podcastUrls.push({ name, url, fieldApi });
};
