import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client';
import { hydrateRoot } from 'react-dom/client';
import { createBrowserRouter } from 'react-router-dom';
import { loadableReady } from 'sku/@loadable/component';
import { RouterProvider } from 'react-router';
import { makeRoutes } from '../routes';
import { deHyphen, initTealiumTracker } from '@seek/cmsu-analytics';
import { setupDatadogRum } from '../client/shared/utils/datadog';
import { hygraphQueryHeaders } from '@seek/cmsu-components/src/helpers/hygraphHelper';

loadableReady(async () => {
  const container = document.getElementById('app');

  if (!container) {
    throw Error('App container (#app) not found.');
  }

  const config = window.__CONFIG_STATE__;

  const { section, brand, country, language, zone, environment, site } = config;
  const datadogRum = setupDatadogRum(environment, config);

  initTealiumTracker({
    siteSection: deHyphen(section),
    brand,
    siteCountry: site.includes('root') ? 'ZZ' : country, // 'zz' is the default country code for root sites, its hard-coded(Not in Melways Country) as its currently apply for tealium tracking only
    siteLanguage: language,
    zone,
  });

  datadogRum.startSessionReplayRecording();

  const client = new ApolloClient({
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    headers: hygraphQueryHeaders,
    link: from([
      createHttpLink({
        uri: config.hygraphContentApi,
      }),
    ]),
  });

  const clientTAL = new ApolloClient({
    cache: new InMemoryCache().restore(window.__APOLLO_STATE_TAL__),
    link: from([
      createHttpLink({
        uri: config.talApi,
      }),
    ]),
  });

  const router = createBrowserRouter(makeRoutes({ client, clientTAL, config }));

  hydrateRoot(container, <RouterProvider router={router} />);
});
