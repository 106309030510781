export const isAboutSectionSearch = (section: string) => section === 'about';
export const isCareersSectionSearch = (section: string) =>
  section === 'careers' || section === 'life-at-seek';
export const getSearchSection = (section: string) => {
  switch (section) {
    case 'about':
      return 'news';
    case 'careers':
      return 'careers';
    case 'life-at-seek':
      return 'life-at-seek';
    default:
      return 'hami';
  }
};

export const getSearchPathBy = (section: string) => {
  switch (section) {
    case 'about':
      return 'about/search';
    case 'careers':
      return 'careers/life-at-seek/search';
    default:
      return 'article-search';
  }
};
