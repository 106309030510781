import { siteNames } from '@seek/melways-sites';
import type {
  CandidateSiteName,
  EmployerSiteName,
  AsiaRootSites,
} from '../types';

const lookupHygraphSiteName: Record<
  AsiaRootSites,
  CandidateSiteName | EmployerSiteName
> = {
  'candidate-jobsdb-root': siteNames.candidate.jobsdb.hk,
  'candidate-jobstreet-root': siteNames.candidate.jobstreet.my,
};

/**
 * Lookup a supplied Melways `siteName` and returns the associated Hygraph siteName.
 * Currently HyGraph siteNames are the same as Melways non-consolidated siteNames.
 * @param siteName - The Melways siteName to convert
 */
export const convertToHygraphSiteName = (
  siteName: CandidateSiteName | EmployerSiteName,
): CandidateSiteName | EmployerSiteName => {
  if (lookupHygraphSiteName.hasOwnProperty(siteName)) {
    return lookupHygraphSiteName[siteName as AsiaRootSites];
  }
  return siteName;
};
