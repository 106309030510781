import { Box } from 'braid-design-system';
import { logo } from '../styles.css';

interface Props {
  src: string;
  alt?: string;
  size: {
    width: number;
    height: number;
  };
}

const Image = ({ src, alt, size }: Exclude<Props, 'href'>) => (
  <Box
    data={{ testid: 'footer-logo-image' }}
    className={logo}
    display="block"
    style={{
      width: size.width,
      maxWidth: size.width || '180px',
      maxHeight: '60px',
      backgroundImage: `url(${src})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: 'contain',
    }}
    aria-label={alt}
  />
);

export const FooterLogoImage = ({ src, alt, size }: Exclude<Props, 'href'>) => (
  <Image src={src} alt={alt} size={size} />
);
