import React from 'react';
import { Box, Stack, ContentBlock } from 'braid-design-system';
import { Paragraph } from '../../../Paragraph/Paragraph';
import { Video } from '../../../Video/Video';
import {
  BorderRadius,
  CParagraphMarginBottom,
  CTextTone,
  CTextWeight,
  type BannerAwardFragment,
} from '@seek/cmsu-cms-connect';
import { ActionGroup } from '../../../ActionGroup/ActionGroup';
import { getVideoUrl } from '../helpers';
import { Heading } from '../../../Heading/Heading';

import * as styles from './styles.css';

export const StarBanner = ({
  paragraph,
  heading,
  actionGroup,
  backgroundImage,
  video,
}: Omit<BannerAwardFragment, '__typename' | 'id'>) => {
  const minHeight = '400px';
  const videoUrl = getVideoUrl(video);

  return (
    <Box
      background={!backgroundImage ? 'brand' : undefined}
      style={{
        ...(backgroundImage && {
          backgroundImage: `url(${backgroundImage.url})`,
        }),
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <ContentBlock width="large">
        <Box
          data={{ testid: 'AwardsBannerTestId' }}
          display="flex"
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          gap={'xlarge'}
          paddingTop="xlarge"
          paddingBottom={video ? 'xlarge' : undefined}
          paddingX={{ mobile: 'small', tablet: 'medium', wide: 'none' }}
          style={{
            minHeight,
            maxWidth: '2000px',
          }}
          width="full"
          height="full"
        >
          <Box
            id="content"
            borderRadius="none"
            padding={!video ? 'large' : undefined}
            background="brand"
            style={{ flexBasis: '40%' }}
            className={styles.contentBorderRadius}
          >
            <Stack space="small">
              {heading?.text ? (
                <Heading
                  {...heading}
                  weight={heading.weight || CTextWeight.Regular}
                />
              ) : null}
              {paragraph && (
                <Box paddingY="small">
                  <Paragraph
                    content={paragraph.Paragraph_text?.raw}
                    marginBottom={CParagraphMarginBottom.None}
                    align={paragraph.align}
                    tone={paragraph.tone || CTextTone.Neutral}
                    size={paragraph.size}
                  />
                </Box>
              )}
              {actionGroup && (
                <Box paddingTop="small">
                  <ActionGroup {...actionGroup} />
                </Box>
              )}
            </Stack>
          </Box>
          <Box style={{ flexBasis: '60%' }} flexGrow={1}>
            {videoUrl && (
              <Box data={{ testid: 'video' }}>
                <Video
                  size="small"
                  borderRadius={BorderRadius.Xlarge}
                  url={videoUrl}
                  alignX="right"
                />
              </Box>
            )}
          </Box>
        </Box>
      </ContentBlock>
    </Box>
  );
};
