import {
  type Article,
  type Category,
  type Locale,
  type Section,
  ArticleFeature,
} from '@seek/cmsu-cms-connect';
import {
  Bleed,
  Box,
  ContentBlock,
  Heading,
  Pagination,
  Stack,
  Tiles,
} from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLoaderData } from 'react-router-dom';

import { PageLayout } from '../components/PageLayout/PageLayout';
import { useAuth, useConfig } from '../shared/hooks';
import {
  CMS_PAGE_DISPLAYED_EVENT,
  getLoggedInStatus,
  trackEvent,
  type TrackingData,
} from '@seek/cmsu-analytics';

import {
  SubscriptionForm,
  Breadcrumbs,
  LatestArticlesByCategory,
} from '@seek/cmsu-components';
import { WPT_SALESFORCE_API } from '../../config';
import { convertMelwaysLocale } from '../../helpers/convertMelwaysLocale';
import type { LocalesInfo } from '../shared/utils/getLocales';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import { useMelwaysLink } from '@seek/melways-react';
import { enabledSubscriptionForm } from '../shared/utils/helper';
import { getPageNumberFrom, getTotalPages } from '../shared/utils/Pagination';
import type { CategoryLocalization } from '../../helpers/loadersHelper';
import type { Brand, Language } from '@seek/melways-sites';
import { SearchBoxWrapper } from '../components/SearchBoxWrapper/SearchBoxWrapper';
import { ArticleItem } from '@seek/cmsu-components/src/modules/ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '@seek/cmsu-components/src/helpers/articleHelper';
import { SubscribeFormContactUsWrapper } from './pageComponents/SubscribeFormContactUsWrapper';
import { getBreadcrumbItems } from '@seek/cmsu-components/src/modules/Breadcrumbs/breadcrumbHelper';
import { usePageTrackingData } from '../shared/hooks/usePageTracking';
import { transformSeoData } from 'src/helpers/seo';

export const ArticlesCategory = () => {
  const { slug, pageNumber } = useParams();
  const { authenticationStatus } = useAuth();
  const { melwaysLocale, sourceName, country, language, site, brand } =
    useConfig();
  const locale = convertMelwaysLocale(melwaysLocale);

  const data = useLoaderData() as CategoryLocalization & LocalesInfo;
  const articles = data.articles.edges.map(
    ({ node: article }) => article as Article,
  );
  const category = data.category as Category;
  const totalArticles = data.articles.aggregate.count;

  // Casting because we shouldn't be able to see the category page without a
  // section anyway
  const section = category.relatedCategorySection as Section;
  const seo = category.seo as Category['seo'];
  const pageSeo = transformSeoData(seo || {}, {
    title: data.category.name,
    description: data.category.description?.text,
  });

  const hasChildCategories = category.children.length > 0;
  const hasArticles = articles.length > 0;
  const isCandidate = site.startsWith('candidate');

  const trackingData = usePageTrackingData(
    category.name.toLocaleLowerCase() || '',
  ) as TrackingData;

  useEffect(() => {
    const { isPending, isLoggedIn } = getLoggedInStatus(authenticationStatus);
    if (!isPending) {
      trackEvent(CMS_PAGE_DISPLAYED_EVENT, {
        currentPage: category.name.toLocaleLowerCase() || '',
        isLoggedIn,
        [isCandidate ? 'loginId' : 'userId']: isCandidate
          ? trackingData.loginId
          : trackingData.userId,
        ...(trackingData.seekAdvertiserId
          ? { seekAdvertiserId: trackingData.seekAdvertiserId }
          : {}),
      });
    }
  }, [category, slug, authenticationStatus, trackingData, isCandidate]);

  const parentCategoryName = category.parent?.name || '';
  const parentCategorySlug = category.parent?.slug || '';
  const parentSection =
    category.relatedCategorySection?.parentSection?.sectionName;
  const sectionDisplayName = category.relatedCategorySection?.displayName;
  const parentSectionDisplayName =
    category.relatedCategorySection?.parentSection?.displayName;
  const items = getBreadcrumbItems({
    locale: locale as Locale,
    section: section.sectionName,
    parentCategoryName,
    parentCategorySlug,
    categoryName: category.name || '',
    categorySlug: category.slug || '',
    title: '',
    parentSection,
    sectionDisplayName,
    parentSectionDisplayName,
  });
  const showSubscriptionForm = enabledSubscriptionForm(section.sectionName);

  return (
    <PageLayout
      layoutProps={{
        title: category.name || '',
        seo: pageSeo,
        categoryName: category.name || slug,
        locales: data.locales,
        section: {
          sectionName: section.sectionName,
          theme: section?.theme === null ? undefined : section?.theme,
          headerOptions:
            section?.headerOptions === null
              ? undefined
              : section?.headerOptions,
          footerOptions:
            section?.footerOptions === null
              ? undefined
              : section?.footerOptions,
        },
      }}
    >
      <Box background="surface">
        <CMSUProvider config={{ language }}>
          <ContentBlock width="medium">
            <Box
              paddingX={{ mobile: 'small', tablet: 'medium', desktop: 'none' }}
            >
              {category.name && (
                <Box paddingY="large" background="surface">
                  <Breadcrumbs items={items} />
                </Box>
              )}

              <Box paddingBottom="xlarge" background="surface">
                {category && (
                  <Box paddingBottom="large">
                    <Heading level="1">{category.name}</Heading>
                  </Box>
                )}
                <Stack space="large">
                  {hasChildCategories && <SearchBoxWrapper />}

                  {hasArticles && (
                    <ChildCategoryLayout
                      articles={articles}
                      totalArticles={totalArticles}
                      language={language}
                      brand={brand}
                      sectionSlug={section.sectionName}
                      categorySlug={slug ? slug : category.slug}
                      pageNumber={pageNumber}
                      parentSection={parentSection}
                      relatedSectionName={section.sectionName}
                    />
                  )}

                  {hasChildCategories && (
                    <Box paddingTop={hasArticles ? 'xlarge' : 'none'}>
                      <ParentCategoryLayout
                        brand={brand}
                        category={category}
                        language={language}
                        section={section.sectionName}
                      />
                    </Box>
                  )}
                  <SubscribeFormContactUsWrapper
                    contactUs={data.contactUs}
                    formData={data.formData}
                  />
                </Stack>
                {showSubscriptionForm && (
                  <Bleed
                    horizontal={{
                      mobile: 'small',
                      tablet: 'medium',
                      desktop: 'none',
                    }}
                  >
                    <Box
                      marginTop={{
                        mobile: 'gutter',
                        tablet: 'xlarge',
                        desktop: 'xxlarge',
                      }}
                    >
                      <SubscriptionForm
                        type={section.sectionName}
                        locale={melwaysLocale}
                        sourceName={sourceName}
                        wptSalesForceApi={WPT_SALESFORCE_API}
                        country={country}
                        submitBtnStyle={{ tone: 'brandAccent' }}
                      />
                    </Box>
                  </Bleed>
                )}
              </Box>
            </Box>
          </ContentBlock>
        </CMSUProvider>
      </Box>
    </PageLayout>
  );
};

type ChildCategoryLayoutProps = {
  articles: Article[];
  brand: Brand;
  totalArticles: number;
  language: Language;
  sectionSlug: string;
  categorySlug: string;
  pageNumber: string | undefined;
  parentSection: string | undefined;
  relatedSectionName: string | undefined;
};

const ChildCategoryLayout = ({
  articles,
  brand,
  totalArticles,
  language,
  sectionSlug,
  categorySlug,
  pageNumber,
  parentSection,
  relatedSectionName,
}: ChildCategoryLayoutProps) => {
  const urlResolver = useMelwaysLink();

  const totalPages = getTotalPages(totalArticles);
  const currentPageNumber = getPageNumberFrom(pageNumber);
  const hasMoreThanOnePage = totalPages > 1;
  const section = parentSection
    ? `${parentSection}/${relatedSectionName}`
    : sectionSlug;
  return (
    <Stack space="large">
      <Tiles
        space="gutter"
        columns={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
          wide: 3,
        }}
      >
        {articles &&
          articles.map((article) => (
            <ArticleItem
              displayType={ITEM_DISPLAY_TYPE.CARD}
              article={article as Article}
              language={language}
              brand={brand}
              section={section}
              fieldVisibility={{
                showCategory: true,
                showPublishDate: true,
              }}
              key={article.id}
            />
          ))}
      </Tiles>
      {hasMoreThanOnePage && (
        <Box>
          <Pagination
            page={currentPageNumber}
            total={totalPages}
            label="pagination"
            linkProps={({ page: pageInPagination }) => ({
              href: urlResolver({
                language,
                brand,
                path: `/${section}/category/${categorySlug}/${pageInPagination}`,
              }),
            })}
          />
        </Box>
      )}
    </Stack>
  );
};

type ParentCategoryLayoutProps = {
  brand: Brand;
  category: Category;
  language: Language;
  section?: string;
};

const ParentCategoryLayout = ({
  brand,
  category,
  language,
  section,
}: ParentCategoryLayoutProps) => {
  const childCategoryWithArticles = category.children.filter(
    (child) => child.relatedArticlesInCategory.length > 0,
  );
  return (
    <Stack space="xlarge">
      {childCategoryWithArticles.map((child) => (
        <LatestArticlesByCategory
          key={child.id}
          brand={brand}
          category={child}
          language={language}
          sectionOverride={section}
          latestArticlesByCategory={{
            category: child,
            cardFields: [ArticleFeature.PublishDate],
          }}
        />
      ))}
    </Stack>
  );
};
