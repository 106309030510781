import {
  Box,
  ButtonIcon,
  Hidden,
  IconChevron,
  Stack,
} from 'braid-design-system';
import React, { type MouseEvent, type UIEvent, useState } from 'react';

import {
  carousel,
  carouselItem,
  slideContainer,
  arrows,
} from './TestimonialCarousel.css';
import { getCarouselSlides } from '../../utils/get-carousel.slides';
import { TestimonialCard } from '../TestimonialCard/TestimonialCard';
import { TestimonialCarouselBottomNav } from '../TestimonialCarouselBottomNav/TestimonialCarouselBottomNav';
import type { TestimonialsCarouselProps } from '../../TestimonialGroup';

export const MAX_CARDS_PER_SLIDE = 3;
const X_AXIS_RANGE_DELTA = 10;

export const TestimonialCarousel = ({
  testimonials,
  cardBackground,
}: TestimonialsCarouselProps) => {
  const slides = getCarouselSlides(testimonials);

  const [focusedSlideIndex, setFocusedSlideIndex] = useState(0);
  const isLastSlide = focusedSlideIndex >= slides.length - 1;
  const isFirstSlide = focusedSlideIndex <= 0;
  const ref = React.createRef<HTMLElement>();

  const moveSlidesLeft = (event: MouseEvent, slidesToMove: number = 1) => {
    event.preventDefault();
    if (isFirstSlide) {
      return;
    }
    const carouselDiv = ref.current;
    if (carouselDiv) {
      carouselDiv.scrollLeft -= carouselDiv.clientWidth * slidesToMove;
    }
  };

  const moveSlidesRight = (event: MouseEvent, slidesToMove: number = 1) => {
    event.preventDefault();
    if (isLastSlide) {
      return;
    }
    const carouselDiv = ref.current;
    if (carouselDiv) {
      carouselDiv.scrollLeft += carouselDiv.clientWidth * slidesToMove;
    }
  };

  const handleScroll = (event: UIEvent) => {
    event.preventDefault();
    const carouselDiv = ref.current;
    if (!carouselDiv) {
      return;
    }

    const carouselX = carouselDiv.getBoundingClientRect()?.x || 0;
    const carouselXRangeMin = carouselX - X_AXIS_RANGE_DELTA;
    const carouselXRangeMax = carouselX + X_AXIS_RANGE_DELTA;

    Array.from(carouselDiv.children).some((item, index) => {
      const { x } = item.getBoundingClientRect();

      if (carouselXRangeMin <= x && x <= carouselXRangeMax) {
        setFocusedSlideIndex(index);
        return true;
      }
    });
  };

  return (
    <Stack space="small">
      <Box position="relative" display="flex" alignItems="center">
        {!isFirstSlide && (
          <Box
            className={arrows.left}
            background="surface"
            boxShadow="borderNeutralLight"
            onClick={moveSlidesLeft}
          >
            <ButtonIcon
              id="cp-carousel-previous"
              icon={<IconChevron tone="secondary" direction="left" />}
              size="large"
              label={'Prev'}
              variant="transparent"
              bleed={false}
            />
          </Box>
        )}
        <Box className={carousel} ref={ref} onScroll={handleScroll}>
          {slides.map((slide, indexSlide) => (
            <Box key={indexSlide} className={slideContainer}>
              {slide.map((testimonial, index) => (
                <Box
                  key={index}
                  position="relative"
                  className={carouselItem}
                  height="full"
                >
                  <TestimonialCard
                    testimonial={testimonial}
                    cardBackground={cardBackground}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        {!isLastSlide && (
          <Box
            className={arrows.right}
            background="surface"
            boxShadow="borderNeutralLight"
            onClick={moveSlidesRight}
          >
            <ButtonIcon
              id="cp-carousel-next"
              icon={<IconChevron tone="secondary" direction="right" />}
              size="large"
              label={'Next'}
              variant="transparent"
              data={{ testid: 'testimonial-carousel-next-navigation-button' }}
              bleed={false}
            />
          </Box>
        )}
      </Box>
      <Hidden below="desktop">
        <TestimonialCarouselBottomNav
          focusedSlideIndex={focusedSlideIndex}
          moveSlidesLeft={moveSlidesLeft}
          moveSlidesRight={moveSlidesRight}
          slides={slides}
        />
      </Hidden>
    </Stack>
  );
};
