import type { TopJobGrowthData } from '../types';

// THIS IS GENERATED DATA FROM SCRIPT.JS
export const NZ_TOP_GROWTH_JOBS_CLASSIFICATION_DATA: Record<
  string,
  TopJobGrowthData[]
> = {
  Accounting: [
    {
      rank: 1,
      roleTitle: 'Auditor',
      industryTitle: 'Accounting',
      numberOfJobAds: 54,
      changeInJobAds: 42.1,
      averageSalary: 84192,
      changeInSalary: 8.19,
    },
    {
      rank: 2,
      roleTitle: 'Finance Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 130,
      changeInJobAds: -12.2,
      averageSalary: 132998,
      changeInSalary: 1.84,
    },
    {
      rank: 3,
      roleTitle: 'Accounts Assistant',
      industryTitle: 'Accounting',
      numberOfJobAds: 51,
      changeInJobAds: -13.6,
      averageSalary: 66188,
      changeInSalary: 4.07,
    },
    {
      rank: 4,
      roleTitle: 'Accountant',
      industryTitle: 'Accounting',
      numberOfJobAds: 817,
      changeInJobAds: -21.2,
      averageSalary: 92055,
      changeInSalary: 2.03,
    },
    {
      rank: 5,
      roleTitle: 'Financial Analyst',
      industryTitle: 'Accounting',
      numberOfJobAds: 31,
      changeInJobAds: -22.5,
      averageSalary: 105666,
      changeInSalary: 2.41,
    },
    {
      rank: 6,
      roleTitle: 'Financial Administrator',
      industryTitle: 'Accounting',
      numberOfJobAds: 38,
      changeInJobAds: -34.5,
      averageSalary: 66936,
      changeInSalary: 2.97,
    },
    {
      rank: 7,
      roleTitle: 'Business Advisory Manager',
      industryTitle: 'Accounting',
      numberOfJobAds: 30,
      changeInJobAds: -34.8,
      averageSalary: 119167,
      changeInSalary: 2.36,
    },
    {
      rank: 8,
      roleTitle: 'Accounts Administrator',
      industryTitle: 'Accounting',
      numberOfJobAds: 78,
      changeInJobAds: -41.4,
      averageSalary: 66765,
      changeInSalary: 5.63,
    },
    {
      rank: 9,
      roleTitle: 'Partner',
      industryTitle: 'Accounting',
      numberOfJobAds: 65,
      changeInJobAds: -42.5,
      averageSalary: 120365,
      changeInSalary: -4.39,
    },
  ],
  'Administration & Office Support': [
    {
      rank: 1,
      roleTitle: 'Support Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 61,
      changeInJobAds: -12.9,
      averageSalary: 64171,
      changeInSalary: 6.08,
    },
    {
      rank: 2,
      roleTitle: 'Executive Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 149,
      changeInJobAds: -29.7,
      averageSalary: 81449,
      changeInSalary: 2.58,
    },
    {
      rank: 3,
      roleTitle: 'Office Manager',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 84,
      changeInJobAds: -30,
      averageSalary: 73195,
      changeInSalary: 5.42,
    },
    {
      rank: 4,
      roleTitle: 'Receptionist',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 182,
      changeInJobAds: -35.9,
      averageSalary: 54816,
      changeInSalary: 4.54,
    },
    {
      rank: 5,
      roleTitle: 'Office Administrator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 193,
      changeInJobAds: -36.3,
      averageSalary: 59102,
      changeInSalary: 0.09,
    },
    {
      rank: 6,
      roleTitle: 'Personal Assistant',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 79,
      changeInJobAds: -36.8,
      averageSalary: 70908,
      changeInSalary: 0.92,
    },
    {
      rank: 7,
      roleTitle: 'Administration Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 392,
      changeInJobAds: -41.5,
      averageSalary: 59629,
      changeInSalary: 2.4,
    },
    {
      rank: 8,
      roleTitle: 'Coordinator',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 46,
      changeInJobAds: -45.9,
      averageSalary: 65803,
      changeInSalary: -0.11,
    },
    {
      rank: 9,
      roleTitle: 'Support Officer',
      industryTitle: 'Administration & Office Support',
      numberOfJobAds: 69,
      changeInJobAds: -48.1,
      averageSalary: 63337,
      changeInSalary: 5.96,
    },
  ],
  'Banking & Financial Services': [
    {
      rank: 1,
      roleTitle: 'Analyst',
      industryTitle: 'Banking & Financial Services',
      numberOfJobAds: 44,
      changeInJobAds: 41.9,
      averageSalary: 106020,
      changeInSalary: 10.58,
    },
  ],
  'Call Centre & Customer Service': [
    {
      rank: 1,
      roleTitle: 'Customer Service Representative',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 269,
      changeInJobAds: -36.9,
      averageSalary: 56879,
      changeInSalary: 2.99,
    },
    {
      rank: 2,
      roleTitle: 'Centre Operator',
      industryTitle: 'Call Centre & Customer Service',
      numberOfJobAds: 34,
      changeInJobAds: -46.9,
      averageSalary: 56199,
      changeInSalary: 3.89,
    },
  ],
  'Community Services & Development': [
    {
      rank: 1,
      roleTitle: 'Support Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 876,
      changeInJobAds: -4.8,
      averageSalary: 51475,
      changeInSalary: 0.34,
    },
    {
      rank: 2,
      roleTitle: 'Caregiver',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 88,
      changeInJobAds: -5.4,
      averageSalary: 51757,
      changeInSalary: null,
    },
    {
      rank: 3,
      roleTitle: 'Social Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 121,
      changeInJobAds: -28,
      averageSalary: 87711,
      changeInSalary: 4.98,
    },
    {
      rank: 4,
      roleTitle: 'Youth Worker',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 69,
      changeInJobAds: -31.7,
      averageSalary: 57634,
      changeInSalary: -0.05,
    },
    {
      rank: 5,
      roleTitle: 'Coordinator',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 32,
      changeInJobAds: -39.6,
      averageSalary: 65031,
      changeInSalary: -1.9,
    },
    {
      rank: 6,
      roleTitle: 'Navigator',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 44,
      changeInJobAds: -48.8,
      averageSalary: 64284,
      changeInSalary: 7.11,
    },
    {
      rank: 7,
      roleTitle: 'Facilitator',
      industryTitle: 'Community Services & Development',
      numberOfJobAds: 30,
      changeInJobAds: -49.2,
      averageSalary: 65080,
      changeInSalary: 3.09,
    },
  ],
  Construction: [
    {
      rank: 1,
      roleTitle: 'Surveyor',
      industryTitle: 'Construction',
      numberOfJobAds: 119,
      changeInJobAds: 0,
      averageSalary: 114258,
      changeInSalary: 6.08,
    },
    {
      rank: 2,
      roleTitle: 'Excavator Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 38,
      changeInJobAds: -17.4,
      averageSalary: 65708,
      changeInSalary: 3.46,
    },
    {
      rank: 3,
      roleTitle: 'Site Manager',
      industryTitle: 'Construction',
      numberOfJobAds: 84,
      changeInJobAds: -30,
      averageSalary: 125555,
      changeInSalary: 5.45,
    },
    {
      rank: 4,
      roleTitle: 'Site Traffic Management Supervisor',
      industryTitle: 'Construction',
      numberOfJobAds: 64,
      changeInJobAds: -46.7,
      averageSalary: 60560,
      changeInSalary: 4.22,
    },
    {
      rank: 5,
      roleTitle: 'Foreperson',
      industryTitle: 'Construction',
      numberOfJobAds: 65,
      changeInJobAds: -50,
      averageSalary: 77792,
      changeInSalary: 10.97,
    },
    {
      rank: 6,
      roleTitle: 'Operator',
      industryTitle: 'Construction',
      numberOfJobAds: 41,
      changeInJobAds: -54.9,
      averageSalary: 60435,
      changeInSalary: 8.16,
    },
  ],
  'Design & Architecture': [
    {
      rank: 1,
      roleTitle: 'Planner',
      industryTitle: 'Design & Architecture',
      numberOfJobAds: 37,
      changeInJobAds: -44.8,
      averageSalary: 103781,
      changeInSalary: -0.44,
    },
  ],
  'Education & Training': [
    {
      rank: 1,
      roleTitle: 'Centre Manager',
      industryTitle: 'Education & Training',
      numberOfJobAds: 63,
      changeInJobAds: 37,
      averageSalary: 98272,
      changeInSalary: 7.94,
    },
    {
      rank: 2,
      roleTitle: 'Education Adviser',
      industryTitle: 'Education & Training',
      numberOfJobAds: 36,
      changeInJobAds: 0,
      averageSalary: 93641,
      changeInSalary: 3.78,
    },
    {
      rank: 3,
      roleTitle: 'Teachers Aide',
      industryTitle: 'Education & Training',
      numberOfJobAds: 81,
      changeInJobAds: -19.8,
      averageSalary: 56114,
      changeInSalary: 1.93,
    },
    {
      rank: 4,
      roleTitle: 'Teacher / Kaiako',
      industryTitle: 'Education & Training',
      numberOfJobAds: 255,
      changeInJobAds: -25.9,
      averageSalary: 78510,
      changeInSalary: 2.54,
    },
    {
      rank: 5,
      roleTitle: 'Educator',
      industryTitle: 'Education & Training',
      numberOfJobAds: 30,
      changeInJobAds: -26.8,
      averageSalary: 64722,
      changeInSalary: -11.57,
    },
    {
      rank: 6,
      roleTitle: 'Tutor',
      industryTitle: 'Education & Training',
      numberOfJobAds: 69,
      changeInJobAds: -28.9,
      averageSalary: 70698,
      changeInSalary: 4.91,
    },
    {
      rank: 7,
      roleTitle: 'Adviser',
      industryTitle: 'Education & Training',
      numberOfJobAds: 30,
      changeInJobAds: -34.8,
      averageSalary: 85406,
      changeInSalary: -14.01,
    },
    {
      rank: 8,
      roleTitle: 'Early Childhood Teacher',
      industryTitle: 'Education & Training',
      numberOfJobAds: 84,
      changeInJobAds: -38.2,
      averageSalary: 69820,
      changeInSalary: 7.01,
    },
  ],
  Engineering: [
    {
      rank: 1,
      roleTitle: 'Fitter',
      industryTitle: 'Engineering',
      numberOfJobAds: 40,
      changeInJobAds: 5.3,
      averageSalary: 84154,
      changeInSalary: 10.85,
    },
    {
      rank: 2,
      roleTitle: 'Development Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 41,
      changeInJobAds: -21.2,
      averageSalary: 100680,
      changeInSalary: 4.25,
    },
    {
      rank: 3,
      roleTitle: 'Electrical Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 50,
      changeInJobAds: -37.5,
      averageSalary: 123687,
      changeInSalary: 12.41,
    },
    {
      rank: 4,
      roleTitle: 'Maintenance Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 62,
      changeInJobAds: -43.6,
      averageSalary: 88122,
      changeInSalary: 5.53,
    },
    {
      rank: 5,
      roleTitle: 'Site Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 35,
      changeInJobAds: -44.4,
      averageSalary: 94788,
      changeInSalary: 0.69,
    },
    {
      rank: 6,
      roleTitle: 'Project Engineer',
      industryTitle: 'Engineering',
      numberOfJobAds: 81,
      changeInJobAds: -54.2,
      averageSalary: 115166,
      changeInSalary: 4.14,
    },
  ],
  'Farming, Animals & Conservation': [
    {
      rank: 1,
      roleTitle: 'Veterinarian',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 76,
      changeInJobAds: 31,
      averageSalary: 116515,
      changeInSalary: 8.94,
    },
    {
      rank: 2,
      roleTitle: 'Nurse',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 91,
      changeInJobAds: -13.3,
      averageSalary: 58349,
      changeInSalary: 3.56,
    },
    {
      rank: 3,
      roleTitle: 'Ranger',
      industryTitle: 'Farming, Animals & Conservation',
      numberOfJobAds: 33,
      changeInJobAds: -31.3,
      averageSalary: 64956,
      changeInSalary: -6.65,
    },
  ],
  'Government & Defence': [
    {
      rank: 1,
      roleTitle: 'Adviser',
      industryTitle: 'Government & Defence',
      numberOfJobAds: 118,
      changeInJobAds: -28,
      averageSalary: 114203,
      changeInSalary: 0.01,
    },
  ],
  'Healthcare & Medical': [
    {
      rank: 1,
      roleTitle: 'General Practitioner',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 164,
      changeInJobAds: 46.4,
      averageSalary: 205329,
      changeInSalary: -1.42,
    },
    {
      rank: 2,
      roleTitle: 'Technologist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 63,
      changeInJobAds: 43.2,
      averageSalary: 106429,
      changeInSalary: 4.62,
    },
    {
      rank: 3,
      roleTitle: 'Practice Manager',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 48,
      changeInJobAds: 37.1,
      averageSalary: 87487,
      changeInSalary: 12.34,
    },
    {
      rank: 4,
      roleTitle: 'Pharmacist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 174,
      changeInJobAds: 17.6,
      averageSalary: 100022,
      changeInSalary: 6.14,
    },
    {
      rank: 5,
      roleTitle: 'Clinician',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 75,
      changeInJobAds: 15.4,
      averageSalary: 91016,
      changeInSalary: 15.47,
    },
    {
      rank: 6,
      roleTitle: 'Nurse Practitioner',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 65,
      changeInJobAds: 4.8,
      averageSalary: 146282,
      changeInSalary: 5.69,
    },
    {
      rank: 7,
      roleTitle: 'Sonographer',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 42,
      changeInJobAds: -2.3,
      averageSalary: 140665,
      changeInSalary: 6.72,
    },
    {
      rank: 8,
      roleTitle: 'Dispensary Technician',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 47,
      changeInJobAds: -11.3,
      averageSalary: 62496,
      changeInSalary: 7.52,
    },
    {
      rank: 9,
      roleTitle: 'Midwife',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 39,
      changeInJobAds: -13.3,
      averageSalary: 96358,
      changeInSalary: 4.08,
    },
    {
      rank: 10,
      roleTitle: 'Receptionist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 183,
      changeInJobAds: -14.1,
      averageSalary: 54764,
      changeInSalary: 1.07,
    },
    {
      rank: 11,
      roleTitle: 'Occupational Therapist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 190,
      changeInJobAds: -17,
      averageSalary: 90440,
      changeInSalary: 13.56,
    },
    {
      rank: 12,
      roleTitle: 'Social Worker',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 56,
      changeInJobAds: -17.6,
      averageSalary: 82752,
      changeInSalary: 6.35,
    },
    {
      rank: 13,
      roleTitle: 'Health Improvement Practitioner',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 48,
      changeInJobAds: -18.6,
      averageSalary: 84402,
      changeInSalary: 0.12,
    },
    {
      rank: 14,
      roleTitle: 'Clinical Manager',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 34,
      changeInJobAds: -22.7,
      averageSalary: 112269,
      changeInSalary: -3.56,
    },
    {
      rank: 15,
      roleTitle: 'Support Worker',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 56,
      changeInJobAds: -23.3,
      averageSalary: 60159,
      changeInSalary: 16.85,
    },
    {
      rank: 16,
      roleTitle: 'Counsellor',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 63,
      changeInJobAds: -30,
      averageSalary: 77619,
      changeInSalary: 2.44,
    },
    {
      rank: 17,
      roleTitle: 'Dental Assistant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 78,
      changeInJobAds: -30.4,
      averageSalary: 54151,
      changeInSalary: 2.37,
    },
    {
      rank: 18,
      roleTitle: 'Physiotherapist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 192,
      changeInJobAds: -30.4,
      averageSalary: 86756,
      changeInSalary: 12.01,
    },
    {
      rank: 19,
      roleTitle: 'Psychologist',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 110,
      changeInJobAds: -34.1,
      averageSalary: 104794,
      changeInSalary: 5.37,
    },
    {
      rank: 20,
      roleTitle: 'Healthcare Assistant',
      industryTitle: 'Healthcare & Medical',
      numberOfJobAds: 71,
      changeInJobAds: -39.3,
      averageSalary: 54756,
      changeInSalary: 1.82,
    },
  ],
  'Hospitality & Tourism': [
    {
      rank: 1,
      roleTitle: 'Waitperson',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 76,
      changeInJobAds: 15.2,
      averageSalary: 53608,
      changeInSalary: 0.63,
    },
    {
      rank: 2,
      roleTitle: 'Food and Beverage Supervisor',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 52,
      changeInJobAds: 0,
      averageSalary: 56575,
      changeInSalary: 0.56,
    },
    {
      rank: 3,
      roleTitle: 'Food and Beverage Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 99,
      changeInJobAds: -2.9,
      averageSalary: 51826,
      changeInSalary: 2.56,
    },
    {
      rank: 4,
      roleTitle: 'Chef de Partie',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 289,
      changeInJobAds: -7.7,
      averageSalary: 58397,
      changeInSalary: 1,
    },
    {
      rank: 5,
      roleTitle: 'Chef',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 674,
      changeInJobAds: -13.1,
      averageSalary: 61022,
      changeInSalary: 1.58,
    },
    {
      rank: 6,
      roleTitle: 'Housekeeper',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 91,
      changeInJobAds: -13.3,
      averageSalary: 50710,
      changeInSalary: 1.12,
    },
    {
      rank: 7,
      roleTitle: 'Duty Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 210,
      changeInJobAds: -13.9,
      averageSalary: 58221,
      changeInSalary: 2.65,
    },
    {
      rank: 8,
      roleTitle: 'Host',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 48,
      changeInJobAds: -14.3,
      averageSalary: 54334,
      changeInSalary: 3.42,
    },
    {
      rank: 9,
      roleTitle: 'Porter',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 36,
      changeInJobAds: -14.3,
      averageSalary: 52112,
      changeInSalary: 1.92,
    },
    {
      rank: 10,
      roleTitle: 'Team Member',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 78,
      changeInJobAds: -14.3,
      averageSalary: 48436,
      changeInSalary: 5.78,
    },
    {
      rank: 11,
      roleTitle: 'Bar Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 34,
      changeInJobAds: -17.1,
      averageSalary: 61352,
      changeInSalary: 0.9,
    },
    {
      rank: 12,
      roleTitle: 'Bartender',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 49,
      changeInJobAds: -19.7,
      averageSalary: 55690,
      changeInSalary: 1.4,
    },
    {
      rank: 13,
      roleTitle: 'Guide',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 39,
      changeInJobAds: -20.4,
      averageSalary: 56864,
      changeInSalary: -3.02,
    },
    {
      rank: 14,
      roleTitle: 'Night Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 34,
      changeInJobAds: -22.7,
      averageSalary: 59870,
      changeInSalary: 1.54,
    },
    {
      rank: 15,
      roleTitle: 'Housekeeping Supervisor',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 48,
      changeInJobAds: -25,
      averageSalary: 57662,
      changeInSalary: 2.52,
    },
    {
      rank: 16,
      roleTitle: 'Receptionist',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 48,
      changeInJobAds: -25,
      averageSalary: 52075,
      changeInSalary: 2.03,
    },
    {
      rank: 17,
      roleTitle: 'Room Attendant',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 63,
      changeInJobAds: -25,
      averageSalary: 50738,
      changeInSalary: 2.39,
    },
    {
      rank: 18,
      roleTitle: 'Kitchen Hand',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 67,
      changeInJobAds: -27.2,
      averageSalary: 51639,
      changeInSalary: 1.17,
    },
    {
      rank: 19,
      roleTitle: 'Restaurant Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 125,
      changeInJobAds: -29,
      averageSalary: 64063,
      changeInSalary: 3.33,
    },
    {
      rank: 20,
      roleTitle: 'Assistant Manager',
      industryTitle: 'Hospitality & Tourism',
      numberOfJobAds: 34,
      changeInJobAds: -30.6,
      averageSalary: 61511,
      changeInSalary: 3.6,
    },
  ],
  'Human Resources & Recruitment': [
    {
      rank: 1,
      roleTitle: 'Human Resources Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 102,
      changeInJobAds: -8.1,
      averageSalary: 96272,
      changeInSalary: 1.97,
    },
    {
      rank: 2,
      roleTitle: 'Human Resources Manager',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 33,
      changeInJobAds: -23.3,
      averageSalary: 123745,
      changeInSalary: 4.69,
    },
    {
      rank: 3,
      roleTitle: 'Human Resources Business Partner',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 45,
      changeInJobAds: -23.7,
      averageSalary: 126369,
      changeInSalary: -0.67,
    },
    {
      rank: 4,
      roleTitle: 'Recruitment Consultant',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 93,
      changeInJobAds: -33.6,
      averageSalary: 83101,
      changeInSalary: 5.43,
    },
    {
      rank: 5,
      roleTitle: 'Health and Safety Adviser',
      industryTitle: 'Human Resources & Recruitment',
      numberOfJobAds: 36,
      changeInJobAds: -40,
      averageSalary: 99845,
      changeInSalary: 4.71,
    },
  ],
  'Information & Communication Technology': [
    {
      rank: 1,
      roleTitle: 'Data Engineer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 87,
      changeInJobAds: 14.5,
      averageSalary: 132045,
      changeInSalary: 5.1,
    },
    {
      rank: 2,
      roleTitle: 'DevOps Engineer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 31,
      changeInJobAds: -16.2,
      averageSalary: 139038,
      changeInSalary: 7.7,
    },
    {
      rank: 3,
      roleTitle: 'Solutions Architect',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 79,
      changeInJobAds: -16.8,
      averageSalary: 168411,
      changeInSalary: 8.52,
    },
    {
      rank: 4,
      roleTitle: 'Network Engineer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 40,
      changeInJobAds: -29.8,
      averageSalary: 118113,
      changeInSalary: 4.67,
    },
    {
      rank: 5,
      roleTitle: 'Software Developer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 219,
      changeInJobAds: -32.6,
      averageSalary: 117964,
      changeInSalary: -3.45,
    },
    {
      rank: 6,
      roleTitle: 'Product Owner',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 38,
      changeInJobAds: -34.5,
      averageSalary: 131263,
      changeInSalary: 0.19,
    },
    {
      rank: 7,
      roleTitle: 'Support Specialist',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 39,
      changeInJobAds: -43.5,
      averageSalary: 68925,
      changeInSalary: -2.82,
    },
    {
      rank: 8,
      roleTitle: 'Support Analyst',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 30,
      changeInJobAds: -45.5,
      averageSalary: 74102,
      changeInSalary: -3.01,
    },
    {
      rank: 9,
      roleTitle: 'Product Manager',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 34,
      changeInJobAds: -46,
      averageSalary: 140544,
      changeInSalary: -3.72,
    },
    {
      rank: 10,
      roleTitle: 'Systems Engineer',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 101,
      changeInJobAds: -46.8,
      averageSalary: 100372,
      changeInSalary: -2.6,
    },
    {
      rank: 11,
      roleTitle: 'Technical Lead',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 30,
      changeInJobAds: -49.2,
      averageSalary: 142517,
      changeInSalary: -11.05,
    },
    {
      rank: 12,
      roleTitle: 'Project Manager',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 84,
      changeInJobAds: -50.9,
      averageSalary: 134057,
      changeInSalary: 3.03,
    },
    {
      rank: 13,
      roleTitle: 'Data Analyst',
      industryTitle: 'Information & Communication Technology',
      numberOfJobAds: 34,
      changeInJobAds: -51.4,
      averageSalary: 100911,
      changeInSalary: -0.15,
    },
  ],
  'Insurance & Superannuation': [
    {
      rank: 1,
      roleTitle: 'Underwriter',
      industryTitle: 'Insurance & Superannuation',
      numberOfJobAds: 39,
      changeInJobAds: -17,
      averageSalary: 100811,
      changeInSalary: 3.98,
    },
  ],
  Legal: [
    {
      rank: 1,
      roleTitle: 'Senior Associate',
      industryTitle: 'Legal',
      numberOfJobAds: 50,
      changeInJobAds: 25,
      averageSalary: 158179,
      changeInSalary: 0.13,
    },
    {
      rank: 2,
      roleTitle: 'Secretary',
      industryTitle: 'Legal',
      numberOfJobAds: 69,
      changeInJobAds: 0,
      averageSalary: 70917,
      changeInSalary: 4.44,
    },
    {
      rank: 3,
      roleTitle: 'Legal Executive',
      industryTitle: 'Legal',
      numberOfJobAds: 63,
      changeInJobAds: -8.7,
      averageSalary: 85666,
      changeInSalary: 10.63,
    },
  ],
  'Manufacturing, Transport & Logistics': [
    {
      rank: 1,
      roleTitle: 'Freight Handler',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 35,
      changeInJobAds: 2.9,
      averageSalary: 50568,
      changeInSalary: 4.86,
    },
    {
      rank: 2,
      roleTitle: 'Warehouse Supervisor',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 36,
      changeInJobAds: -10,
      averageSalary: 66735,
      changeInSalary: -2.07,
    },
    {
      rank: 3,
      roleTitle: 'Procurement Specialist',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 39,
      changeInJobAds: -13.3,
      averageSalary: 114173,
      changeInSalary: 6.58,
    },
    {
      rank: 4,
      roleTitle: 'Logistics Coordinator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 30,
      changeInJobAds: -16.7,
      averageSalary: 65751,
      changeInSalary: 3.15,
    },
    {
      rank: 5,
      roleTitle: 'Team Member',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 58,
      changeInJobAds: -19.4,
      averageSalary: 56630,
      changeInSalary: 1.81,
    },
    {
      rank: 6,
      roleTitle: 'Truck Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 244,
      changeInJobAds: -24.9,
      averageSalary: 60592,
      changeInSalary: 2.15,
    },
    {
      rank: 7,
      roleTitle: 'Delivery Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 110,
      changeInJobAds: -25.2,
      averageSalary: 56005,
      changeInSalary: 2.6,
    },
    {
      rank: 8,
      roleTitle: 'Process Worker',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 49,
      changeInJobAds: -27.9,
      averageSalary: 53486,
      changeInSalary: 4.46,
    },
    {
      rank: 9,
      roleTitle: 'Planner',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 64,
      changeInJobAds: -28.1,
      averageSalary: 87425,
      changeInSalary: 8.16,
    },
    {
      rank: 10,
      roleTitle: 'Machine Operator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 98,
      changeInJobAds: -29,
      averageSalary: 56996,
      changeInSalary: 3.57,
    },
    {
      rank: 11,
      roleTitle: 'Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 64,
      changeInJobAds: -30.4,
      averageSalary: 58103,
      changeInSalary: 0.8,
    },
    {
      rank: 12,
      roleTitle: 'Production Operator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 52,
      changeInJobAds: -32.5,
      averageSalary: 60164,
      changeInSalary: 10.19,
    },
    {
      rank: 13,
      roleTitle: 'Forklift Driver',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 91,
      changeInJobAds: -34.5,
      averageSalary: 54543,
      changeInSalary: 2.05,
    },
    {
      rank: 14,
      roleTitle: 'Warehouse Assistant',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 68,
      changeInJobAds: -35.2,
      averageSalary: 51877,
      changeInSalary: -0.63,
    },
    {
      rank: 15,
      roleTitle: 'Picker and Packer',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 37,
      changeInJobAds: -37.3,
      averageSalary: 51256,
      changeInSalary: 4.24,
    },
    {
      rank: 16,
      roleTitle: 'Team Leader',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 50,
      changeInJobAds: -41.2,
      averageSalary: 71490,
      changeInSalary: -0.46,
    },
    {
      rank: 17,
      roleTitle: 'Storeperson',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 255,
      changeInJobAds: -47.7,
      averageSalary: 55196,
      changeInSalary: 2.86,
    },
    {
      rank: 18,
      roleTitle: 'Warehouse Operator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 39,
      changeInJobAds: -55.7,
      averageSalary: 57066,
      changeInSalary: 0.68,
    },
    {
      rank: 19,
      roleTitle: 'Operator',
      industryTitle: 'Manufacturing, Transport & Logistics',
      numberOfJobAds: 34,
      changeInJobAds: -62.2,
      averageSalary: 59245,
      changeInSalary: 6.15,
    },
  ],
  'Marketing & Communications': [
    {
      rank: 1,
      roleTitle: 'Brand Manager',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 38,
      changeInJobAds: 15.2,
      averageSalary: 106858,
      changeInSalary: 5.84,
    },
    {
      rank: 2,
      roleTitle: 'Marketing Specialist',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 71,
      changeInJobAds: -15.5,
      averageSalary: 82524,
      changeInSalary: 1.5,
    },
    {
      rank: 3,
      roleTitle: 'Marketing Coordinator',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 76,
      changeInJobAds: -18.3,
      averageSalary: 69987,
      changeInSalary: 5.46,
    },
    {
      rank: 4,
      roleTitle: 'Communications Adviser',
      industryTitle: 'Marketing & Communications',
      numberOfJobAds: 54,
      changeInJobAds: -35.7,
      averageSalary: 96039,
      changeInSalary: -8.07,
    },
  ],
  'Real Estate & Property': [
    {
      rank: 1,
      roleTitle: 'Salesperson',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 60,
      changeInJobAds: 30.4,
      averageSalary: 95407,
      changeInSalary: 2.27,
    },
    {
      rank: 2,
      roleTitle: 'Property Manager',
      industryTitle: 'Real Estate & Property',
      numberOfJobAds: 145,
      changeInJobAds: -10.5,
      averageSalary: 71990,
      changeInSalary: -5.45,
    },
  ],
  'Retail & Consumer Products': [
    {
      rank: 1,
      roleTitle: 'Sales Associate',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 216,
      changeInJobAds: 31.7,
      averageSalary: 50384,
      changeInSalary: -2.02,
    },
    {
      rank: 2,
      roleTitle: 'Sales Consultant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 116,
      changeInJobAds: 8.4,
      averageSalary: 53427,
      changeInSalary: 2.71,
    },
    {
      rank: 3,
      roleTitle: 'Branch Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 44,
      changeInJobAds: -13.7,
      averageSalary: 85559,
      changeInSalary: 6.41,
    },
    {
      rank: 4,
      roleTitle: 'Store Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 527,
      changeInJobAds: -24.4,
      averageSalary: 61718,
      changeInSalary: 4.12,
    },
    {
      rank: 5,
      roleTitle: 'Stylist',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 36,
      changeInJobAds: -25,
      averageSalary: 49795,
      changeInSalary: 1.42,
    },
    {
      rank: 6,
      roleTitle: 'Merchandiser',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 155,
      changeInJobAds: -32,
      averageSalary: 49298,
      changeInSalary: 4.67,
    },
    {
      rank: 7,
      roleTitle: 'Sales Assistant',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 541,
      changeInJobAds: -33.6,
      averageSalary: 49369,
      changeInSalary: 2.3,
    },
    {
      rank: 8,
      roleTitle: 'Duty Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 45,
      changeInJobAds: -34.8,
      averageSalary: 56609,
      changeInSalary: 4.78,
    },
    {
      rank: 9,
      roleTitle: 'Assistant Manager',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 62,
      changeInJobAds: -40.4,
      averageSalary: 56950,
      changeInSalary: 4.51,
    },
    {
      rank: 10,
      roleTitle: 'Team Member',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 44,
      changeInJobAds: -43.6,
      averageSalary: 49228,
      changeInSalary: 1.65,
    },
    {
      rank: 11,
      roleTitle: 'Customer Service Representative',
      industryTitle: 'Retail & Consumer Products',
      numberOfJobAds: 67,
      changeInJobAds: -53.1,
      averageSalary: 51291,
      changeInSalary: null,
    },
  ],
  Sales: [
    {
      rank: 1,
      roleTitle: 'Territory Manager',
      industryTitle: 'Sales',
      numberOfJobAds: 105,
      changeInJobAds: -16,
      averageSalary: 83939,
      changeInSalary: 5.64,
    },
    {
      rank: 2,
      roleTitle: 'Account Manager',
      industryTitle: 'Sales',
      numberOfJobAds: 332,
      changeInJobAds: -25.6,
      averageSalary: 94506,
      changeInSalary: 3.46,
    },
    {
      rank: 3,
      roleTitle: 'Sales Specialist',
      industryTitle: 'Sales',
      numberOfJobAds: 46,
      changeInJobAds: -27,
      averageSalary: 81450,
      changeInSalary: 3.26,
    },
    {
      rank: 4,
      roleTitle: 'Sales Representative',
      industryTitle: 'Sales',
      numberOfJobAds: 261,
      changeInJobAds: -30.8,
      averageSalary: 75299,
      changeInSalary: 1.35,
    },
  ],
  'Science & Technology': [
    {
      rank: 1,
      roleTitle: 'Technologist',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 41,
      changeInJobAds: 13.9,
      averageSalary: 92363,
      changeInSalary: 16.29,
    },
    {
      rank: 2,
      roleTitle: 'Technician',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 30,
      changeInJobAds: -6.3,
      averageSalary: 59176,
      changeInSalary: -7.34,
    },
    {
      rank: 3,
      roleTitle: 'Laboratory Technician',
      industryTitle: 'Science & Technology',
      numberOfJobAds: 56,
      changeInJobAds: -33.3,
      averageSalary: 60966,
      changeInSalary: 1.95,
    },
  ],
  'Sport & Recreation': [
    {
      rank: 1,
      roleTitle: 'Coach',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 49,
      changeInJobAds: 16.7,
      averageSalary: 64161,
      changeInSalary: 13.2,
    },
    {
      rank: 2,
      roleTitle: 'Lifeguard',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 58,
      changeInJobAds: 0,
      averageSalary: 54163,
      changeInSalary: 4.16,
    },
    {
      rank: 3,
      roleTitle: 'Instructor',
      industryTitle: 'Sport & Recreation',
      numberOfJobAds: 67,
      changeInJobAds: -25.6,
      averageSalary: 54590,
      changeInSalary: 5.43,
    },
  ],
  'Trades & Services': [
    {
      rank: 1,
      roleTitle: 'Automotive Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 114,
      changeInJobAds: 2.7,
      averageSalary: 73088,
      changeInSalary: 3.82,
    },
    {
      rank: 2,
      roleTitle: 'Therapist',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 101,
      changeInJobAds: -1.9,
      averageSalary: 58211,
      changeInSalary: 2.42,
    },
    {
      rank: 3,
      roleTitle: 'Electrician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 385,
      changeInJobAds: -9,
      averageSalary: 84523,
      changeInSalary: 2.2,
    },
    {
      rank: 4,
      roleTitle: 'Foreperson',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 46,
      changeInJobAds: -9.8,
      averageSalary: 81174,
      changeInSalary: -2.71,
    },
    {
      rank: 5,
      roleTitle: 'Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 72,
      changeInJobAds: -12.2,
      averageSalary: 65866,
      changeInSalary: -1.32,
    },
    {
      rank: 6,
      roleTitle: 'Trades Assistant',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 32,
      changeInJobAds: -13.5,
      averageSalary: 62565,
      changeInSalary: 2.5,
    },
    {
      rank: 7,
      roleTitle: 'Gardener',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 87,
      changeInJobAds: -13.9,
      averageSalary: 56918,
      changeInSalary: 8.18,
    },
    {
      rank: 8,
      roleTitle: 'Fabricator',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 89,
      changeInJobAds: -16,
      averageSalary: 70884,
      changeInSalary: 5.04,
    },
    {
      rank: 9,
      roleTitle: 'Carpenter',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 103,
      changeInJobAds: -16.3,
      averageSalary: 71910,
      changeInSalary: 7.58,
    },
    {
      rank: 10,
      roleTitle: 'Service Technician',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 199,
      changeInJobAds: -16.4,
      averageSalary: 76466,
      changeInSalary: 2.69,
    },
    {
      rank: 11,
      roleTitle: 'Groomer',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 35,
      changeInJobAds: -16.7,
      averageSalary: 50729,
      changeInSalary: 2.86,
    },
    {
      rank: 12,
      roleTitle: 'Cabinet Maker',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 35,
      changeInJobAds: -20.5,
      averageSalary: 67645,
      changeInSalary: -2.07,
    },
    {
      rank: 13,
      roleTitle: 'Butcher',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 36,
      changeInJobAds: -21.7,
      averageSalary: 57970,
      changeInSalary: -0.89,
    },
    {
      rank: 14,
      roleTitle: 'Hairstylist',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 30,
      changeInJobAds: -23.1,
      averageSalary: 64942,
      changeInSalary: 7.21,
    },
    {
      rank: 15,
      roleTitle: 'Mechanic',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 207,
      changeInJobAds: -23.6,
      averageSalary: 77362,
      changeInSalary: 3.88,
    },
    {
      rank: 16,
      roleTitle: 'Installer',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 100,
      changeInJobAds: -27.5,
      averageSalary: 66042,
      changeInSalary: 3.88,
    },
    {
      rank: 17,
      roleTitle: 'Baker',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 68,
      changeInJobAds: -27.7,
      averageSalary: 57074,
      changeInSalary: 0.58,
    },
    {
      rank: 18,
      roleTitle: 'Plumber',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 46,
      changeInJobAds: -29.2,
      averageSalary: 79988,
      changeInSalary: 1.01,
    },
    {
      rank: 19,
      roleTitle: 'Caretaker',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 36,
      changeInJobAds: -29.4,
      averageSalary: 55599,
      changeInSalary: 1.36,
    },
    {
      rank: 20,
      roleTitle: 'Fitter',
      industryTitle: 'Trades & Services',
      numberOfJobAds: 101,
      changeInJobAds: -30.3,
      averageSalary: 81492,
      changeInSalary: 5.46,
    },
  ],
};
