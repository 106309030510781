import type { ActionGroupFragment } from '@seek/cmsu-cms-connect';
import { Box, Inline, Spread } from 'braid-design-system';
import React from 'react';
import { ActionRenderer } from '../../renderer/Action/ActionRenderer';

type Props = Pick<
  ActionGroupFragment,
  'actions' | 'inline' | 'display' | 'boxAlignment'
> & {
  size?: string | null;
};

const ActionGroup = ({
  actions,
  inline,
  size,
  display,
  boxAlignment,
}: Props) => {
  const filtered = actions.filter(
    (action) =>
      !(
        action.link?.to?.__typename === 'ExternalUrl' &&
        action.link.to.externalUrl === '-'
      ),
  );

  if (filtered.length === 0) {
    return null;
  }

  if (size === 'full') {
    return (
      <Spread space="small" align="left" direction="vertical">
        {filtered.map(
          (action) =>
            action.__typename &&
            (display != null ? (
              <Box
                display={display}
                key={action.id}
                height="full"
                alignItems={boxAlignment ? boxAlignment : 'center'}
              >
                <ActionRenderer action={action as Required<typeof action>} />
              </Box>
            ) : (
              <ActionRenderer
                key={action.id}
                action={action as Required<typeof action>}
              />
            )),
        )}
      </Spread>
    );
  }

  return (
    <Inline
      space="gutter"
      alignY="center"
      collapseBelow="tablet"
      align={inline?.align || 'left'}
    >
      {filtered.map(
        (action) =>
          action.__typename && (
            <ActionRenderer
              key={action.id}
              action={action as Required<typeof action>}
            />
          ),
      )}
    </Inline>
  );
};
export { ActionGroup };
