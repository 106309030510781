import type { Article } from '@seek/cmsu-cms-connect';
import React from 'react';
import {
  ITEM_DISPLAY_TYPE,
  resolveArticlePathFrom,
} from '../../helpers/articleHelper';
import { useMelwaysLink } from '@seek/melways-react';
import type { Brand, Language } from '@seek/melways-sites';
import { ArticleListItem } from '../ArticleListItem/ArticleListItem';
import { ArticleCard } from '../ArticleCard/ArticleCard';
import { Box } from 'braid-design-system';
import type { ArticleItemFieldVisibility } from './articleItemHelper';

export const defaultArticleItemFieldVisibility =
  (): ArticleItemFieldVisibility => ({
    showCategory: false,
    showExcerpt: false,
    showPublishDate: false,
    showTimeToRead: false,
  });

type Props = {
  displayType: ITEM_DISPLAY_TYPE;
  article: Article;
  language: Language;
  brand: Brand;
  section?: string;
  fieldVisibility?: ArticleItemFieldVisibility;
  isLastItem?: boolean;
  index?: number;
};

export const ArticleItem = ({
  displayType,
  article,
  language,
  brand,
  section,
  fieldVisibility = {
    showCategory: false,
    showExcerpt: false,
    showPublishDate: false,
    showTimeToRead: false,
  },
  isLastItem = false,
  index,
}: Props) => {
  const urlResolver = useMelwaysLink();
  const path = resolveArticlePathFrom(article, section);
  const articleLink = urlResolver({
    language,
    path,
    brand,
  });

  if (displayType === ITEM_DISPLAY_TYPE.LIST_ITEM) {
    return (
      <Box data={{ testid: 'ArticleListItemTestId' }}>
        <ArticleListItem
          article={article}
          articleLink={articleLink}
          fieldVisibility={fieldVisibility}
          isLastItem={isLastItem}
        />
      </Box>
    );
  } else if (displayType === ITEM_DISPLAY_TYPE.CARD) {
    return (
      <Box height="full" data={{ testid: 'ArticleCardTestId' }}>
        <ArticleCard
          index={index}
          article={article}
          articleLink={articleLink}
          fieldVisibility={fieldVisibility}
        />
      </Box>
    );
  }
};
