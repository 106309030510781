// Converts string to lowercase and replaces commas, & and whitespace with hyphens
const formatUrlCase = (text: string) =>
  text.toLowerCase().replace(/[,&\s]+/g, '-');

// create valid jobs link for the country
export const createJobsLink = (
  country: string,
  jobTitle: string,
  industryTitle?: string,
) => {
  const domain = country === 'au' ? 'seek.com.au' : 'seek.co.nz';
  const industryPath = industryTitle
    ? `-in-${formatUrlCase(industryTitle)}`
    : '';
  return `https://www.${domain}/${formatUrlCase(jobTitle)}-jobs${industryPath}`;
};
