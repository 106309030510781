import type { TopJobGrowthData } from '../types';

// THIS IS GENERATED DATA FROM SCRIPT.JS
export const NZ_TOP_GROWTH_JOBS_COUNTRY_DATA: TopJobGrowthData[] = [
  {
    rank: 1,
    roleTitle: 'General Practitioner',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 164,
    changeInJobAds: 46.4,
    averageSalary: 205329,
    changeInSalary: -1.42,
  },
  {
    rank: 2,
    roleTitle: 'Technologist',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 63,
    changeInJobAds: 43.2,
    averageSalary: 106429,
    changeInSalary: 4.62,
  },
  {
    rank: 3,
    roleTitle: 'Auditor',
    industryTitle: 'Accounting',
    numberOfJobAds: 54,
    changeInJobAds: 42.1,
    averageSalary: 84192,
    changeInSalary: 8.19,
  },
  {
    rank: 4,
    roleTitle: 'Analyst',
    industryTitle: 'Banking & Financial Services',
    numberOfJobAds: 44,
    changeInJobAds: 41.9,
    averageSalary: 106020,
    changeInSalary: 10.58,
  },
  {
    rank: 5,
    roleTitle: 'Practice Manager',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 48,
    changeInJobAds: 37.1,
    averageSalary: 87487,
    changeInSalary: 12.34,
  },
  {
    rank: 6,
    roleTitle: 'Centre Manager',
    industryTitle: 'Education & Training',
    numberOfJobAds: 63,
    changeInJobAds: 37,
    averageSalary: 98272,
    changeInSalary: 7.94,
  },
  {
    rank: 7,
    roleTitle: 'Sales Associate',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 216,
    changeInJobAds: 31.7,
    averageSalary: 50384,
    changeInSalary: -2.02,
  },
  {
    rank: 8,
    roleTitle: 'Veterinarian',
    industryTitle: 'Farming, Animals & Conservation',
    numberOfJobAds: 76,
    changeInJobAds: 31,
    averageSalary: 116515,
    changeInSalary: 8.94,
  },
  {
    rank: 9,
    roleTitle: 'Salesperson',
    industryTitle: 'Real Estate & Property',
    numberOfJobAds: 60,
    changeInJobAds: 30.4,
    averageSalary: 95407,
    changeInSalary: 2.27,
  },
  {
    rank: 10,
    roleTitle: 'Senior Associate',
    industryTitle: 'Legal',
    numberOfJobAds: 50,
    changeInJobAds: 25,
    averageSalary: 158179,
    changeInSalary: 0.13,
  },
  {
    rank: 11,
    roleTitle: 'Pharmacist',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 174,
    changeInJobAds: 17.6,
    averageSalary: 100022,
    changeInSalary: 6.14,
  },
  {
    rank: 12,
    roleTitle: 'Coach',
    industryTitle: 'Sport & Recreation',
    numberOfJobAds: 49,
    changeInJobAds: 16.7,
    averageSalary: 64161,
    changeInSalary: 13.2,
  },
  {
    rank: 13,
    roleTitle: 'Clinician',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 75,
    changeInJobAds: 15.4,
    averageSalary: 91016,
    changeInSalary: 15.47,
  },
  {
    rank: 14,
    roleTitle: 'Waitperson',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 76,
    changeInJobAds: 15.2,
    averageSalary: 53608,
    changeInSalary: 0.63,
  },
  {
    rank: 15,
    roleTitle: 'Brand Manager',
    industryTitle: 'Marketing & Communications',
    numberOfJobAds: 38,
    changeInJobAds: 15.2,
    averageSalary: 106858,
    changeInSalary: 5.84,
  },
  {
    rank: 16,
    roleTitle: 'Data Engineer',
    industryTitle: 'Information & Communication Technology',
    numberOfJobAds: 87,
    changeInJobAds: 14.5,
    averageSalary: 132045,
    changeInSalary: 5.1,
  },
  {
    rank: 17,
    roleTitle: 'Technologist',
    industryTitle: 'Science & Technology',
    numberOfJobAds: 41,
    changeInJobAds: 13.9,
    averageSalary: 92363,
    changeInSalary: 16.29,
  },
  {
    rank: 18,
    roleTitle: 'Sales Consultant',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 116,
    changeInJobAds: 8.4,
    averageSalary: 53427,
    changeInSalary: 2.71,
  },
  {
    rank: 19,
    roleTitle: 'Fitter',
    industryTitle: 'Engineering',
    numberOfJobAds: 40,
    changeInJobAds: 5.3,
    averageSalary: 84154,
    changeInSalary: 10.85,
  },
  {
    rank: 20,
    roleTitle: 'Nurse Practitioner',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 65,
    changeInJobAds: 4.8,
    averageSalary: 146282,
    changeInSalary: 5.69,
  },
  {
    rank: 21,
    roleTitle: 'Freight Handler',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 35,
    changeInJobAds: 2.9,
    averageSalary: 50568,
    changeInSalary: 4.86,
  },
  {
    rank: 22,
    roleTitle: 'Automotive Technician',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 114,
    changeInJobAds: 2.7,
    averageSalary: 73088,
    changeInSalary: 3.82,
  },
  {
    rank: 23,
    roleTitle: 'Surveyor',
    industryTitle: 'Construction',
    numberOfJobAds: 119,
    changeInJobAds: 0,
    averageSalary: 114258,
    changeInSalary: 6.08,
  },
  {
    rank: 26,
    roleTitle: 'Secretary',
    industryTitle: 'Legal',
    numberOfJobAds: 69,
    changeInJobAds: 0,
    averageSalary: 70917,
    changeInSalary: 4.44,
  },
  {
    rank: 27,
    roleTitle: 'Lifeguard',
    industryTitle: 'Sport & Recreation',
    numberOfJobAds: 58,
    changeInJobAds: 0,
    averageSalary: 54163,
    changeInSalary: 4.16,
  },
  {
    rank: 25,
    roleTitle: 'Food and Beverage Supervisor',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 52,
    changeInJobAds: 0,
    averageSalary: 56575,
    changeInSalary: 0.56,
  },
  {
    rank: 24,
    roleTitle: 'Education Adviser',
    industryTitle: 'Education & Training',
    numberOfJobAds: 36,
    changeInJobAds: 0,
    averageSalary: 93641,
    changeInSalary: 3.78,
  },
  {
    rank: 28,
    roleTitle: 'Therapist',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 101,
    changeInJobAds: -1.9,
    averageSalary: 58211,
    changeInSalary: 2.42,
  },
  {
    rank: 29,
    roleTitle: 'Sonographer',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 42,
    changeInJobAds: -2.3,
    averageSalary: 140665,
    changeInSalary: 6.72,
  },
  {
    rank: 30,
    roleTitle: 'Food and Beverage Attendant',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 99,
    changeInJobAds: -2.9,
    averageSalary: 51826,
    changeInSalary: 2.56,
  },
  {
    rank: 31,
    roleTitle: 'Support Worker',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 876,
    changeInJobAds: -4.8,
    averageSalary: 51475,
    changeInSalary: 0.34,
  },
  {
    rank: 32,
    roleTitle: 'Caregiver',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 88,
    changeInJobAds: -5.4,
    averageSalary: 51757,
    changeInSalary: null,
  },
  {
    rank: 33,
    roleTitle: 'Technician',
    industryTitle: 'Science & Technology',
    numberOfJobAds: 30,
    changeInJobAds: -6.3,
    averageSalary: 59176,
    changeInSalary: -7.34,
  },
  {
    rank: 34,
    roleTitle: 'Chef de Partie',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 289,
    changeInJobAds: -7.7,
    averageSalary: 58397,
    changeInSalary: 1,
  },
  {
    rank: 35,
    roleTitle: 'Human Resources Adviser',
    industryTitle: 'Human Resources & Recruitment',
    numberOfJobAds: 102,
    changeInJobAds: -8.1,
    averageSalary: 96272,
    changeInSalary: 1.97,
  },
  {
    rank: 36,
    roleTitle: 'Legal Executive',
    industryTitle: 'Legal',
    numberOfJobAds: 63,
    changeInJobAds: -8.7,
    averageSalary: 85666,
    changeInSalary: 10.63,
  },
  {
    rank: 37,
    roleTitle: 'Electrician',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 385,
    changeInJobAds: -9,
    averageSalary: 84523,
    changeInSalary: 2.2,
  },
  {
    rank: 38,
    roleTitle: 'Foreperson',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 46,
    changeInJobAds: -9.8,
    averageSalary: 81174,
    changeInSalary: -2.71,
  },
  {
    rank: 39,
    roleTitle: 'Warehouse Supervisor',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 36,
    changeInJobAds: -10,
    averageSalary: 66735,
    changeInSalary: -2.07,
  },
  {
    rank: 40,
    roleTitle: 'Property Manager',
    industryTitle: 'Real Estate & Property',
    numberOfJobAds: 145,
    changeInJobAds: -10.5,
    averageSalary: 71990,
    changeInSalary: -5.45,
  },
  {
    rank: 41,
    roleTitle: 'Dispensary Technician',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 47,
    changeInJobAds: -11.3,
    averageSalary: 62496,
    changeInSalary: 7.52,
  },
  {
    rank: 42,
    roleTitle: 'Finance Manager',
    industryTitle: 'Accounting',
    numberOfJobAds: 130,
    changeInJobAds: -12.2,
    averageSalary: 132998,
    changeInSalary: 1.84,
  },
  {
    rank: 43,
    roleTitle: 'Technician',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 72,
    changeInJobAds: -12.2,
    averageSalary: 65866,
    changeInSalary: -1.32,
  },
  {
    rank: 44,
    roleTitle: 'Support Administrator',
    industryTitle: 'Administration & Office Support',
    numberOfJobAds: 61,
    changeInJobAds: -12.9,
    averageSalary: 64171,
    changeInSalary: 6.08,
  },
  {
    rank: 45,
    roleTitle: 'Chef',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 674,
    changeInJobAds: -13.1,
    averageSalary: 61022,
    changeInSalary: 1.52,
  },
  {
    rank: 46,
    roleTitle: 'Nurse',
    industryTitle: 'Farming, Animals & Conservation',
    numberOfJobAds: 91,
    changeInJobAds: -13.3,
    averageSalary: 58349,
    changeInSalary: 3.56,
  },
  {
    rank: 48,
    roleTitle: 'Housekeeper',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 91,
    changeInJobAds: -13.3,
    averageSalary: 50710,
    changeInSalary: 1.12,
  },
  {
    rank: 47,
    roleTitle: 'Midwife',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 39,
    changeInJobAds: -13.3,
    averageSalary: 96358,
    changeInSalary: 4.08,
  },
  {
    rank: 49,
    roleTitle: 'Procurement Specialist',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 39,
    changeInJobAds: -13.3,
    averageSalary: 114173,
    changeInSalary: 6.58,
  },
  {
    rank: 50,
    roleTitle: 'Trades Assistant',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 32,
    changeInJobAds: -13.5,
    averageSalary: 62565,
    changeInSalary: 2.5,
  },
];
