import { ArticleFeature } from '@seek/cmsu-cms-connect';

export type ArticleItemFieldVisibility = {
  showCategory?: boolean;
  showExcerpt?: boolean;
  showPublishDate?: boolean;
  showTimeToRead?: boolean;
};

const mapToArticleFieldVisibility = (
  cardFields: ArticleFeature[],
): ArticleItemFieldVisibility => ({
  showCategory: cardFields.includes(ArticleFeature.Category),
  showExcerpt: cardFields.includes(ArticleFeature.Excerpt),
  showPublishDate: cardFields.includes(ArticleFeature.PublishDate),
  showTimeToRead: cardFields.includes(ArticleFeature.ReadTime),
});

export const articleItemHelper = {
  mapToArticleFieldVisibility,
};
