import type {
  BoxFragment,
  ImageBlockFragment,
  ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Stack, useResponsiveValue } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { getBoxBackgroundProps, getBoxShadowProps } from '../../shared/helper';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { TextLink } from '../ActionGroup/TextLink';
import { DialogButton } from '../ActionGroup/DialogButton';
import { getElementAction } from '../../utils/getElementAction';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { toUrl } from '../../utils/toUrl';

type Props = Pick<
  ImageBlockFragment,
  | 'heading'
  | 'image'
  | 'paragraph'
  | 'cButtonLink'
  | 'cTextLink'
  | 'dialogButton'
  | 'reverseContent'
> &
  Pick<
    ImageBlockGroupFragment,
    'imageAlignment' | 'blockBackground' | 'blockBorder'
  > & {
    textAlign?: BoxFragment['textAlign'];
    imageAlignment?: BoxFragment['justifyContent'];
  };

const getAlignment = (textAlign?: BoxFragment['textAlign']) => {
  switch (textAlign) {
    case 'left':
      return 'flexStart';
    case 'right':
      return 'flexEnd';
    default:
      return 'center';
  }
};

export const RegularImageBlock = ({
  image,
  imageAlignment,
  textAlign,
  heading,
  paragraph,
  blockBackground,
  blockBorder,
  cButtonLink,
  cTextLink,
  dialogButton,
  reverseContent,
}: Props) => {
  const { language, utmParameters } = useCMSUContext();
  const responsiveValue = useResponsiveValue();
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const isMobile = responsiveValue({
    mobile: true,
    desktop: false,
    tablet: true,
    wide: false,
  });

  const ImageComponent = React.useMemo(
    () =>
      image && (
        <Box
          display="flex"
          justifyContent={imageAlignment ?? 'center'}
          width="full"
        >
          <Box borderRadius="large" overflow="hidden">
            <Asset {...image} assetType={AssetType.DEFAULT_IMAGE} />
          </Box>
        </Box>
      ),
    [image, imageAlignment],
  );

  const ContentComponent = React.useMemo(
    () => (
      <Stack space="medium">
        {heading && <Heading {...heading} />}
        {paragraph && (
          <Paragraph
            content={paragraph.Paragraph_text?.raw}
            align={paragraph.align}
            tone={paragraph.tone}
            size={paragraph.size}
          />
        )}
        {cButtonLink && (
          <Box display="inlineBlock">
            <ButtonLink
              link={toUrl(cButtonLink.link?.to, language, utmParameters)}
              text={cButtonLink.text}
              tone={cButtonLink.tone || 'neutral'}
              variant={cButtonLink.variant || 'solid'}
              size={cButtonLink.size || 'standard'}
              icon={cButtonLink.icon}
              iconPosition={cButtonLink.iconPosition}
              openLinkInNewTab={cButtonLink.link?.openLinkInNewTab || false}
              uniqueTrackingId="button-link"
              trackingAttributes={{
                elementText: cButtonLink.text,
                elementAction: getElementAction(cButtonLink.link?.to),
                elementLink: toUrl(
                  cButtonLink.link?.to,
                  language,
                  utmParameters,
                ),
              }}
            />
          </Box>
        )}
        {dialogButton && (
          <Box display="inlineBlock">
            <DialogButton {...dialogButton} />
          </Box>
        )}
        {cTextLink && (
          <Box display="inlineBlock">
            <TextLink {...cTextLink} />
          </Box>
        )}
      </Stack>
    ),
    [
      heading,
      paragraph,
      cButtonLink,
      cTextLink,
      dialogButton,
      language,
      utmParameters,
    ],
  );

  const getOrderedContent = () => {
    switch (reverseContent) {
      case 'desktop':
        return isDeskTop
          ? [ContentComponent, ImageComponent]
          : [ImageComponent, ContentComponent];
      case 'both':
        return [ContentComponent, ImageComponent];
      case 'mobile':
        return isMobile
          ? [ContentComponent, ImageComponent]
          : [ImageComponent, ContentComponent];
      default:
        return [ImageComponent, ContentComponent];
    }
  };

  return (
    <Box
      padding={blockBackground ? 'gutter' : 'none'}
      borderRadius={blockBackground ? 'large' : 'none'}
      height="full"
      justifyContent={getAlignment(textAlign)}
      {...getBoxBackgroundProps(blockBackground)}
      {...getBoxShadowProps(blockBorder)}
    >
      <Stack space="medium" align={textAlign ?? 'center'}>
        {getOrderedContent()}
      </Stack>
    </Box>
  );
};
