import { Box, Loader } from 'braid-design-system';
import React, { useState } from 'react';

import * as styles from './Video.css';
import type { BorderRadius } from '@seek/cmsu-cms-connect';

export enum VideoSrcType {
  Brightcove = 'BrightcoveVideo',
  YouTube = 'YouTubeVideo',
}

interface Props {
  url: string;
  type?: VideoSrcType;
  size?: 'small' | 'medium' | 'large';
  borderRadius?: Exclude<BorderRadius, 'full'>;
  alignX?: 'left' | 'center' | 'right';
}

export const getVideoUrl = (mediaUrl: string) =>
  mediaUrl.includes('https://www.youtube') ? getYoutubeUrl(mediaUrl) : mediaUrl;

export const getYoutubeUrl = (mediaUrl: string) =>
  mediaUrl.includes('https://www.youtube.com/embed/')
    ? mediaUrl
    : constructUrl(mediaUrl);

export const constructUrl = (mediaUrl: string) => {
  const videoId = getVideoID(mediaUrl);
  return `https://www.youtube.com/embed/${videoId}`;
};

export const getVideoID = (mediaUrl: string) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const matchId = mediaUrl.match(regExp);
  let videoId: string;
  if (matchId && matchId[2].length === 11) {
    videoId = matchId[2];
  } else {
    videoId = '';
  }
  return videoId;
};

export const getVideoType = (url: string) => {
  /**
   TODO: can replace if conditional checks with ENUMS
   so that if we add new type it will reduce the branching
   */
  if (url.includes('brightcove')) {
    return VideoSrcType.Brightcove;
  } else if (url.includes('youtube')) {
    return VideoSrcType.YouTube;
  }
  return undefined;
};

export const Video = ({
  url,
  type,
  size,
  borderRadius,
  alignX = 'center',
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const newVideoUrl = getVideoUrl(url);
  const videoType = getVideoType(newVideoUrl);

  if (!videoType && !type) {
    return <></>;
  }

  return (
    <Box>
      <Box className={[styles.size[size || 'large'], styles.alignX[alignX]]}>
        <Box
          className={styles.videoWrapper}
          borderRadius={borderRadius || 'large'}
          display="flex"
          justifyContent="center"
        >
          {!loaded && <Loader aria-label="video loading" size="large" />}
          <Box
            component={'iframe'}
            className={[
              styles.video,
              styles.borderRadius[borderRadius || 'large'],
              styles.size[size || 'large'],
            ]}
            src={newVideoUrl}
            frameBorder="0"
            onLoad={() => setLoaded(true)}
            allowFullScreen
            borderRadius={borderRadius || 'large'}
          />
        </Box>
      </Box>
    </Box>
  );
};
