import { Box } from 'braid-design-system';
import { encodeSvg } from '../../../helpers/helper';
import StarPinkImg from '../images/star-pink.svg';

import { starPink } from './styles.css';
import type { CustomDecoration } from '@seek/cmsu-cms-connect';

export const StarPinkDecoration = ({
  variant,
}: {
  variant:
    | CustomDecoration.StarPinkBottomLeft
    | CustomDecoration.StarPinkTopRight;
}) => (
  <Box
    data={{ testid: `star-pink-deco__${variant}` }}
    className={starPink[variant]}
    style={{
      backgroundImage: `url(${encodeSvg(StarPinkImg)})`,
    }}
  />
);
