import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.92.1_@swc+core@1.1_cteoacatbmtnm6xljpipiqxsii/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Sy27CMBC88xUrVZXgYBTeTbj01P8w9hK2TezIcUqg4t+rGIc6oUAPzSVxdjwzu7Njka8mq3ICXwMAi7VlPKNUJSBQWTTrAcAOKd3ZBCZR9Nycc16zPUm76/5qYfMoKur14DQYn6mn/0c961HP7lBvuPhIja6UZAYL5DYBpf1nr17SERMQWllOqqk97g7gyEhJrBOI48DR3DkKuIXOtEng6c09GGIXN7Cf3AwZyza1MNW0Gjmz2kg0zHBJVdlFiFFAuXKUksoi44cEthk6r+9VaWl7YE2LqOy9BAouJamUWV10dWajsLzR1ur8GnFx8vK7k+bNJBkUlnSTl86q3M3cBcjIYl6G/n6ZiQ114vMQz/PJcNu0UtRQ6ozk1S2vnxq9T2ASJt1ZK+44byg362BSUr2cnKfXHCVxKIVBVMCVhGFOqt3o1Twq6pHj9kKROwA0oDaIZbtgpx/c3OP89LvSC2fq7o5ID/FGlueoA4HFXwT6tx/1G8fTXr9tHx6wuOnjJmDpAZfFUlqht/MNKXo4+MsEAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.92.1_@swc+core@1.1_cteoacatbmtnm6xljpipiqxsii/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = 'cm717s6';
export var BannerTextColumnBoxStyle = 'cm717s4';
export var ShopfrontBannerTextColumnBoxStyle = 'cm717s5';
export var VerticalDivider = 'cm717s8';
export var VerticalDividerText = 'cm717sa';
export var VerticalDividerWrapper = 'cm717s7';
export var VerticalLine = 'cm717s9';
export var bannerContainer = 'cm717s0';
export var headerImageStyle = 'cm717s2';
export var headerImageStyleEnlarged = 'cm717s1';
export var tertiaryImageStyle = 'cm717s3';