import { Box, Inline } from 'braid-design-system';

import type { CTextLink } from '@seek/cmsu-cms-connect';
import { TextLink } from '../../ActionGroup/TextLink';

export const NavLinkGroup = ({ links }: { links: CTextLink[] }) => (
  <Box>
    <Inline space={'gutter'} collapseBelow="desktop" align="right">
      {links.map((linkItem, i) => (
        <Box width={'full'} key={i} position="relative">
          <Inline space="xxsmall" align="center">
            <TextLink {...linkItem} link={linkItem.link as any} />
          </Inline>
        </Box>
      ))}
    </Inline>
  </Box>
);
