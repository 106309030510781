import { Inline, TextLink } from 'braid-design-system';
import type { ComponentProps } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from './Table';

// tableData expects Table Data with ROWS and COLUMNS
// Map<string, JSX.Element> is a map of ROWS with column name and column values
type Props = {
  tableData: Map<string, JSX.Element>[];
  showHeader?: boolean;
};

export const TableBuilder = ({ tableData: data, showHeader }: Props) => {
  if (!data || data.length === 0) return;

  const headers = Array.from(data[0]!.keys());

  return (
    <Table>
      <TableHeadBuilder headers={headers} showHeader={showHeader} />
      <TableBody>
        {data.map((row, indexRow) => (
          <TableRow key={indexRow}>
            {Array.from(row.values()).map((column, indexColumn) => (
              <TableCell key={indexColumn}>{column}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

type TableHeadBuilderProps = {
  headers: string[];
  showHeader?: boolean;
};

const TableHeadBuilder = ({
  headers,
  showHeader = true,
}: TableHeadBuilderProps) => {
  if (!showHeader) return;
  return (
    <TableHead>
      <TableRow>
        {headers.map((column, index) => (
          <TableHeaderCell key={index}>
            <>{column}</>
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const textElementWrapper = (
  data: string | number | JSX.Element,
): JSX.Element => <>{data}</>;

export const percentageWrapper = (
  data: number,
  preElement?: JSX.Element,
): JSX.Element => (
  <Inline space="xsmall" alignY="center">
    {preElement && preElement}
    <>{data}%</>
  </Inline>
);

export const textLinkWrapper = (
  data: string,
  link: string,
  textLinkOpts?: Omit<ComponentProps<typeof TextLink>, 'href'>,
): JSX.Element => (
  <TextLink href={link} hitArea="large" {...textLinkOpts}>
    {data}
  </TextLink>
);
