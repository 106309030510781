import 'braid-design-system/reset';
import {
  ConfigProvider,
  type ConfigStateContext,
} from './shared/hooks/useConfig';
import { AuthProvider } from './shared/hooks/useAuth';
import {
  type ApolloClient,
  type NormalizedCacheObject,
  ApolloProvider,
} from '@apollo/client';
import { Outlet } from 'react-router';
import { Box } from 'braid-design-system';
import { HygraphInspector } from '@hygraph/live-preview-sdk/react';
import { VocabProvider } from '@vocab/react';
import { MelwaysProvider } from '@seek/melways-react';
type Props = {
  client: ApolloClient<NormalizedCacheObject>;
  clientTAL: ApolloClient<NormalizedCacheObject>;
  config: ConfigStateContext;
};

export const Root = ({ config, client, clientTAL }: Props) => (
  <MelwaysProvider.Server url={config.fullURL} site={config.site}>
    <ApolloProvider client={client}>
      <ConfigProvider config={config}>
        <AuthProvider>
          <VocabProvider language={config.language}>
            {config.hygraphContentApi && (
              <HygraphInspector endpoint={config.hygraphContentApi}>
                <OutletWrapper
                  config={config}
                  client={client}
                  clientTAL={clientTAL}
                />
              </HygraphInspector>
            )}
            {!config.hygraphContentApi && (
              <OutletWrapper
                config={config}
                client={client}
                clientTAL={clientTAL}
              />
            )}
          </VocabProvider>
        </AuthProvider>
      </ConfigProvider>
    </ApolloProvider>
  </MelwaysProvider.Server>
);

const OutletWrapper = ({ config, client, clientTAL }: Props) => (
  <Box role="main">
    <Outlet context={{ config, client, clientTAL }} />
  </Box>
);
