import type { CBoxBackground, CTestimonial } from '@seek/cmsu-cms-connect';
import { Box, Stack, Strong, Text } from 'braid-design-system';
import { vars } from 'braid-design-system/css';

import * as styles from './TestimonialCard.css';
import { getBoxBackgroundProps } from '../../../../shared/helper';

export const TestimonialCard = ({
  testimonial,
  cardBackground,
}: {
  testimonial: CTestimonial;
  cardBackground?: CBoxBackground | null;
}) => (
  <Box
    overflow="hidden"
    height="full"
    data={{ testid: 'testimonial-card' }}
    textAlign="left"
  >
    <Box
      height="full"
      padding="gutter"
      boxShadow={cardBackground ? undefined : 'borderNeutralLight'}
      borderRadius="large"
      {...getBoxBackgroundProps(cardBackground, true)}
    >
      {/* Note the semantic elements being used throughout */}
      <Box component="blockquote" style={{ height: 248 }}>
        <Stack space="large">
          <Box style={{ height: 160 }}>
            <Stack space="medium">
              <Box
                component="span"
                display="block"
                className={styles.quoteMark}
                style={{
                  color: vars.foregroundColor.brandAccent,
                  fontSize: 64,
                }}
              >
                &ldquo;
              </Box>

              <Text component="p" maxLines={5}>
                {testimonial.quote}
              </Text>
            </Stack>
          </Box>
          <Text component="footer" size="small">
            <Stack space="xxsmall">
              <Strong>{testimonial.author}</Strong>
              {testimonial.company ? <>{testimonial.company}</> : null}
            </Stack>
          </Text>
        </Stack>
      </Box>
    </Box>
  </Box>
);
