import { Bleed, Box, Dropdown, Stack } from 'braid-design-system';
import { ALL_INDUSTRIES } from '../FastestGrowingSalaries/Data/fastestGrowingSalaries';
import {
  percentageWrapper,
  TableBuilder,
  textElementWrapper,
  textLinkWrapper,
} from '@seek/cmsu-rich-text/src/components/Table/TableBuilder';
import { useConfig } from 'src/client/shared/hooks/useConfig';
import { AU_TOP_GROWTH_JOBS_COUNTRY_DATA } from './data/generated-au-country-data';
import * as styles from './styles.css';
import { useMemo, useState } from 'react';
import { formatCurrency } from '@seek/cmsu-components/src/helpers/helper';
import { createJobsLink } from './helper';
import { NZ_TOP_GROWTH_JOBS_COUNTRY_DATA } from './data/generated-nz-country-data';
import { AU_TOP_GROWTH_JOBS_CLASSIFICATION_DATA } from './data/generated-au-classification-data';
import { NZ_TOP_GROWTH_JOBS_CLASSIFICATION_DATA } from './data/generated-nz-classification-data';
import type { Country } from '@seek/melways-sites/';

const filterEmptyData = (data: object) =>
  Object.values(data).every((value) => value !== null && value !== undefined);

// prepare data for the table
// AU + NZ: initial view show Top 20 by country
const LIMIT = 20;
const prepareCountryTableData = (country: string) => {
  const data =
    country === 'au'
      ? AU_TOP_GROWTH_JOBS_COUNTRY_DATA
      : NZ_TOP_GROWTH_JOBS_COUNTRY_DATA;
  return data?.filter(filterEmptyData).slice(0, LIMIT);
};

// AU NZ, has threshold 5%
const CHANGE_IN_JOB_ADS_THRESHOLD = 5;
const prepareClassificationTableData = (country: string) => {
  const data =
    country === 'au'
      ? AU_TOP_GROWTH_JOBS_CLASSIFICATION_DATA
      : NZ_TOP_GROWTH_JOBS_CLASSIFICATION_DATA;
  return Object.fromEntries(
    Object.entries(data).map(([key, roles]) => [
      key,
      roles
        .filter(filterEmptyData)
        .filter(
          (role) =>
            role.changeInJobAds &&
            role.changeInJobAds >= CHANGE_IN_JOB_ADS_THRESHOLD,
        ),
    ]),
  );
};

const classificationByCountry = (country: string) => {
  const data =
    country === 'au'
      ? AU_TOP_GROWTH_JOBS_CLASSIFICATION_DATA
      : NZ_TOP_GROWTH_JOBS_CLASSIFICATION_DATA;
  return Object.keys(data);
};

const createTableRow = (item: any, index: number, country: string) => {
  const percentageIcon = item.changeInJobAds ? (
    <div
      className={item.changeInJobAds > 0 ? styles.arrow_up : styles.arrow_down}
    />
  ) : (
    <></>
  );
  const jobAdsLink = createJobsLink(
    country,
    item.roleTitle,
    item.industryTitle,
  );

  return new Map<string, JSX.Element>([
    ['Rank', textElementWrapper(`${index + 1}`)],
    ['Industry', textElementWrapper(item.industryTitle)],
    ['Role', textLinkWrapper(item.roleTitle, jobAdsLink, { target: '_blank' })],
    [
      'Job ad growth ',
      item.changeInJobAds ? (
        percentageWrapper(item.changeInJobAds, percentageIcon)
      ) : (
        <></>
      ),
    ],
    [
      'Average salary',
      item.averageSalary ? (
        textElementWrapper(
          formatCurrency(item.averageSalary, country as Country),
        )
      ) : (
        <></>
      ),
    ],
    ['Job Ads', textLinkWrapper('View jobs', jobAdsLink, { target: '_blank' })],
  ]);
};

/**
 * Component that displays top job growth data visualization
 * for AU NZ
 * @jira https://myseek.atlassian.net/browse/WP-4208
 */
export const TopJobGrowth = () => {
  const { country } = useConfig();
  const [selectedIndustry, setSelectedIndustry] = useState<string | -1>(-1);

  const countryTableData = useMemo(
    () =>
      prepareCountryTableData(country)?.map((item, index) =>
        createTableRow(item, index, country),
      ),
    [country],
  );

  const classificationTableData = useMemo(() => {
    if (typeof selectedIndustry !== 'string') {
      return null;
    }

    return prepareClassificationTableData(country)[selectedIndustry]?.map(
      (item, index) => createTableRow(item, index, country),
    );
  }, [selectedIndustry, country]);

  if (country !== 'au' && country !== 'nz') {
    return null;
  }
  const displayDropdown = country !== 'nz';
  const displayCountryTable = selectedIndustry === -1 && countryTableData;
  const displayClassificationTable =
    selectedIndustry !== -1 && classificationTableData;
  return (
    <Box style={{ lineHeight: '24.528px' }}>
      <Stack space="gutter">
        {displayDropdown && (
          <Dropdown
            id="INDUSTRY_SELECTION"
            label="Select an industry"
            onChange={(event) => {
              setSelectedIndustry(
                event.currentTarget.value === '-1'
                  ? -1
                  : event.currentTarget.value,
              );
            }}
            value={selectedIndustry}
            placeholder="Select an industry"
          >
            <>
              <option value="-1">{ALL_INDUSTRIES}</option>
              {classificationByCountry(country).map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </>
          </Dropdown>
        )}
        {displayCountryTable && (
          <Bleed
            horizontal={{
              desktop: 'xxxlarge',
              tablet: 'none',
              mobile: 'none',
            }}
          >
            <TableBuilder tableData={countryTableData} showHeader={true} />
          </Bleed>
        )}
        {displayClassificationTable && (
          <Bleed
            horizontal={{
              desktop: 'xxxlarge',
              tablet: 'none',
              mobile: 'none',
            }}
          >
            <TableBuilder
              tableData={classificationTableData}
              showHeader={true}
            />
          </Bleed>
        )}
      </Stack>
    </Box>
  );
};
