import type {
  Category,
  LatestArticlesByCategoryFragment,
  ResponsiveColumnValueFragment,
} from '@seek/cmsu-cms-connect';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language, Brand } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import { Actions, Box, Heading, Spread, Text } from 'braid-design-system';

import { ITEM_DISPLAY_TYPE } from '../../helpers/articleHelper';
import { useScreenSize, type ViewPoints } from '../../hooks/useScreenSize';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { ArticleItem } from '../ArticleItem/ArticleItem';
import {
  articleItemHelper,
  type ArticleItemFieldVisibility,
} from '../ArticleItem/articleItemHelper';
import { Tiles } from '../Tiles/Tiles';

type Props = {
  category: Category;
  language: Language;
  brand: Brand;
  sectionOverride?: string;
  latestArticlesByCategory: Pick<
    LatestArticlesByCategoryFragment,
    | 'headingText'
    | 'descriptionText'
    | 'category'
    | 'cardFields'
    | 'maximumArticlesToDisplay'
  >;
};
export const LatestArticlesByCategory = ({
  category,
  language,
  brand,
  sectionOverride,
  latestArticlesByCategory = {} as LatestArticlesByCategoryFragment,
}: Props) => {
  const urlResolver = useMelwaysLink();
  const { t } = useTranslations(translations);
  const screenSize = useScreenSize();

  if (
    category.relatedArticlesInCategory &&
    category.relatedArticlesInCategory.length &&
    category.relatedArticlesInCategory.length === 0
  )
    return;

  const {
    headingText: heading,
    descriptionText: description,
    cardFields = [],
    maximumArticlesToDisplay,
  } = latestArticlesByCategory;
  const maxArticlesToDisplayForCurrentScreenSize =
    getMaximumArticlesToDisplayByScreenSize(
      screenSize,
      maximumArticlesToDisplay ?? undefined,
    );

  const showBrowseAll =
    category.relatedArticlesInCategory.length >
    maxArticlesToDisplayForCurrentScreenSize;

  const section = sectionOverride
    ? sectionOverride
    : getSectionFromCategory(category);

  const categoryLink = urlResolver({
    language,
    path: `/${section}/category/${category.slug}`,
    brand,
  });

  const articleItemFieldVisibility: ArticleItemFieldVisibility =
    articleItemHelper.mapToArticleFieldVisibility(cardFields);

  return (
    <Spread space="gutter" direction="vertical">
      <Heading level="2">
        <Box component="span" data={{ testid: 'Category_Name_TestId' }}>
          {Boolean(heading) ? heading : category.name}
        </Box>
      </Heading>
      {Boolean(description) && <Text>{description}</Text>}
      <Tiles
        space="gutter"
        columns={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
          wide: 3,
        }}
      >
        {category.relatedArticlesInCategory
          .slice(0, maxArticlesToDisplayForCurrentScreenSize)
          .map((article, index) => (
            <Box
              component="span"
              key={index}
              data={{ testid: `ArticleItem_${article.id}_TestId` }}
            >
              <ArticleItem
                index={index}
                displayType={ITEM_DISPLAY_TYPE.CARD}
                article={article}
                language={language}
                brand={brand}
                section={section}
                fieldVisibility={articleItemFieldVisibility}
              />
            </Box>
          ))}
      </Tiles>
      {showBrowseAll && (
        <Actions data={{ testid: 'BrowseAllButtonTestId' }}>
          <ButtonLink
            link={categoryLink}
            text={t('Browse all')}
            variant="ghost"
            tone="neutral"
            openLinkInNewTab={false}
            uniqueTrackingId="text-link"
            trackingAttributes={{
              elementText: t('Browse all'),
              elementLink: categoryLink,
            }}
          />
        </Actions>
      )}
    </Spread>
  );
};

export const getMaximumArticlesToDisplayByScreenSize = (
  screenSize: ViewPoints,
  maximumArticlesToDisplay: ResponsiveColumnValueFragment = {
    mobile: 3,
    tablet: 2,
    desktop: 3,
    wide: 3,
  },
) => {
  if (screenSize.isMobile) return maximumArticlesToDisplay.mobile;
  if (screenSize.isTablet) return maximumArticlesToDisplay.tablet;
  if (screenSize.isDesktop) return maximumArticlesToDisplay.desktop;
  if (screenSize.isWide) return maximumArticlesToDisplay.wide;
  return 3;
};

const getSectionFromCategory = (category: Category) =>
  category.relatedCategorySection?.parentSection?.sectionName
    ? `${category.relatedCategorySection?.parentSection?.sectionName}/${category.relatedCategorySection?.sectionName}`
    : category.relatedCategorySection?.sectionName;
