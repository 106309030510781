import { CustomDecoration } from '@seek/cmsu-cms-connect';
import type React from 'react';
import type { Box } from 'braid-design-system';
import {
  ContactUsDecoration,
  TALDecoration2,
  TALDecoration,
  SaraBokehDecoration01,
  CareersRibbonsDecoration,
} from './components';
import { StarPinkDecoration } from './components/StarPinkDecoration';
import { ArchedLinesDecoration } from './components/ArchedLinesDecoration';

interface BoxWithDecoratorProps extends React.ComponentProps<typeof Box> {
  customDecoration?: CustomDecoration | null;
}
// Function to decide additional Box props based on decoration type
export const getDecorationRendererBoxProps = ({
  customDecoration,
}: BoxWithDecoratorProps): React.ComponentProps<typeof Box> => {
  switch (customDecoration) {
    case CustomDecoration.ContactUsBannerDeco:
      return {
        style: { overflow: 'hidden' }, // Hide decoration bleed on bottom right
      };
    case CustomDecoration.SaraBokeh_01:
      return {
        style: { overflowX: 'clip' }, // Hide decoration on x-axis
      };
    default:
      return {};
  }
};

export const DecorationRenderer = ({
  decorationType,
}: {
  decorationType: CustomDecoration | null | undefined;
}) => {
  switch (decorationType) {
    case CustomDecoration.ContactUsBannerDeco:
      return <ContactUsDecoration />;
    case CustomDecoration.TalBannerDeco:
      return <TALDecoration />;
    case CustomDecoration.TalBannerDeco_2:
      return <TALDecoration2 />;
    case CustomDecoration.SaraBokeh_01:
      return <SaraBokehDecoration01 />;
    case CustomDecoration.CareersRibbonsAnimated:
      return <CareersRibbonsDecoration number={0} />;
    case CustomDecoration.CareersRibbons_1:
      return <CareersRibbonsDecoration number={1} />;
    case CustomDecoration.CareersRibbons_2:
      return <CareersRibbonsDecoration number={2} />;
    case CustomDecoration.CareersRibbons_3:
      return <CareersRibbonsDecoration number={3} />;
    case CustomDecoration.CareersRibbons_4:
      return <CareersRibbonsDecoration number={4} />;
    case CustomDecoration.StarPinkTopRight:
    case CustomDecoration.StarPinkBottomLeft:
      return <StarPinkDecoration variant={decorationType} />;
    case CustomDecoration.ArchesBlueBottomLeft:
    case CustomDecoration.ArchesBlueRight:
    case CustomDecoration.ArchesBlueLeft:
    case CustomDecoration.ArchesBlueTopLeft:
    case CustomDecoration.ArchesWhiteBottomRight:
    case CustomDecoration.ArchesWhiteTopRight:
      return <ArchedLinesDecoration variant={decorationType} />;
    default:
      return null;
  }
};
