import type { TopJobGrowthData } from '../types';

// THIS IS GENERATED DATA FROM SCRIPT.JS
export const AU_TOP_GROWTH_JOBS_COUNTRY_DATA: TopJobGrowthData[] = [
  {
    rank: 1,
    roleTitle: 'Trolley Return Assistant',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 234,
    changeInJobAds: 172.1,
    averageSalary: null,
    changeInSalary: null,
  },
  {
    rank: 2,
    roleTitle: 'Assessor',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 117,
    changeInJobAds: 165.9,
    averageSalary: 96154,
    changeInSalary: 13.82,
  },
  {
    rank: 3,
    roleTitle: 'Lending Manager',
    industryTitle: 'Banking & Financial Services',
    numberOfJobAds: 79,
    changeInJobAds: 163.3,
    averageSalary: 133903,
    changeInSalary: 7.82,
  },
  {
    rank: 4,
    roleTitle: 'Assessor',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 85,
    changeInJobAds: 142.9,
    averageSalary: 88660,
    changeInSalary: 14.59,
  },
  {
    rank: 5,
    roleTitle: 'Support Practitioner',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 250,
    changeInJobAds: 127.3,
    averageSalary: 90714,
    changeInSalary: 2.31,
  },
  {
    rank: 6,
    roleTitle: 'Appointment Setter',
    industryTitle: 'Sales',
    numberOfJobAds: 59,
    changeInJobAds: 90.3,
    averageSalary: 70985,
    changeInSalary: -2.96,
  },
  {
    rank: 7,
    roleTitle: 'Artist',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 57,
    changeInJobAds: 90,
    averageSalary: 55050,
    changeInSalary: 11.32,
  },
  {
    rank: 8,
    roleTitle: 'Travel Consultant',
    industryTitle: 'Sales',
    numberOfJobAds: 210,
    changeInJobAds: 87.5,
    averageSalary: 53407,
    changeInSalary: null,
  },
  {
    rank: 9,
    roleTitle: 'Hospitality Assistant',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 64,
    changeInJobAds: 82.9,
    averageSalary: null,
    changeInSalary: null,
  },
  {
    rank: 10,
    roleTitle: 'Certifier',
    industryTitle: 'Construction',
    numberOfJobAds: 52,
    changeInJobAds: 73.3,
    averageSalary: 114317,
    changeInSalary: 26.83,
  },
  {
    rank: 11,
    roleTitle: 'Building Supervisor',
    industryTitle: 'Construction',
    numberOfJobAds: 131,
    changeInJobAds: 72.4,
    averageSalary: 106840,
    changeInSalary: 0.55,
  },
  {
    rank: 12,
    roleTitle: 'Maintenance Operator',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 59,
    changeInJobAds: 68.6,
    averageSalary: 63877,
    changeInSalary: 2.9,
  },
  {
    rank: 13,
    roleTitle: 'Dispenser',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 115,
    changeInJobAds: 64.3,
    averageSalary: 59300,
    changeInSalary: 8.36,
  },
  {
    rank: 14,
    roleTitle: 'Optical Assistant',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 202,
    changeInJobAds: 62.9,
    averageSalary: 54778,
    changeInSalary: 4.07,
  },
  {
    rank: 15,
    roleTitle: 'Engagement Coordinator',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 81,
    changeInJobAds: 62,
    averageSalary: 85787,
    changeInSalary: 7.79,
  },
  {
    rank: 16,
    roleTitle: 'Maintenance Team Leader',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 123,
    changeInJobAds: 61.8,
    averageSalary: 69491,
    changeInSalary: 0.22,
  },
  {
    rank: 17,
    roleTitle: 'Data Analyst',
    industryTitle: 'Government & Defence',
    numberOfJobAds: 53,
    changeInJobAds: 60.6,
    averageSalary: 105093,
    changeInSalary: 1.79,
  },
  {
    rank: 18,
    roleTitle: 'Groomer',
    industryTitle: 'Farming, Animals & Conservation',
    numberOfJobAds: 117,
    changeInJobAds: 60.3,
    averageSalary: 64256,
    changeInSalary: 5.64,
  },
  {
    rank: 19,
    roleTitle: 'Estimator Supervisor',
    industryTitle: 'Construction',
    numberOfJobAds: 67,
    changeInJobAds: 59.5,
    averageSalary: 102054,
    changeInSalary: 4.52,
  },
  {
    rank: 20,
    roleTitle: 'Chaplain',
    industryTitle: 'Education & Training',
    numberOfJobAds: 51,
    changeInJobAds: 59.4,
    averageSalary: 86332,
    changeInSalary: 2.06,
  },
  {
    rank: 21,
    roleTitle: 'Sales Representative',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 127,
    changeInJobAds: 58.8,
    averageSalary: 70627,
    changeInSalary: 5.17,
  },
  {
    rank: 22,
    roleTitle: 'Mentor',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 262,
    changeInJobAds: 57.8,
    averageSalary: 71760,
    changeInSalary: 0.79,
  },
  {
    rank: 23,
    roleTitle: 'Coach',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 55,
    changeInJobAds: 57.1,
    averageSalary: 80272,
    changeInSalary: -6.71,
  },
  {
    rank: 24,
    roleTitle: 'Migration Agent',
    industryTitle: 'Legal',
    numberOfJobAds: 97,
    changeInJobAds: 56.5,
    averageSalary: 93371,
    changeInSalary: 12.95,
  },
  {
    rank: 25,
    roleTitle: 'Lending Specialist',
    industryTitle: 'Banking & Financial Services',
    numberOfJobAds: 112,
    changeInJobAds: 55.6,
    averageSalary: 86523,
    changeInSalary: 3.14,
  },
  {
    rank: 26,
    roleTitle: 'Relationship Officer',
    industryTitle: 'Call Centre & Customer Service',
    numberOfJobAds: 60,
    changeInJobAds: 53.8,
    averageSalary: 66092,
    changeInSalary: 0.56,
  },
  {
    rank: 27,
    roleTitle: 'Front of House Supervisor',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 67,
    changeInJobAds: 52.3,
    averageSalary: 69668,
    changeInSalary: 7.2,
  },
  {
    rank: 28,
    roleTitle: 'Peer Worker',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 73,
    changeInJobAds: 52.1,
    averageSalary: 79861,
    changeInSalary: 7.9,
  },
  {
    rank: 29,
    roleTitle: 'Contractor',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 50,
    changeInJobAds: 51.5,
    averageSalary: 91201,
    changeInSalary: -2.38,
  },
  {
    rank: 30,
    roleTitle: 'Medical Practitioner',
    industryTitle: 'Healthcare & Medical',
    numberOfJobAds: 59,
    changeInJobAds: 51.3,
    averageSalary: 234347,
    changeInSalary: 19.3,
  },
  {
    rank: 31,
    roleTitle: 'Console Operator',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 124,
    changeInJobAds: 51.2,
    averageSalary: 64523,
    changeInSalary: 1.89,
  },
  {
    rank: 32,
    roleTitle: 'Scheduler',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 57,
    changeInJobAds: 50,
    averageSalary: 70456,
    changeInSalary: 4.25,
  },
  {
    rank: 35,
    roleTitle: 'Installer',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 51,
    changeInJobAds: 50,
    averageSalary: 63066,
    changeInSalary: 4.02,
  },
  {
    rank: 33,
    roleTitle: 'School Officer',
    industryTitle: 'Education & Training',
    numberOfJobAds: 48,
    changeInJobAds: 50,
    averageSalary: 65631,
    changeInSalary: 6.09,
  },
  {
    rank: 34,
    roleTitle: 'Employee Relations Adviser',
    industryTitle: 'Human Resources & Recruitment',
    numberOfJobAds: 48,
    changeInJobAds: 50,
    averageSalary: 111848,
    changeInSalary: -0.58,
  },
  {
    rank: 36,
    roleTitle: 'Duty Manager',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 152,
    changeInJobAds: 49,
    averageSalary: 66995,
    changeInSalary: -3.1,
  },
  {
    rank: 37,
    roleTitle: 'Sorter',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 83,
    changeInJobAds: 48.2,
    averageSalary: 65820,
    changeInSalary: 1.95,
  },
  {
    rank: 38,
    roleTitle: 'Arborist',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 153,
    changeInJobAds: 47.1,
    averageSalary: 77899,
    changeInSalary: 9.42,
  },
  {
    rank: 39,
    roleTitle: 'Recruitment Consultant',
    industryTitle: 'Sales',
    numberOfJobAds: 101,
    changeInJobAds: 46.4,
    averageSalary: 76047,
    changeInSalary: -0.54,
  },
  {
    rank: 40,
    roleTitle: 'Production Coordinator',
    industryTitle: 'Manufacturing, Transport & Logistics',
    numberOfJobAds: 57,
    changeInJobAds: 46.2,
    averageSalary: 79547,
    changeInSalary: -7.91,
  },
  {
    rank: 41,
    roleTitle: 'Formworker',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 73,
    changeInJobAds: 46,
    averageSalary: 99947,
    changeInSalary: 3.8,
  },
  {
    rank: 42,
    roleTitle: 'Wellbeing Worker',
    industryTitle: 'Community Services & Development',
    numberOfJobAds: 51,
    changeInJobAds: 45.7,
    averageSalary: 80880,
    changeInSalary: 3.12,
  },
  {
    rank: 43,
    roleTitle: 'Hydraulic Engineer',
    industryTitle: 'Engineering',
    numberOfJobAds: 67,
    changeInJobAds: 45.7,
    averageSalary: 128496,
    changeInSalary: 13.55,
  },
  {
    rank: 44,
    roleTitle: 'Law Graduate',
    industryTitle: 'Legal',
    numberOfJobAds: 67,
    changeInJobAds: 45.7,
    averageSalary: 77803,
    changeInSalary: 11.37,
  },
  {
    rank: 45,
    roleTitle: 'Concession Manager',
    industryTitle: 'Retail & Consumer Products',
    numberOfJobAds: 87,
    changeInJobAds: 45,
    averageSalary: 64774,
    changeInSalary: 4.44,
  },
  {
    rank: 46,
    roleTitle: 'Customer Experience Specialist',
    industryTitle: 'Call Centre & Customer Service',
    numberOfJobAds: 55,
    changeInJobAds: 44.7,
    averageSalary: 66637,
    changeInSalary: 5.38,
  },
  {
    rank: 47,
    roleTitle: 'Pipefitter',
    industryTitle: 'Trades & Services',
    numberOfJobAds: 50,
    changeInJobAds: 42.9,
    averageSalary: 106199,
    changeInSalary: 1.31,
  },
  {
    rank: 48,
    roleTitle: 'Maintenance Technician',
    industryTitle: 'Engineering',
    numberOfJobAds: 44,
    changeInJobAds: 41.9,
    averageSalary: 96199,
    changeInSalary: 13.15,
  },
  {
    rank: 49,
    roleTitle: 'Floor Staff',
    industryTitle: 'Hospitality & Tourism',
    numberOfJobAds: 62,
    changeInJobAds: 40.9,
    averageSalary: 67692,
    changeInSalary: 5.54,
  },
  {
    rank: 50,
    roleTitle: 'Customer Service and Sales Consultant',
    industryTitle: 'Call Centre & Customer Service',
    numberOfJobAds: 90,
    changeInJobAds: 40.6,
    averageSalary: 64861,
    changeInSalary: 3.55,
  },
];
