import {
  CTextAlign,
  type CustomFooter,
  type CustomHeader,
  type GetPagesWithEntitiesQuery,
  HeaderLayout,
  Locale,
  type Section,
} from '@seek/cmsu-cms-connect';

export const isNumber = (value: any): boolean => !isNaN(Number(value));

export const enabledSubscriptionForm = (
  section: string,
  excludeSectionName?: string,
) =>
  excludeSectionName !== section &&
  ['hiring-advice', 'market-insights'].includes(section);

export const removeLanguagePath = (pageUrl: string, language: string) => {
  // remove extra language path from non-english language
  if (pageUrl.startsWith(`/${language}/`)) {
    return pageUrl.substring(3);
  }

  return pageUrl;
};

interface HeaderProps {
  type: 'SeekHeader' | 'CustomHeader';
  layout?: HeaderLayout;
  logo?: CustomHeader['logo'];
  links?: CustomHeader['links'];
  divider?: CustomHeader['divider'];
  linksAlignment?: CTextAlign;
}

export const getHeaderConfig = (
  headerOptions: Section['headerOptions'],
): HeaderProps => {
  if (headerOptions?.headerType?.__typename === 'CustomHeader') {
    return {
      type: 'CustomHeader',
      logo: headerOptions.headerType.logo,
      links: headerOptions.headerType.links,
      divider: headerOptions.headerType.divider,
      linksAlignment:
        headerOptions.headerType.linksAlignment || CTextAlign.Left,
    };
  }

  if (headerOptions?.headerType?.__typename === 'SeekHeader') {
    return {
      type: 'SeekHeader',
      layout: headerOptions.headerType.layout || HeaderLayout.Full,
    };
  }

  return { type: 'SeekHeader' };
};

interface FooterProps {
  type: 'CustomFooter';
  logo?: CustomFooter['logo'];
  links?: CustomFooter['links'];
  backgroundColor?: CustomFooter['backgroundColor'];
}

export const getFooterConfig = (
  footerOptions: Section['footerOptions'],
): FooterProps | undefined => {
  if (footerOptions?.footerType?.__typename === 'CustomFooter') {
    return {
      type: 'CustomFooter',
      logo: footerOptions.footerType.logo,
      links: footerOptions.footerType.links,
      backgroundColor: footerOptions.footerType.backgroundColor,
    };
  }

  return undefined;
};
export const getHygraphLocales = (hygraphLocale: Locale): Locale[] =>
  hygraphLocale.startsWith('en_')
    ? [hygraphLocale, Locale.Default]
    : [hygraphLocale];

export const getSectionsData = (
  sectionDetails: GetPagesWithEntitiesQuery['pages'][0]['relatedPageSection'],
): Record<string, string> | undefined => {
  let sectionData;
  if (sectionDetails?.parentSection) {
    sectionData = {
      siteSection: sectionDetails?.parentSection.sectionName,
      siteSubsection: sectionDetails.sectionName,
    };
  } else {
    sectionData = {
      siteSection: sectionDetails?.sectionName || '',
    };
  }
  return sectionData;
};
