import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useConfig } from 'src/client/shared/hooks';
import { titleHelper } from '@seek/cmsu-components/src/helpers/helper';

interface HelmetProps {
  title: string;
  seoTitle: string;
  url: string;
  description?: string;
  noIndex?: boolean;
  noArchive?: boolean;
  locales?: { locale: string; url: string }[];
}

const Helm = ({
  title,
  seoTitle,
  url,
  description,
  noIndex,
  noArchive,
  locales,
}: HelmetProps) => {
  const { brand, site, section } = useConfig();
  const displayTitle = titleHelper.getTitle({ brand, site, title, section });
  const meta = useMemo(() => {
    const generalMeta = [
      { name: 'title', content: seoTitle },
      { name: 'description', content: description },
    ];

    const fbMeta = [
      { property: 'og:title', content: seoTitle },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SEEK' },
      { property: 'og:description', content: description },
    ];

    const twitterMeta = [
      { name: 'twitter:title', content: seoTitle },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:description', content: description },
    ];

    const robotsValues: Record<string, boolean> = {
      noindex: Boolean(noIndex),
      noarchive: Boolean(noArchive),
    };

    // Reduce the values to a string[]
    const robotMeta = Object.entries(robotsValues).reduce<string[]>(
      (carry, [key, value]) => (value ? [...carry, key] : carry),
      [],
    );

    if (robotMeta.length) {
      generalMeta.push({ name: 'robots', content: robotMeta.join(',') });
    }

    // Filter out meta with no content
    return [...generalMeta, ...fbMeta, ...twitterMeta].filter((metaData) =>
      Boolean(metaData.content),
    );
  }, [description, noArchive, noIndex, seoTitle]);

  return (
    <Helmet meta={meta}>
      <title>{displayTitle}</title>
      <link rel="canonical" href={url} />
      {locales?.map((l) => (
        <link rel="alternate" key={l.locale} hrefLang={l.locale} href={l.url} />
      ))}
    </Helmet>
  );
};
export { Helm };
