import type { CTextLink, Locale, TextLinkGroup } from '@seek/cmsu-cms-connect';
import type { Language } from '@seek/melways-sites';
import { adapter } from '@seek/tealium-adapter';
import type { NestedLink } from '../../modules/CustomPageHeader/types';
import { toUrl } from '../../utils';
import { checkSkipDefaultLocale } from '../../utils/checkSkipDefaultLocale';

import JobsdbLogo from './images/jobsdb-logo-40px.svg';
import JobstreetLogo from './images/jobstreet-logo-40px.svg';
import SeekLogo from './images/seek-logo-40px.svg';

/**
 * Convert the array of links of type CTextLink to a simplified array of SimpleLink objects
 * @param linkArray - array of links of type CTextLink
 * @param language - the current language eg., 'en'
 * @param locale - the current site locale eg., 'en_AU'
 * @returns Array of SimpleLink objects with text and url properties
 */
export const convertCTextLinks = (
  linkArray: CTextLink[],
  language: Language,
  locale: Locale,
): CTextLink[] =>
  linkArray
    .filter(
      (item) =>
        item.link &&
        !checkSkipDefaultLocale(item.skipDefaultLocale, item.locale, locale),
    )
    .map((item) => item);

/*
  Convert the array of links of type TextLinkGroup
  to a simplified array of NestedLink objects
  Mainly used in custom header
  @param linkArray - array of links of type TextLinkGroup
  @param language - the current language eg., 'en'
  @param locale - the current site locale eg., 'en_AU'
*/
export const convertLinks = (
  linkArray: TextLinkGroup[],
  language: Language,
  locale: Locale,
): NestedLink[] => {
  const arr = linkArray.map((item) => {
    const { link, subLinks } = item;
    if (!link) {
      return null;
    }
    if (checkSkipDefaultLocale(link.skipDefaultLocale, link.locale, locale)) {
      return null;
    }

    return {
      text: link.text || '',
      url: toUrl(link.link?.to as any, language),
      subLinks:
        (subLinks &&
          subLinks
            .map((s) => {
              if (
                checkSkipDefaultLocale(s.skipDefaultLocale, s.locale, locale)
              ) {
                return null;
              }
              return {
                text: s.text || '',
                url: toUrl(s.link?.to as any, language),
              };
            })
            .filter(Boolean)) ||
        [],
    };
  });

  return arr.filter(Boolean) as NestedLink[];
};

export const tealium = adapter();

export const trackLink = (
  trackingEvent: string,
  trackingAttributes: Record<string, string>,
) => {
  tealium.link(trackingEvent, trackingAttributes);
};

const encodeSvg = (svg: string) =>
  `'data:image/svg+xml;utf8,${encodeURIComponent(svg)}'`;

const getImage = (locale: Locale) => {
  switch (locale) {
    case 'en_HK':
    case 'en_TH':
    case 'th_TH':
      return {
        alt: `About ${getBrandName(locale)}`,
        url: encodeSvg(JobsdbLogo),
        mimeType: 'image/svg+xml',
        height: 40,
        width: 121,
        __typename: 'Asset',
      };
    case 'en_PH':
    case 'en_MY':
    case 'en_SG':
    case 'en_ID':
    case 'id_ID':
      return {
        alt: `About ${getBrandName(locale)}`,
        url: encodeSvg(JobstreetLogo),
        mimeType: 'image/svg+xml',
        height: 40,
        width: 150,
        __typename: 'Asset',
      };
    default:
      return {
        alt: `About ${getBrandName(locale)}`,
        url: encodeSvg(SeekLogo),
        mimeType: 'image/svg+xml',
        height: 40,
        width: 102,
        __typename: 'Asset',
      };
  }
};

const getBrandName = (locale: Locale) => {
  switch (locale) {
    case 'en_HK':
    case 'en_TH':
    case 'th_TH':
      return 'Jobsdb';
    case 'en_PH':
    case 'en_MY':
    case 'en_SG':
    case 'en_ID':
    case 'id_ID':
      return 'Jobstreet';
    default:
      return 'SEEK';
  }
};

export const getFallbackSeekLogo = (locale: Locale) => ({
  title: `${getBrandName(locale)} logo`,
  locale,
  image: getImage(locale),
  link: {
    to: null,
    __typename: 'Link',
  },
  __typename: 'CImageLink',
});

/*
  Remove the domain from the URL to match the active route
  Override in-place because of the about/investor page links
  are set as ExternalUrls directly eg., 'https://www.seek.com.au/about/investors'
*/
export const getUrlPath = (url?: string) => {
  if (!url) return '';

  const baseUrl = 'https://www.seek.com.au';
  const urlPath = new URL(url, baseUrl).pathname;

  return urlPath;
};
