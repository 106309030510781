import { Box } from 'braid-design-system';

import { archedLines } from './styles.css';
import type { CustomDecoration } from '@seek/cmsu-cms-connect';

export const ArchedLinesDecoration = ({
  variant,
}: {
  variant:
    | CustomDecoration.ArchesBlueBottomLeft
    | CustomDecoration.ArchesBlueRight
    | CustomDecoration.ArchesBlueLeft
    | CustomDecoration.ArchesBlueTopLeft
    | CustomDecoration.ArchesWhiteBottomRight
    | CustomDecoration.ArchesWhiteTopRight;
}) => (
  <Box
    data={{ testid: `archedLines-deco__${variant}` }}
    className={archedLines[variant]}
  />
);
