import { Box } from 'braid-design-system';
import type { DataAttributeMap } from '@seek/cmsu-components/src/modules/types';

type Props = {
  mediaUrl: string | null | undefined;
  data?: DataAttributeMap | undefined;
};

export const PodcastPlayer = ({ mediaUrl, data }: Props) => {
  if (!mediaUrl) return;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      data={{
        testid: 'PodcastPlayerTestId',
        ...data,
        'hygraph-field-api-id': 'mediaUrl',
      }}
    >
      <iframe src={mediaUrl} width="100%" height="220" frameBorder="0" />
    </Box>
  );
};
