import type { CustomFooter, Locale } from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import { Box, Column, Columns, PageBlock, Stack } from 'braid-design-system';

import { CustomFooterProvider } from './CustomFooterProvider';

import {
  convertCTextLinks,
  getFallbackSeekLogo,
} from '../../shared/header-footer/helpers';
import { getBoxBackgroundProps } from '../../shared/helper';
import { FooterLogoImage } from './components/Logo';
import { NavLinkGroup } from './components/NavLinkGroup';

interface Props
  extends Partial<Pick<CustomFooter, 'backgroundColor' | 'links' | 'logo'>> {
  language: Language;
  country: Country;
  locale: Locale;

  theme?: 'default' | 'sara' | 'star';
}

export const CustomPageFooter = ({
  country,

  logo,
  links,
  language,
  backgroundColor,
  locale,
  theme = 'default',
}: Props) => {
  const backgroundProps = getBoxBackgroundProps(backgroundColor);
  const formattedLinks = links && convertCTextLinks(links, language, locale);

  const fallbackData = getFallbackSeekLogo(locale);
  const logoData = logo || fallbackData.image;
  // so we can actually use the fallback alt infomation
  const logoAlt = logo?.alt || fallbackData.title;
  return (
    <CustomFooterProvider
      config={{
        language,
        country,

        links: formattedLinks,
        theme,
      }}
    >
      <Box data={{ testid: 'custom-footer' }}>
        <Box {...backgroundProps} paddingY={'gutter'}>
          <Stack space="xxsmall">
            <PageBlock width="large">
              <Columns space="small" alignY="center">
                <Column>
                  <Columns
                    space={{
                      mobile: 'large',

                      desktop: 'gutter',
                    }}
                    alignY="center"
                    collapseBelow="desktop"
                  >
                    {/* Logo image */}
                    <Column width="content">
                      <Box>
                        <FooterLogoImage
                          size={{
                            width: logoData?.width as number,
                            height: logoData?.height as number,
                          }}
                          src={logoData?.url as string}
                          alt={logoAlt}
                        />
                      </Box>
                    </Column>
                    {/* Nav Links */}
                    <Column>
                      {formattedLinks && (
                        <NavLinkGroup links={formattedLinks} />
                      )}
                    </Column>
                  </Columns>
                </Column>
              </Columns>
            </PageBlock>
          </Stack>
        </Box>
      </Box>
    </CustomFooterProvider>
  );
};
