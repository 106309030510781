import {
  HiddenBelow,
  type BoxFragment,
  type ImageBlockFragment,
  type ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Columns, Column, Stack, Actions } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { TextLink } from '../ActionGroup/TextLink';
import { DialogButton } from '../ActionGroup/DialogButton';
import { toUrl } from '../../utils/toUrl';
import {
  getBlockBorderProps,
  getBoxBackgroundProps,
} from '../../shared/helper';
import { getElementAction } from '../../utils/getElementAction';
import { useCMSUContext } from '../../hooks/useCMSUContext';

type Props = Pick<
  ImageBlockFragment,
  | 'heading'
  | 'image'
  | 'paragraph'
  | 'cButtonLink'
  | 'cTextLink'
  | 'dialogButton'
  | 'reverseContent'
> &
  Pick<
    ImageBlockGroupFragment,
    'imageAlignment' | 'blockBackground' | 'blockBorder' | 'stackBelow'
  > & {
    inlineTextJustify?: BoxFragment['justifyContent'];
  };

const getCollapseProp = (stackBelow: ImageBlockGroupFragment['stackBelow']) => {
  switch (stackBelow) {
    case HiddenBelow.Tablet:
      return 'tablet';
    case HiddenBelow.Desktop:
      return 'desktop';
    default:
      return undefined;
  }
};

const getDesktopContentReversal = (
  reverseContent: ImageBlockFragment['reverseContent'],
  stackBelow: ImageBlockGroupFragment['stackBelow'],
) => {
  if (!stackBelow || stackBelow === HiddenBelow.None) return false;
  switch (reverseContent) {
    case 'mobile':
    case 'desktop':
      return true;
    case 'both':
    default:
      return false;
  }
};

const getAlignYProp = (inlineTextJustify: BoxFragment['justifyContent']) => {
  switch (inlineTextJustify) {
    case 'flexStart':
      return 'top';
    case 'flexEnd':
      return 'bottom';
    case 'center':
      return 'center';
    default:
      return 'top';
  }
};

const getImagePaddingGap = (imageWidth: number) => {
  if (imageWidth > 100) {
    return 'medium';
  }
  if (imageWidth > 70) {
    return 'small';
  }
  if (imageWidth > 40) {
    return 'xsmall';
  }
  if (imageWidth > 25) {
    return 'xsmall';
  }

  return 'none';
};

export const InlineImageBlock = ({
  imageAlignment,
  image,
  heading,
  paragraph,
  cButtonLink,
  cTextLink,
  dialogButton,
  stackBelow = HiddenBelow.None,
  inlineTextJustify,
  blockBackground,
  blockBorder,
  reverseContent,
}: Props) => {
  const { language, utmParameters } = useCMSUContext();

  const ImageComponent = React.useMemo(
    () =>
      image ? (
        <Box
          display="flex"
          alignItems={imageAlignment || 'flexStart'}
          paddingRight={getImagePaddingGap(image.width!)}
        >
          <Asset
            assetType={AssetType.DEFAULT_IMAGE}
            {...image}
            style={{ maxWidth: 'fit-content' }}
          />
        </Box>
      ) : null,
    [image, imageAlignment],
  );

  const ContentComponent = React.useMemo(() => {
    const hasCta = cButtonLink || dialogButton || cTextLink;

    const textLinkTrackingAttributes = {
      elementText: cTextLink?.text || '',
      elementLink: toUrl(cTextLink?.link?.to),
      elementDesign: 'link',
    };

    return (
      <Box
        width="full"
        display="flex"
        flexDirection="column"
        justifyContent={inlineTextJustify ?? 'center'}
      >
        <Stack space="large">
          <Stack space="small">
            {heading && <Heading {...heading} />}
            {paragraph && (
              <Paragraph
                {...paragraph}
                content={paragraph.Paragraph_text?.raw}
              />
            )}
          </Stack>
          {hasCta ? (
            <Actions>
              {cButtonLink && (
                <Box display="inlineBlock">
                  <ButtonLink
                    link={toUrl(cButtonLink.link?.to, language, utmParameters)}
                    text={cButtonLink.text}
                    tone={cButtonLink.tone || 'neutral'}
                    variant={cButtonLink.variant || 'solid'}
                    size={cButtonLink.size || 'standard'}
                    icon={cButtonLink.icon}
                    iconPosition={cButtonLink.iconPosition}
                    openLinkInNewTab={
                      cButtonLink.link?.openLinkInNewTab || false
                    }
                    uniqueTrackingId="button-link"
                    trackingAttributes={{
                      elementText: cButtonLink.text,
                      elementAction: getElementAction(cButtonLink.link?.to),
                      elementLink: toUrl(
                        cButtonLink.link?.to,
                        language,
                        utmParameters,
                      ),
                    }}
                  />
                </Box>
              )}
              {dialogButton && (
                <Box display="inlineBlock">
                  <DialogButton {...dialogButton} />
                </Box>
              )}
              {cTextLink && (
                <Box display="inlineBlock">
                  <TextLink
                    uniqueTrackingId="text-link"
                    trackingAttributes={textLinkTrackingAttributes}
                    {...cTextLink}
                  />
                </Box>
              )}
            </Actions>
          ) : null}
        </Stack>
      </Box>
    );
  }, [
    cButtonLink,
    cTextLink,
    dialogButton,
    heading,
    inlineTextJustify,
    language,
    paragraph,
    utmParameters,
  ]);

  // Given {image} is content #1, and {content} is content #2,
  // we need to set the order of the content based on the reverseContent value
  const getContentOrder = React.useMemo(
    () => (reverse: ImageBlockFragment['reverseContent']) => {
      const firstCol = <Column width="content">{ImageComponent}</Column>;
      const secondCol = <Column>{ContentComponent}</Column>;

      switch (reverse) {
        case 'both':
        case 'mobile':
          return [secondCol, firstCol];
        case 'desktop':
        default:
          return [firstCol, secondCol];
      }
    },
    [ImageComponent, ContentComponent],
  );

  return (
    <Box
      padding={blockBackground ? 'gutter' : 'none'}
      borderRadius={blockBackground ? 'large' : 'none'}
      height="full"
      {...getBoxBackgroundProps(blockBackground)}
      {...getBlockBorderProps(blockBorder)}
    >
      {stackBelow !== HiddenBelow.None ? (
        <Box>
          <Columns
            space="medium"
            alignY={getAlignYProp(imageAlignment)}
            collapseBelow={getCollapseProp(stackBelow)}
            reverse={getDesktopContentReversal(reverseContent, stackBelow)}
          >
            {getContentOrder(reverseContent)}
          </Columns>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection={
            reverseContent === 'desktop' || reverseContent === 'both'
              ? 'rowReverse'
              : 'row'
          }
          gap="medium"
          alignItems={imageAlignment || 'center'}
        >
          {ImageComponent}
          {ContentComponent}
        </Box>
      )}
    </Box>
  );
};
