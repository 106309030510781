import type { Article, Maybe, Scalars } from '@seek/cmsu-cms-connect';
import { formatAbsoluteDatePreferredFormat } from './dateHelper';

export enum ITEM_DISPLAY_TYPE {
  LIST_ITEM,
  CARD,
}

export const articleActionByType = (articleType: string | null | undefined) => {
  switch (articleType?.toLowerCase()) {
    case 'video':
      return 'min watch';
    case 'podcast':
      return 'min listen';
    default:
      return 'min read';
  }
};

const getSectionFromArticle = (article: Article) =>
  article.category?.relatedCategorySection?.parentSection
    ? `${article.category?.relatedCategorySection?.parentSection?.sectionName}/${article.category?.relatedCategorySection?.sectionName}`
    : article.category?.relatedCategorySection?.sectionName;

export const resolveArticlePathFrom = (
  article: Article,
  sectionOverride?: string,
) => {
  const section = sectionOverride
    ? sectionOverride
    : getSectionFromArticle(article);
  const sectionPath = section ? `/${section}` : '';
  return `${sectionPath}/article/${article.slug}`;
};

export const getArticleCardFooter = ({
  publishDate,
  timeToRead,
  articleActionTranslated,
}: {
  publishDate?: Scalars['Date'] | undefined;
  timeToRead?: Maybe<number> | undefined;
  articleActionTranslated?: string;
}) => {
  const formattedDate = publishDate
    ? formatAbsoluteDatePreferredFormat(publishDate)
    : '';
  const articleTimeAction = timeToRead
    ? `${timeToRead} ${articleActionTranslated}`
    : '';
  const separator = timeToRead && formattedDate ? ' · ' : '';
  return articleTimeAction + separator + formattedDate;
};
