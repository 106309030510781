import type { Maybe } from '@seek/cmsu-cms-connect';

type Seo = {
  title?: Maybe<string>;
  description?: Maybe<string>;
  noIndex?: Maybe<boolean> | undefined;
  noArchive?: Maybe<boolean> | undefined;
  image?: Maybe<{ url: string }>;
};

export const transformSeoData = (
  seo: Seo,
  fallback?: { title?: string; image?: string; description?: string },
) => {
  const commonData = {
    noIndex: Boolean(seo?.noIndex),
    noArchive: Boolean(seo?.noArchive),
    image: seo?.image?.url || fallback?.image,
  };

  return {
    ...commonData,
    title: seo?.title || fallback?.title,
    description: seo?.description || fallback?.description,
  };
};
