import { BoxShadows, type CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { Badge, Bleed, Box, Stack } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { NumericalBlock } from '../NumericalBlock/NumericalBlock';
import { getBoxShadowProps } from '../../shared/helper';
import { headerImageStyle } from './styles.css';

type Props = Pick<
  CardPrimaryFragment,
  | 'badge'
  | 'paragraph'
  | 'actionGroup'
  | 'border'
  | 'borderRadius'
  | 'numericalBlock'
  | 'textAlignment'
  | 'cardContentAlignment'
> & {
  heading?: React.ComponentProps<typeof Heading> | null;
  image?: {
    url: string;
    alt?: string | null;
  } | null;
};

const Card = ({
  heading,
  paragraph,
  actionGroup,
  image,
  badge,
  border,
  borderRadius,
  numericalBlock,
  cardContentAlignment,
  textAlignment,
}: Props) => (
  <Box
    borderRadius={borderRadius ?? 'standard'}
    overflow="hidden"
    height="full"
    display="flex"
    flexDirection="column"
    justifyContent={cardContentAlignment ?? 'spaceBetween'}
    style={{ padding: `calc(${vars.space.gutter} + 1px)` }}
    textAlign={textAlignment ?? 'left'}
    background="surface"
    data={{ testid: 'CardTestId' }}
    {...getBoxShadowProps(border, BoxShadows.BorderNeutralLight)}
  >
    <Stack space="gutter">
      {image ? (
        <Bleed horizontal="gutter" top="gutter">
          <img
            src={image.url}
            alt={image.alt || undefined}
            className={headerImageStyle}
          />
        </Bleed>
      ) : null}
      <Stack space="medium">
        {badge?.text ? <Badge tone={badge?.tone}>{badge.text}</Badge> : null}
        <Stack space="medium">
          {heading && (
            <Box>
              <Heading {...heading} />
            </Box>
          )}
          {numericalBlock && <NumericalBlock numericalBlock={numericalBlock} />}
          {paragraph && (
            <Paragraph content={paragraph.Paragraph_text?.raw} {...paragraph} />
          )}
        </Stack>
      </Stack>
    </Stack>
    {actionGroup && (
      <Box paddingTop="large">
        <ActionGroup {...actionGroup} />
      </Box>
    )}
  </Box>
);

export { Card };
