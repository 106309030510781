import { Box, Link } from 'braid-design-system';
import React from 'react';

import { logo } from '../styles.css';
import { trackLink } from '../../../shared/header-footer/helpers';

// TODO: Support fallback to generic SEEK logo if no logo is provided
interface Props {
  src: string;
  alt?: string;
  href?: string;
  size: {
    width: number;
    height: number;
  };
}

const Image = ({ src, alt, size }: Exclude<Props, 'href'>) => (
  <Box
    data={{ testid: 'logo-image' }}
    className={logo}
    display="block"
    style={{
      width: size.width,
      maxWidth: size.width || '180px',
      maxHeight: '60px',
      backgroundImage: `url(${src})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: 'contain',
    }}
    aria-label={alt}
  />
);

export const Logo = ({ src, alt, href, size }: Props) => {
  // Return from the toUrl function can be "#!" instead of undefined
  if (!href || href === '#!') {
    return <Image src={src} alt={alt} size={size} />;
  }

  return (
    <Link
      href={href ?? ''}
      aria-label={alt}
      onClick={() =>
        trackLink('navigation_pressed', {
          linkName: 'navigation_pressed',
          linkPosition: 'header',
          linkText: alt || 'logo',
        })
      }
    >
      <Image src={src} alt={alt} size={size} />
    </Link>
  );
};
