import {
  Badge,
  Bleed,
  Box,
  Heading,
  IconClear,
  IconTick,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import translations from './.vocab';

import type { ProfileVisibilityConfigType } from './ProfileVisibilityCardGroup';
import { useTranslations } from '@vocab/react';
export const ProfileVisibilitySettingCard = ({
  profileConfig,
}: {
  profileConfig: ProfileVisibilityConfigType;
}) => {
  const { type, canViewResume, isProfileHidden, isRecommended } = profileConfig;
  return (
    <Stack space="medium">
      <VisibilityCardHeading isRecommended={isRecommended} type={type} />
      <VisibilityCardSubHeading />
      <VisibilitySettingBullets
        canViewResume={canViewResume}
        isProfileHidden={isProfileHidden}
      />
    </Stack>
  );
};

const VisibilityCardHeading = ({
  isRecommended,
  type,
}: {
  isRecommended: boolean;
  type: string;
}) => {
  const { t } = useTranslations(translations);
  return (
    <Box>
      <Inline space="small" alignY="center">
        <Heading level="3">
          {t(type as 'Standard' | 'Limited' | 'Hidden')}
        </Heading>
        {isRecommended && (
          <Bleed vertical="xxsmall">
            <Badge tone="promote">{t('Recommended')}</Badge>
          </Bleed>
        )}
      </Inline>
    </Box>
  );
};

const VisibilityCardSubHeading = () => {
  const { t } = useTranslations(translations);
  return (
    <Box>
      <Text weight="strong">{t('Employer Can:')}</Text>
    </Box>
  );
};

const VisibilitySettingBullets = ({
  canViewResume,
  isProfileHidden,
}: {
  canViewResume: boolean;
  isProfileHidden: boolean;
}) => (
  <Stack space="xxsmall">
    <BulletItem allow={!isProfileHidden} text="See your profile" />
    <BulletItem
      allow={!isProfileHidden}
      text="Contact you with opportunities"
    />
    <BulletItem
      allow={!isProfileHidden && canViewResume}
      text="View your resume"
    />
  </Stack>
);

const BulletItem = ({
  allow,
  text,
}: {
  allow: boolean;
  text:
    | 'See your profile'
    | 'Contact you with opportunities'
    | 'View your resume';
}) => {
  const { t } = useTranslations(translations);
  return (
    <Box display="flex" flexDirection="row" gap="xsmall" alignItems="center">
      <Box flexGrow={0}>
        {allow ? <IconTick size="small" /> : <IconClear size="small" />}
      </Box>
      <Box flexGrow={1}>
        <Text>{t(text)}</Text>
      </Box>
    </Box>
  );
};
