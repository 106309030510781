import type { Badge, Button } from 'braid-design-system';
import type { ComponentProps } from 'react';
import type {
  Language,
  SiteNamesMatchingClassification,
} from '@seek/melways-sites';
import type {
  SectionFooter,
  SectionHeader,
  Theme,
} from '@seek/cmsu-cms-connect';
import type { PageTrackingDataLayer } from '@seek/cmsu-analytics';

declare global {
  interface Window {
    pageTrackingDataLayer: PageTrackingDataLayer;
  }
}

export type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;

export type CandidateSiteName = SiteNamesMatchingClassification<{
  product: 'candidate';
}>;

type RootSites =
  | 'candidate-seek-root'
  | 'candidate-jobsdb-root'
  | 'candidate-jobstreet-root';

export type CandidateSiteNameWithoutRoots = Exclude<
  CandidateSiteName,
  RootSites
>;

export type CandidateRootSites = Extract<CandidateSiteName, RootSites>;

export type AsiaRootSites = Exclude<CandidateRootSites, 'candidate-seek-root'>;

export interface LayoutProps {
  title: string;
  section?: {
    sectionName?: string;
    headerOptions?: SectionHeader;
    footerOptions?: SectionFooter;
    theme?: Theme;
  };
  categoryName?: string;
  seo?: {
    title?: string;
    description?: string;
    image?: string;
    noArchive?: boolean;
    noIndex?: boolean;
  };
  locales?: { locale: string; url: string }[];
}

export enum AUTHENTICATION_TYPES {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  AUTH_PENDING = 'pending',
}

export interface AuthInfo {
  authenticationStatus:
    | AUTHENTICATION_TYPES.AUTH_PENDING
    | AUTHENTICATION_TYPES.AUTHENTICATED
    | AUTHENTICATION_TYPES.UNAUTHENTICATED;
  emailAddress: string;
  firstName: string;
  lastName: string;
  error?: any;
}

export type Environment = 'development' | 'staging' | 'production';

export type Maybe<T> = T | null;

export enum AlignmentType {
  Left = 'left',
  Centre = 'center',
  Right = 'right',
}

export type ActionType = {
  text?: string;
  link?: string;
  tone?: ComponentProps<typeof Button>['tone'];
  alignment?: AlignmentType;
};

export type BadgeType = {
  text: string;
  tone: ComponentProps<typeof Badge>['tone'];
};

export type ArrayElement<ArrayType extends Array<any>> = ArrayType[number];

export type LanguageNoZh = Exclude<Language, 'zh'>;
