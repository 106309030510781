import { CTextTone, Theme, type VideoFragment } from '@seek/cmsu-cms-connect';
import { vars } from 'braid-design-system/css';
import { VideoSrcType } from '../../Video/Video';

export const getThemeTone = (theme?: Theme): CTextTone | undefined => {
  switch (theme) {
    case Theme.Sara:
      return CTextTone.BrandAccent;
    case Theme.Star:
      return CTextTone.Neutral;
    default:
      return undefined;
  }
};

export const getThemeBorder = (theme?: Theme) => {
  switch (theme) {
    case Theme.Sara:
      return 'borderNeutralInverted';
    default:
      return undefined;
  }
};

export const getBackgroundColor = (theme?: Theme) => {
  switch (theme) {
    case Theme.Sara:
      return 'brandAccentSoft';
    case Theme.Star:
      return 'brand';
    default:
      return undefined;
  }
};

export const getContentBackgroundType = (theme?: Theme) => {
  switch (theme) {
    case Theme.Sara:
      return 'customLight';
    case Theme.Star:
      return 'customDark';
    default:
      return undefined;
  }
};

export const getContentBackgroundColor = (theme?: Theme) => {
  switch (theme) {
    case Theme.Sara:
      return vars.backgroundColor.brandAccentSoft;
    case Theme.Star:
      return vars.backgroundColor.brand;
    default:
      return undefined;
  }
};

export const getVideoUrl = (video?: VideoFragment | null) => {
  if (!video) {
    return undefined;
  }

  if (video.source?.__typename === VideoSrcType.Brightcove) {
    return video.source.videoUrl;
  }

  if (video.source?.__typename === VideoSrcType.YouTube) {
    return `https://www.youtube.com/embed/${video?.source?.videoId}`;
  }
};
