import React from 'react';
import { TestimonialCarousel } from './components/TestimonialCarousel/TestimonialCarousel';
import { ContentBlock } from 'braid-design-system';
import type { Testimonials } from './types';
import type { CBoxBackground } from '@seek/cmsu-cms-connect';

export interface TestimonialsCarouselProps {
  testimonials: Testimonials;
  cardBackground?: CBoxBackground | null;
}

export const TestimonialGroup = ({
  testimonials,
  cardBackground,
}: TestimonialsCarouselProps) => (
  <ContentBlock>
    <TestimonialCarousel
      testimonials={testimonials}
      cardBackground={cardBackground}
    />
  </ContentBlock>
);
