import { useTranslations } from '@vocab/react';
import translations from '../../.vocab';
import { useFetchSuggestions } from '../../shared/hooks/useFetchSuggestions';
import { useMelwaysLink } from '@seek/melways-react';
import { useConfig } from '../..//shared/hooks';
import { useState } from 'react';
import { convertMelwaysLocale } from '../../../helpers/convertMelwaysLocale';
import { SearchBox } from '@seek/cmsu-components';
import {
  getSearchPathBy,
  isAboutSectionSearch,
  isCareersSectionSearch,
} from '@seek/cmsu-components/src/utils/searchHelper';

export const SearchBoxWrapper = () => {
  const urlResolver = useMelwaysLink();
  const { t } = useTranslations(translations);
  const { melwaysLocale, language, section } = useConfig();
  const [searchInput, setSearchInput] = useState<string | undefined>();
  const locale = convertMelwaysLocale(melwaysLocale);

  const fetchedSuggestions = useFetchSuggestions(locale, searchInput);
  const isAboutSection = isAboutSectionSearch(section);
  const isCareersSection = isCareersSectionSearch(section);

  const getPlaceholderText = () => {
    if (isAboutSection) {
      return t('Search box news suggestion');
    }
    if (isCareersSection) {
      return t('Search box careers suggestion');
    }
    return t('Search box suggestion');
  };

  return (
    <SearchBox
      placeholderText={getPlaceholderText()}
      searchButtonText={t('Search button text')}
      section={section}
      locale={locale}
      onSearch={(term) => {
        window.location.href = urlResolver({
          language,
          path: `/${getSearchPathBy(section)}/${encodeURIComponent(term)}/1`,
        });
      }}
      minChars={3}
      minCharsErrorMessage={t('Minimum chars error message', {
        minChars: '3',
      })}
      onKeyPress={setSearchInput}
      onSelect={(path) => {
        window.location.href = path;
      }}
      titleSuggestions={fetchedSuggestions}
    />
  );
};
