import {
  CTextAlign,
  Theme,
  type CustomHeader,
  type LinkFragment,
  type Locale,
} from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import React from 'react';
import {
  Box,
  Column,
  Columns,
  Divider,
  Hidden,
  IconChevron,
  PageBlock,
  Spread,
  Stack,
  Text,
} from 'braid-design-system';

import { Logo } from './components/Logo';
import { LanguageToggle } from './components/LanguageToggle';
import { NavLinkGroup } from './components/NavLinkGroup';
import {
  convertLinks,
  getFallbackSeekLogo,
} from '../../shared/header-footer/helpers';
import { MobileMenu } from './components/MobileMenu';
import * as styles from './styles.css';
import { CustomHeaderProvider } from './CustomHeaderProvider';

import { toUrl } from '../../utils';
import translations from '../../.vocab';
import { BurgerMenu } from './components/BurgerMenu';

interface Props
  extends Partial<Pick<CustomHeader, 'divider' | 'links' | 'logo'>> {
  linksAlignment?: CTextAlign;
  language: Language;
  country: Country;
  locale: Locale;
  route: string[];
  theme?: Theme;
}

export const CustomPageHeader = ({
  linksAlignment = CTextAlign.Left,
  country,
  divider,
  route,
  logo,
  links,
  language,
  locale,
  theme = Theme.SeekJobs,
}: Props) => {
  const { t } = useTranslations(translations);
  const [menuOpen, toggleMenuOpen] = React.useState(false);
  const activeRoute = route.join('/');
  const path = activeRoute.split(`/${country}`).pop() || activeRoute;
  const formattedLinks = links && convertLinks(links, language, locale);
  const logoData = logo || getFallbackSeekLogo(locale);
  /* Enable language toggle for Indonesia and Thailand.
  / Temporary fix for melways-react dependency not
  / loading correctly in SARA app and throwing errors. */
  const enableLanguageToggle = country === 'id' || country === 'th';

  return (
    <CustomHeaderProvider
      config={{
        language,
        enableLanguageToggle,
        country,
        path,
        activeRoute,
        links: formattedLinks,
        theme,
      }}
    >
      <Box width="full" background="surface" data={{ testid: 'custom-header' }}>
        <Box
          background="surface"
          className={[styles.menu, menuOpen ? styles.menuOpen : '']}
          width="full"
          height="full"
        >
          <Stack space="none">
            <PageBlock width="large">
              <Box paddingY={{ mobile: 'small', desktop: 'none' }}>
                <Columns space="small">
                  <Column>
                    <Columns
                      space={{
                        mobile: 'small',
                        tablet: 'none',
                        wide: 'large',
                      }}
                      collapseBelow="wide"
                    >
                      {/* Logo image */}
                      <Column width="content">
                        <Box paddingY={{ mobile: 'none', desktop: 'xxsmall' }}>
                          <Logo
                            src={logoData.image.url}
                            alt={logoData.title || ''}
                            href={toUrl(
                              logoData.link?.to as LinkFragment['to'],
                              language,
                            )}
                            size={{
                              width: logoData.image.width as number,
                              height: logoData.image.height as number,
                            }}
                          />
                        </Box>
                      </Column>
                      {/* Nav Links */}
                      <Column hideBelow="desktop">
                        {formattedLinks && (
                          <NavLinkGroup
                            alignLinks={linksAlignment}
                            links={formattedLinks}
                            activeRoute={activeRoute}
                          />
                        )}
                      </Column>
                    </Columns>
                  </Column>
                  {/* Mobile menu button */}
                  <Column width="content" hideAbove="tablet">
                    <Box height="full" display="flex" alignItems="center">
                      {theme !== Theme.SeekJobs ? (
                        <BurgerMenu
                          open={menuOpen}
                          onClick={() => toggleMenuOpen(!menuOpen)}
                        />
                      ) : (
                        <Box
                          component="button"
                          cursor="pointer"
                          arial-label="Menu"
                          aria-expanded={menuOpen}
                          onClick={() => toggleMenuOpen(!menuOpen)}
                        >
                          <Spread
                            space="xxsmall"
                            alignY="center"
                            direction="horizontal"
                          >
                            <Text>{t('Menu')}</Text>
                            <IconChevron
                              size="small"
                              direction={menuOpen ? 'up' : 'down'}
                            />
                          </Spread>
                        </Box>
                      )}
                    </Box>
                  </Column>
                  {/* Language toggle */}
                  {enableLanguageToggle ? (
                    <Column width="content" hideBelow="desktop">
                      <Box
                        height="full"
                        paddingTop={{ desktop: 'medium', wide: 'none' }}
                        display="flex"
                        alignItems={{ desktop: 'flexStart', wide: 'center' }}
                      >
                        <LanguageToggle />
                      </Box>
                    </Column>
                  ) : null}
                </Columns>
              </Box>
            </PageBlock>
            {divider && (
              <Hidden below="desktop">
                <Divider />
              </Hidden>
            )}
          </Stack>
          {/* Mobile menu */}
          <Hidden above="tablet">
            <Divider />
            <MobileMenu open={menuOpen} />
          </Hidden>
        </Box>
      </Box>
    </CustomHeaderProvider>
  );
};
