import { CFormType, type FormFragment } from '@seek/cmsu-cms-connect';
import type { RenderParams } from '@seek/forms-ui';
import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import type { Language } from '@seek/forms-ui/lib/types';
import {
  Alert,
  Box,
  Button,
  FieldMessage,
  Heading,
  IconPositive,
  Inline,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';

import { RichText } from '@seek/cmsu-rich-text';
import type { Country, SiteName } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { renderEmail } from 'react-html-email';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import translations from './.vocab';
import { EmailContentComponent } from './EmailContentComponent';
import { RenderField } from './RenderField';
import type { DynamicFormValues, FormField } from './types';
import { useTrackLink } from '@seek/cmsu-analytics';

type FormProps = RenderParams<DynamicFormValues>;

export interface ComponentProps extends FormProps {
  useField: UseField<DynamicFormValues, Language>;
  country: Country;
  locale: string;
  site: SiteName;
  wptSalesForceApi: string;
  resetForm: () => void;
  sourceName: string;
  privacyUrl: string;
  form: FormFragment;
  recaptchaKey: string;
}

export const Form = ({
  handleSubmit,
  useField,
  resetForm,
  wptSalesForceApi,
  country,
  site,
  sourceName,
  form,
  recaptchaKey,
}: ComponentProps) => {
  const { t } = useTranslations(translations);
  const isMailChimpForm = form.formType === CFormType.MailChimp;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const { language } = useCMSUContext();

  // Tealium Tracking when the form is submitted and receive API response
  const onSubmitLink = useTrackLink('form-submission', {
    elementAction: form.ctaText ?? 'Submit',
    elementText: form.ctaText ?? 'Submit',
    elementDesign: 'button',
  });

  // Tealium Tracking when the button is pressed
  const onSubmitPressed = useTrackLink('button-link', {
    elementAction: form.ctaText ?? 'Submit',
    elementText: form.ctaText ?? 'Submit',
    elementDesign: 'button',
  });

  const onSubmit = handleSubmit((formValues: DynamicFormValues) => {
    onSubmitPressed?.();
    if (!recaptchaValue) {
      setRecaptchaError('Please verify that you are not a robot');
      return;
    }

    let data;
    let url;

    switch (form.formType) {
      case CFormType.SaraEnquiry:
        data = {
          from: 'saras@seek.com.au',
          to: ['saras@seek.com.au'],
          subject: 'SARAs Enquiry',
          message: renderEmail(
            <EmailContentComponent
              name={formValues.name}
              company={formValues.company}
              email={formValues.email}
              enquiry={formValues.enquiry}
              subject="SARAs Enquiry"
            />,
          ),
          recaptcha: recaptchaValue,
        };
        url = `${wptSalesForceApi}/sara`;
        break;
      case CFormType.MailChimp:
        data = {
          ...formValues,
          apiEndpoint: form.apiEndpoint,
          SITECNTRY: country.toUpperCase(),
          recaptcha: recaptchaValue,
        };
        url = `${wptSalesForceApi}/mailChimp-subscription`;
        break;
      default:
        data = {
          ...formValues,
          siteCountry: country.toUpperCase(),
          employerJobSeeker: site.includes('employer')
            ? 'Employer'
            : 'Job Seeker',
          sourcePage: sourceName,
          recaptcha: recaptchaValue,
          ...(form.apiEndpoint === 'hirer-subscription' && {
            dateSubscribed: new Date().toISOString(),
            sourceName,
            siteKey: country.toUpperCase(),
          }),
        };
        url = `${wptSalesForceApi}/${form.apiEndpoint}`;
    }

    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
          onSubmitLink?.();
        } else {
          resetForm();
          setSubscriptionError(true);
        }
      })
      .catch(() => {
        setSubscriptionError(true);
        resetForm();
      });
  });

  return (
    <form onSubmit={onSubmit} style={{ padding: '0px' }}>
      {!isSubmitted ? (
        <Box>
          <Stack space={'medium'}>
            <Heading level="2">{form.title ?? ''}</Heading>
            <Text>{form.description ?? ''}</Text>
            <Tiles
              columns={{
                mobile: 1,
                tablet: form.columns,
                desktop: form.columns,
                wide: form.columns,
              }}
              space="medium"
            >
              {form.fields.map((field, key) => (
                <Box key={field.key}>
                  <RenderField field={field} key={key} useField={useField} />{' '}
                </Box>
              ))}
            </Tiles>

            <ReCAPTCHA
              style={{ paddingTop: '0px', marginTop: '0px' }}
              sitekey={recaptchaKey}
              onChange={(val: any) => {
                setRecaptchaValue(val);
                setRecaptchaError('');
              }}
              hl={language}
            />

            {recaptchaError ? (
              <FieldMessage
                id="recaptcha"
                tone="critical"
                message={recaptchaError}
              />
            ) : (
              <></>
            )}
            {form.consentText && (
              <RichText
                content={form.consentText.raw}
                renderers={{
                  p: ({ children }) => <Text size="xsmall">{children}</Text>,
                }}
              />
            )}
            <Inline space="none">
              <Button
                type="submit"
                id="submitBtn"
                tone={
                  isMailChimpForm
                    ? 'formAccent'
                    : (form.buttonColour as Parameters<
                        typeof Button
                      >[0]['tone']) || undefined
                }
              >
                {form.ctaText || t('Submit')}
              </Button>
            </Inline>

            {subscriptionError && (
              <Box>
                <Alert tone="critical">
                  <Text>Something Wrong</Text>
                </Alert>
              </Box>
            )}
          </Stack>
        </Box>
      ) : (
        <>
          {form.confirmationMessage && isMailChimpForm ? (
            <Box>
              <Stack space="medium">
                <Heading level="2">Subscribe</Heading>
                <Text icon={<IconPositive />}>Thanks for subscribing!</Text>
              </Stack>
            </Box>
          ) : (
            <Box data={{ testid: 'FormConfirmationMessageTestId' }}>
              <RichText content={form.confirmationMessage?.raw} />
            </Box>
          )}
        </>
      )}
    </form>
  );
};

export const gridColumn = (field: FormField) =>
  field.__typename === 'FormTextarea' ||
  field.__typename === 'FormCheckbox' ||
  field.rowSpan
    ? '1 / -1'
    : 'auto';
