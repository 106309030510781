import { Stage, type Locale } from '@seek/cmsu-cms-connect';
import type { DataAttributeMap } from '../modules/types';
import { isDraft, type PreviewQueryProps } from './helper';

export const hygraphQueryHeaders = {
  'hyg-stale-if-error': '86400',
  'hyg-stale-while-revalidate': '300',
};

export const getHygraphLivePreviewProps = (
  id: string | undefined,
  locale: Locale,
  stage: Stage,
): DataAttributeMap =>
  isDraft(stage) && id
    ? {
        'hygraph-entry-id': id,
        'hygraph-field-locale': locale,
      }
    : {};

export const getPreviewQueryProps = (isPreview?: boolean): PreviewQueryProps =>
  isPreview
    ? {
        stage: Stage.Draft,
        fetchPolicy: 'no-cache',
        previewHeaders: {
          'hyg-stale-while-revalidate': '0',
        },
      }
    : {
        stage: Stage.Published,
        fetchPolicy: 'network-only',
        previewHeaders: {},
      };
