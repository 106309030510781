import { Box, Divider, Stack, Tiles } from 'braid-design-system';
import { ProfileSampleCard } from './ProfileSampleCard';
import { ProfileVisibilitySettingCard } from './ProfileVisibilitySettingCard';

export interface ProfileVisibilityConfigType {
  type: string;
  isRecommended: boolean;
  canViewResume: boolean;
  isProfileHidden: boolean;
}

const profileVisibilitySettingConfig: ProfileVisibilityConfigType[] = [
  {
    type: 'Standard',
    isRecommended: true,
    canViewResume: true,
    isProfileHidden: false,
  },
  {
    type: 'Limited',
    isRecommended: false,
    canViewResume: false,
    isProfileHidden: false,
  },
  {
    type: 'Hidden',
    isRecommended: false,
    canViewResume: false,
    isProfileHidden: true,
  },
];

export const ProfileVisibilityCardGroup = () => (
  <Tiles
    columns={{ mobile: 1, tablet: 3 }}
    space={{ mobile: 'xxlarge', tablet: 'medium' }}
  >
    {profileVisibilitySettingConfig.map((profileConfig) => (
      <Box key={profileConfig.type}>
        <Stack space={'medium'}>
          <ProfileVisibilitySettingCard profileConfig={profileConfig} />
          <Divider />
          <ProfileSampleCard profileConfig={profileConfig} />
        </Stack>
      </Box>
    ))}
  </Tiles>
);
