import { Box, Text } from 'braid-design-system';
import React, { type PropsWithChildren } from 'react';
import { vars } from 'braid-design-system/css';

type DataAttributeMap = Record<string, string | number | boolean>;
type BlockQuoteProps = PropsWithChildren<{
  data?: DataAttributeMap | undefined;
}>;
export const BlockQuote = ({ data, children }: BlockQuoteProps) => (
  <Box
    paddingLeft={{ mobile: 'medium', desktop: 'large' }}
    paddingY="xsmall"
    marginY="large"
    style={{
      borderStyle: 'solid',
      borderColor: vars.borderColor.brandAccent,
      borderLeftWidth: vars.borderWidth.large,
    }}
    data={{ ...data, 'hygraph-field-api-id': 'excerpt' }}
  >
    <Text size="large">{children}</Text>
  </Box>
);
