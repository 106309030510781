import {
  Box,
  Column,
  Columns,
  IconChevron,
  Inline,
  Link,
  MenuItemLink,
  MenuRenderer,
  Text,
} from 'braid-design-system';

import { Theme } from '@seek/cmsu-cms-connect';
import { vars } from 'braid-design-system/css';
import { getUrlPath, trackLink } from '../../../shared/header-footer/helpers';
import { useCustomHeaderContext } from '../CustomHeaderProvider';
import * as styles from '../styles.css';
import type { NestedLink } from '../types';

interface Props {
  url: string;
  text: string;
  active: boolean;
}

const getTrackingDataLayer = (): Record<string, string> => {
  const {
    siteSection,
    brand,
    siteCountry,
    siteLanguage,
    zone,
    currentPage,
    isLoggedIn,
    loginId,
  } = window.pageTrackingDataLayer || {};

  return {
    siteSection: siteSection ?? '',
    brand: brand ?? '',
    siteCountry: siteCountry ?? '',
    siteLanguage: siteLanguage ?? '',
    zone: zone ?? '',
    currentPage: currentPage ?? '',
    // Convert boolean and number to string
    isLoggedIn: isLoggedIn ? 'true' : 'false',
    loginId: loginId !== undefined ? String(loginId) : '',
  };
};

export const NavLink = ({ url, text, active }: Props) => {
  const { theme } = useCustomHeaderContext();
  const themeTone = theme === Theme.SeekJobs ? 'neutral' : 'brandAccent';

  return (
    <Box
      height="full"
      display="flex"
      flexDirection="row"
      flexShrink={0}
      flexWrap="nowrap"
      alignItems="center"
      className={[styles.linkContainer, active && styles.linkActive]}
      style={{ height: '100%', minHeight: '76px' }}
    >
      <Text
        size="small"
        weight={active ? 'strong' : 'regular'}
        tone={active ? themeTone : 'secondary'}
      >
        <Link
          href={url}
          onClick={() => {
            trackLink('navigation_pressed', {
              ...getTrackingDataLayer(),
              linkName: 'navigation_pressed',
              linkPosition: 'header',
              linkText: text,
              actionOrigin: `${getTrackingDataLayer().siteSection} header`,
              actionPressed: text,
            });
          }}
        >
          <Box component="span" className={styles.linkText}>
            {text}
          </Box>
        </Link>
      </Text>
      <Box
        style={{ color: vars.backgroundColor[themeTone] }}
        className={styles.linkUnderline[theme]}
      />
    </Box>
  );
};

enum flexAlign {
  left = 'flexStart',
  center = 'center',
  right = 'flexEnd',
}

export const NavLinkGroup = ({
  links,
  alignLinks = 'left',
  activeRoute,
}: {
  links: NestedLink[];
  alignLinks?: 'left' | 'center' | 'right';
  activeRoute: string;
}) => (
  <Box
    display="flex"
    justifyContent={{
      tablet: 'flexStart',
      wide: alignLinks && flexAlign[alignLinks],
    }}
    style={{
      width: '100%',
    }}
    height="full"
  >
    <Inline space="medium" alignY={'center'}>
      {links.map((link, i) => {
        const { url, text, subLinks } = link;
        const isActive = activeRoute === getUrlPath(url);
        const dropdownButtonTracking = (toggleText: string, toggle: string) => {
          trackLink('navigation_pressed', {
            ...getTrackingDataLayer(),
            actionOrigin: `${getTrackingDataLayer().siteSection} header`,
            actionPressed: `Dropdown ${toggleText} menu - toggle ${toggle}`,
          });
        };
        return (
          <Box
            height="full"
            key={i}
            position="relative"
            display="flex"
            alignItems="center"
          >
            <Columns space="xxsmall">
              <Column>
                <NavLink url={url} text={text} active={isActive} />
              </Column>
              {subLinks.length > 0 ? (
                <Column width="content">
                  <Box height="full" display="flex" alignItems="center">
                    <MenuRenderer
                      offsetSpace="small"
                      align="right"
                      onClose={() => {
                        dropdownButtonTracking(text, 'close');
                      }}
                      onOpen={() => {
                        dropdownButtonTracking(text, 'open');
                      }}
                      trigger={(triggerProps, { open }) => (
                        <Box
                          userSelect="none"
                          cursor="pointer"
                          {...triggerProps}
                        >
                          <Text size="small">
                            <IconChevron
                              direction={open ? 'up' : 'down'}
                              alignY="lowercase"
                              tone={isActive ? 'neutral' : 'secondary'}
                            />
                          </Text>
                        </Box>
                      )}
                    >
                      {subLinks.map(
                        (subLink, index) =>
                          subLink.url && (
                            <MenuItemLink
                              key={index}
                              href={subLink.url}
                              onClick={() =>
                                trackLink('navigation_pressed', {
                                  ...getTrackingDataLayer(),
                                  linkName: 'navigation_pressed',
                                  linkPosition: 'header',
                                  linkText: text,
                                  actionOrigin: `${
                                    getTrackingDataLayer().siteSection
                                  } header`,
                                  actionPressed: `${text} - ${subLink.text}`,
                                })
                              }
                            >
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight:
                                    getUrlPath(subLink.url) === activeRoute
                                      ? vars.textWeight.strong
                                      : vars.textWeight.regular,
                                }}
                              >
                                {subLink.text}
                              </span>
                            </MenuItemLink>
                          ),
                      )}
                    </MenuRenderer>
                  </Box>
                </Column>
              ) : null}
            </Columns>
          </Box>
        );
      })}
    </Inline>
  </Box>
);
