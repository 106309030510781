import { useTrackLink } from '@seek/cmsu-analytics';
import type { Article } from '@seek/cmsu-cms-connect';
import { Bleed, Box, Link, Stack, Text } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { resizeImageFromHygraph } from '../../utils/imageHelper';
import { defaultArticleItemFieldVisibility } from '../ArticleItem/ArticleItem';
import type { ArticleItemFieldVisibility } from '../ArticleItem/articleItemHelper';
import { ArticleItemFooter } from '../ArticleItemFooter/ArticleItemFooter';
import { ArticleItemCategory } from '../ArticleItemCategory/ArticleItemCategory';

type Props = {
  article: Article;
  articleLink: string;
  fieldVisibility?: ArticleItemFieldVisibility;
  index?: number;
};

export const ArticleCard = ({
  article,
  articleLink,
  fieldVisibility = defaultArticleItemFieldVisibility(),
  index = 0,
}: Props) => (
  <TrackedLink
    href={articleLink}
    tracking={{
      index: index + 1,
      event: 'image-link',
      elementText: `${article.title} - Position: ${index + 1}`,
      elementLink: articleLink,
      elementDesign: 'link',
    }}
  >
    <Box
      borderRadius="large"
      padding="gutter"
      overflow="hidden"
      height="full"
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      style={{
        border: `2px solid ${vars.borderColor.neutralLight}`,
        gap: vars.space.gutter,
        cursor: 'pointer',
      }}
      textAlign="left"
      background="surface"
      data={{ testid: 'CardTestId' }}
    >
      <Box data={{ testid: 'ArticleFeatureImageTestId' }}>
        <Bleed horizontal="gutter" top="gutter">
          <img
            src={resizeImageFromHygraph({
              imageUrl: article.featureImage[0]?.url,
              width: 480,
              height: 320,
            })}
            alt={article.featureImage[0]?.alt || undefined}
            style={{
              width: '100%',
              height: '160px',
              objectFit: 'cover',
              overflow: 'hidden',
              backgroundColor: vars.backgroundColor.neutralSoft,
            }}
          />
        </Bleed>
      </Box>
      <Box
        height="full"
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={'column'}
      >
        <Box paddingBottom="large">
          <Stack space="gutter">
            <ArticleItemCategory
              article={article}
              fieldVisibility={fieldVisibility}
            />

            <Text
              weight="strong"
              size="large"
              data={{ testid: 'Article_Title_TestId' }}
            >
              {article.title}
            </Text>
            {fieldVisibility.showExcerpt && Boolean(article.excerpt) && (
              <Text size="standard" data={{ testid: 'ArticleExcerptTestId' }}>
                {article.excerpt}
              </Text>
            )}
          </Stack>
        </Box>
        <ArticleItemFooter
          article={article}
          fieldVisibility={fieldVisibility}
        />
      </Box>
    </Box>
  </TrackedLink>
);

interface TrackedLinkProps {
  href: string;
  tracking: {
    index: number;
    event: string;
    elementText: string;
    elementLink: string;
    elementDesign: string;
  };
}

const TrackedLink = ({
  href,
  children,
  tracking,
}: React.PropsWithChildren<TrackedLinkProps>) => {
  const { event, index, ...restTracking } = tracking;
  const trackLink = useTrackLink(event, restTracking);

  const onClick = () => {
    trackLink?.();
  };

  return (
    <Link href={href} onClick={onClick}>
      {children}
    </Link>
  );
};
