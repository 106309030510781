import React from 'react';
import {
  Box,
  ButtonIcon,
  IconChevron,
  IconLanguage,
  IconTick,
  Inline,
  Link,
  MenuItemLink,
  MenuRenderer,
  Spread,
  Stack,
  Text,
} from 'braid-design-system';
import type { Country, Language } from '@seek/melways-sites';
import { useMelwaysLink } from '@seek/melways-react';
import { useCustomHeaderContext } from '../CustomHeaderProvider';

const labels: Partial<Record<Language, string>> = {
  en: 'English',
  id: 'Bahasa',
  th: 'ภาษาไทย',
};

interface Props {
  [key: string]: {
    url: string;
  };
}

const MobileLanguageSwitcher = ({
  language,
  languageSwitcher,
}: {
  language: Language;
  languageSwitcher?: Props;
}) => {
  const [open, toggleOpen] = React.useState(false);

  return (
    <Stack space="none">
      <Box paddingTop="xxsmall" paddingBottom={open ? 'none' : 'xxsmall'}>
        <Spread space="small" alignY="center">
          <Box paddingX="small" width="full" onClick={() => toggleOpen(!open)}>
            <Box paddingY="medium">
              <Inline space="xsmall" alignY={'center'}>
                <IconLanguage size="small" />
                <Text size="small">{labels[language]}</Text>
              </Inline>
            </Box>
          </Box>
          <Box paddingRight="small">
            <ButtonIcon
              id="expand"
              label="expand-group"
              variant="transparent"
              icon={<IconChevron direction={open ? 'up' : 'down'} />}
              onClick={() => toggleOpen(!open)}
            />
          </Box>
        </Spread>
      </Box>
      {open && (
        <Box paddingBottom="small" paddingLeft="small">
          {languageSwitcher &&
            Object.keys(languageSwitcher).map((item) => (
              <Box
                key={item}
                paddingY="small"
                paddingLeft={language === item ? 'none' : 'large'}
              >
                <Inline space="xsmall" alignY="center">
                  {language === item && <IconTick size="xsmall" />}
                  <Text
                    size="small"
                    weight={language === item ? 'strong' : 'regular'}
                  >
                    <Link href={languageSwitcher[item].url}>
                      {labels[item as Language]}
                    </Link>
                  </Text>
                </Inline>
              </Box>
            ))}
        </Box>
      )}
    </Stack>
  );
};

export const LanguageToggle = ({ mobile }: { mobile?: boolean }) => {
  const urlResolver = useMelwaysLink();
  const { country, language, path } = useCustomHeaderContext();
  const getLanguageSwitcher = (c: Country) => {
    if (c !== 'id' && c !== 'th') {
      return undefined;
    }

    return {
      en: {
        url: urlResolver({ language: 'en', path }),
      },

      [country]: {
        url: urlResolver({ language: c, path }),
      },
    };
  };

  const languageSwitcher = getLanguageSwitcher(country as Country);

  if (!languageSwitcher) return null;

  if (mobile)
    return (
      <MobileLanguageSwitcher
        language={language as Language}
        languageSwitcher={getLanguageSwitcher(country as Country)}
      />
    );

  return (
    <Inline space="xsmall" alignY="center">
      <IconLanguage />

      <MenuRenderer
        offsetSpace="small"
        align="right"
        trigger={(triggerProps: any, { open }) => (
          <Box userSelect="none" cursor="pointer" {...triggerProps}>
            <Text size="small">
              {labels[language]}{' '}
              <IconChevron
                direction={open ? 'up' : 'down'}
                alignY="lowercase"
              />
            </Text>
          </Box>
        )}
      >
        {Object.keys(languageSwitcher).map((item) => (
          <MenuItemLink key={item} href={languageSwitcher[item].url}>
            {labels[item as Language]}
          </MenuItemLink>
        ))}
      </MenuRenderer>
    </Inline>
  );
};
