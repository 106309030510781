import { useTranslations } from '@vocab/react';
import {
  Box,
  Disclosure,
  Divider,
  Hidden,
  IconInfo,
  Inline,
  Spread,
  Stack,
  Strong,
  Text,
  TextDropdown,
  Tiles,
} from 'braid-design-system';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useState, type ReactNode } from 'react';
import translations from '../../../../.vocab';
import translations_tal from '../../.vocab';
import {
  datalabHelper,
  type DatalabDriverData,
  type DriverCategory,
  type PrimaryDriverOption,
  type SecondaryDriverOption,
} from '../../datalabHelper';
import { BarChartFilter } from '../BarChart/BarChartFilter';
import { BarChartItem } from '../BarChart/BarChartItem';
import {
  DriverBreakdownProvider,
  useDriverBreakdown,
} from './DriverBreakdownProvider';
import { LevelOfImportance } from './LevelOfImportance/LevelOfImportance';
import { levelOfImportanceHelper } from './LevelOfImportance/levelOfImportanceHelper';
import { HowWeDefineImportance } from '../HowWeDefineImportance/HowWeDefineImportance';
import type { TrackLink } from '../QueryFilter';
import { useDatalab } from '../../DatalabProvider';

const tooltipValue =
  "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.";

interface DriverBreakdownProps {
  drivers: DatalabDriverData;
  trackLink: TrackLink;
}

const barColorList: string[] = ['#3E8FE0', '#072254'];
const maxTiles = 2;

type IndicatorProps = {
  color: string;
  label: string;
};
const Indicator = ({ color, label }: IndicatorProps) => (
  <Inline space="xxsmall" alignY="center">
    <Box
      borderRadius="full"
      style={{ background: color, width: '12px', height: '12px' }}
    />
    <Text>{label}</Text>
  </Inline>
);

type IndicatorGroupProps = {
  categoryOptionsAvailable: string[];
  getBarColor: (name: string) => string;
};
const IndicatorGroup = ({
  categoryOptionsAvailable,
  getBarColor,
}: IndicatorGroupProps) => {
  const { dataTranslations } = useDatalab();
  return (
    <Inline space={{ mobile: 'xsmall', tablet: 'medium' }} alignY={'center'}>
      {categoryOptionsAvailable.map((option) => (
        <Indicator
          key={option}
          color={getBarColor(option)}
          label={datalabHelper.getTranslationFor(dataTranslations, option)}
        />
      ))}
    </Inline>
  );
};

export const DriverBreakdown = ({
  drivers,
  trackLink,
}: DriverBreakdownProps) => {
  const { t } = useTranslations(translations);

  const driverOptions = drivers.driverOptions;

  const availableSortCategoryOptions: string[] =
    datalabHelper.getAvailableSortCategoryOptions(driverOptions);
  const categoryOptionsAvailable: string[] =
    datalabHelper.getAvailableCategoryOptions(driverOptions);

  const getBarColor = (name: string) => {
    const index = categoryOptionsAvailable.findIndex(
      (option) => option === name,
    );
    if (index > -1 && index < barColorList.length) return barColorList[index];
    return barColorList[0];
  };

  if (!driverOptions || driverOptions.length === 0) return;

  const maxResultPercentage: number =
    datalabHelper.getMaxResultPercentageForGivenPrimaryDriverOptions(
      driverOptions,
    );

  const tilesColumns = driverOptions.length >= maxTiles ? maxTiles : 1;

  const categoryRows = driverOptions[0].categories.map(
    (category) => category.name,
  );
  const showDriverTitle = datalabHelper.showDriverTitle(driverOptions);
  const hasNotice = driverOptions.some((driverOption) =>
    driverOption.secondaryDriverOptions.some(
      (item) => item.hasLackOfSampleSizeData || item.hasMissingData,
    ),
  );
  return (
    <DriverBreakdownProvider defaultSortBy={availableSortCategoryOptions[0]}>
      {/* Stack doesnt really hide hidden divs
       */}
      <Box display="flex" flexDirection="column" style={{ gap: '32px' }}>
        {categoryOptionsAvailable.length > 1 && (
          <Stack space="medium">
            <Spread space="medium">
              <IndicatorGroup
                categoryOptionsAvailable={categoryOptionsAvailable}
                getBarColor={getBarColor}
              />
              <SortBy
                sortByLabel={t('Sort by')}
                defaultSortBy={availableSortCategoryOptions[0]}
                categoryOptions={availableSortCategoryOptions}
              />
            </Spread>
            <Divider />
          </Stack>
        )}
        <TabletAndAboveView
          driverOptions={driverOptions}
          getBarColor={getBarColor}
          maxResultPercentage={maxResultPercentage}
          showDriverTitle={showDriverTitle}
          tilesColumns={tilesColumns}
          hasNotice={hasNotice}
          categoryRows={categoryRows}
          trackLink={trackLink}
        />

        <MobileView
          driverOptions={driverOptions}
          getBarColor={getBarColor}
          maxResultPercentage={maxResultPercentage}
          trackLink={trackLink}
          showDriverTitle={false}
          categoryRows={categoryRows}
          tilesColumns={tilesColumns}
          hasNotice={hasNotice}
        />
      </Box>
    </DriverBreakdownProvider>
  );
};
const getCategory = (driverOption: PrimaryDriverOption, categoryRow: string) =>
  driverOption.categories.find((category) => category.name === categoryRow);

const MobileView = ({
  driverOptions,
  getBarColor,
  maxResultPercentage,
  trackLink,
  categoryRows,
  hasNotice,
  tilesColumns,
}: {
  driverOptions: PrimaryDriverOption[];
  getBarColor: (name: string) => string;
  maxResultPercentage: number;
  showDriverTitle: boolean;
  trackLink: TrackLink;
  tilesColumns: number;
  hasNotice: boolean;
  categoryRows: string[];
}) => {
  const { dataTranslations } = useDatalab();
  const multipleDriverOptions = driverOptions.length > 1;

  return (
    <Hidden above="mobile">
      <Stack space="large">
        {hasNotice && (
          <Tiles
            columns={{
              mobile: 1,
              tablet: tilesColumns,
              desktop: tilesColumns,
              wide: tilesColumns,
            }}
            space="medium"
          >
            {driverOptions.map((driverOption) => (
              <SampleDataInfoMessage
                primaryDriverOptionName={driverOption.name}
                secondaryDriverOptions={driverOption.secondaryDriverOptions}
                key={`HEADER_INFO_${driverOption.name}`}
              />
            ))}
          </Tiles>
        )}

        {categoryRows.map((categoryRow) => (
          <Stack space="medium" key={categoryRow}>
            <Text weight="strong">
              {datalabHelper.getTranslationFor(dataTranslations, categoryRow)}
            </Text>
            <Tiles
              columns={{
                mobile: 1,
                tablet: tilesColumns,
                desktop: tilesColumns,
                wide: tilesColumns,
              }}
              space="medium"
            >
              {driverOptions.map((driverOption) => (
                <DriverBreakdownRow
                  key={driverOption.name + categoryRow}
                  category={getCategory(driverOption, categoryRow)}
                  getBarColor={getBarColor}
                  maxResultPercentage={maxResultPercentage}
                  driverOptionName={driverOption.name}
                  showDriverTitle={multipleDriverOptions}
                  showCategoryName={false}
                />
              ))}
            </Tiles>

            <CollapsibleLevelOfImportance
              driverOptions={driverOptions}
              categoryRow={categoryRow}
              tilesColumns={tilesColumns}
              trackLink={trackLink}
            />

            <Box paddingTop="small">
              <Divider />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Hidden>
  );
};

const TabletAndAboveView = ({
  driverOptions,
  getBarColor,
  maxResultPercentage,
  showDriverTitle,
  tilesColumns,
  categoryRows,
  trackLink,
  hasNotice,
}: {
  driverOptions: PrimaryDriverOption[];
  getBarColor: (name: string) => string;
  maxResultPercentage: number;
  showDriverTitle: boolean;
  tilesColumns: number;
  categoryRows: string[];
  trackLink: TrackLink;
  hasNotice: boolean;
}) => (
  <Hidden below="tablet">
    <Stack space="large">
      {showDriverTitle && (
        <Tiles
          columns={{
            mobile: 1,
            tablet: tilesColumns,
            desktop: tilesColumns,
            wide: tilesColumns,
          }}
          space="medium"
        >
          {showDriverTitle &&
            driverOptions.map((driverOption) => (
              <DriverBreakdownHeaderTitle
                driverOption={driverOption}
                key={`HEADER_TITLE_${driverOption.name}`}
              />
            ))}

          {showDriverTitle &&
            driverOptions.map((driverOption) => (
              <Divider key={`HEADER_TITLE_DIVIDER_${driverOption.name}`} />
            ))}
        </Tiles>
      )}
      {hasNotice && (
        <Tiles
          columns={{
            mobile: 1,
            tablet: tilesColumns,
            desktop: tilesColumns,
            wide: tilesColumns,
          }}
          space="medium"
        >
          {driverOptions.map((driverOption) => (
            <SampleDataInfoMessage
              primaryDriverOptionName={driverOption.name}
              secondaryDriverOptions={driverOption.secondaryDriverOptions}
              key={`HEADER_INFO_${driverOption.name}`}
            />
          ))}
        </Tiles>
      )}
      {categoryRows.map((categoryRow) => (
        <Stack space="small" key={categoryRow}>
          <Tiles
            columns={{
              mobile: 1,
              tablet: tilesColumns,
              desktop: tilesColumns,
              wide: tilesColumns,
            }}
            space="medium"
          >
            {driverOptions.map((driverOption) => (
              <DriverBreakdownRow
                category={driverOption.categories.find(
                  (category) => category.name === categoryRow,
                )}
                getBarColor={getBarColor}
                maxResultPercentage={maxResultPercentage}
                driverOptionName={driverOption.name}
                showDriverTitle={false}
                showCategoryName={true}
                key={driverOption.name + categoryRow}
              />
            ))}
          </Tiles>

          <CollapsibleLevelOfImportance
            driverOptions={driverOptions}
            categoryRow={categoryRow}
            tilesColumns={tilesColumns}
            trackLink={trackLink}
          />

          <Box paddingTop="medium">
            <Divider />
          </Box>
        </Stack>
      ))}
    </Stack>
  </Hidden>
);

interface SortByProps {
  sortByLabel: string;
  defaultSortBy: string;
  categoryOptions: string[];
}
const SortBy = ({
  sortByLabel,
  defaultSortBy,
  categoryOptions,
}: SortByProps) => {
  const { sortBy, setSortBy } = useDriverBreakdown();

  if (categoryOptions.length === 1) return;
  return (
    <Box display="flex" justifyContent="flexEnd">
      <Text>
        {sortByLabel}{' '}
        <Strong>
          <TextDropdown
            id="SORT_BY"
            label={sortByLabel}
            value={sortBy || defaultSortBy}
            onChange={setSortBy}
            options={categoryOptions}
          />
        </Strong>
      </Text>
    </Box>
  );
};

interface DriverBreakdownHeaderTitleProps {
  driverOption: PrimaryDriverOption;
}
const DriverBreakdownHeaderTitle = ({
  driverOption,
}: DriverBreakdownHeaderTitleProps) => {
  const { dataTranslations } = useDatalab();
  return (
    <Text weight="strong">
      {datalabHelper.getTranslationFor(dataTranslations, driverOption.name)}
    </Text>
  );
};

interface CollapsibleLevelOfImportance {
  driverOptions?: PrimaryDriverOption[];
  categoryRow: string;
  tilesColumns: number;
  trackLink: TrackLink;
}
export const CollapsibleLevelOfImportance: React.FC<
  CollapsibleLevelOfImportance
> = ({ driverOptions, categoryRow, tilesColumns, trackLink }) => {
  const [showLevelOfImportanceState, setShowLevelOfImportanceState] =
    useState<boolean>(false);
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);
  const [refineBy, setRefineBy] = useState<Record<string, boolean>>({
    must: true,
    delight: true,
    putOff: true,
    neutral: true,
  });

  if (!driverOptions || driverOptions.length === 0) return;

  const importanceOptionsTranslated =
    levelOfImportanceHelper.importanceOptions.map((option) => ({
      ...option,
      label: t_tal(option.label),
    }));
  return (
    <Disclosure
      id={`${driverOptions[0].name}_SUBCATEGORY`}
      expandLabel={t('Show details')}
      collapseLabel={t('Hide details')}
      expanded={showLevelOfImportanceState}
      onToggle={(expanded) => {
        setShowLevelOfImportanceState(expanded);
        if (trackLink) {
          trackLink({
            elementDesign: 'button',
            elementText: expanded ? 'Show Details' : 'Hide Details',
            elementAction: 'expand page section',
            currentPageSection: categoryRow,
          });
        }
      }}
    >
      {showLevelOfImportanceState && (
        <Box paddingTop="xsmall">
          <Stack space="large">
            <HowWeDefineImportance />

            <BarChartFilter
              key={`BARCHART_FILTER_${categoryRow}`}
              filterOptions={importanceOptionsTranslated}
              values={refineBy}
              onChange={(selectedValues) => setRefineBy(selectedValues)}
              trackLink={trackLink}
            />

            <LevelOfImportance
              refineBy={refineBy}
              driverOptions={driverOptions}
              tilesColumns={tilesColumns}
              categoryRow={categoryRow}
            />
          </Stack>
        </Box>
      )}
    </Disclosure>
  );
};

interface DriverBreakdownRowProps {
  category: DriverCategory | undefined;
  maxResultPercentage: number;
  getBarColor: (name: string) => string;
  driverOptionName: string;
  showDriverTitle: boolean;
  showCategoryName: boolean;
}
const DriverBreakdownRow = ({
  category,
  maxResultPercentage,
  getBarColor,
  driverOptionName,
  showDriverTitle = false,
  showCategoryName = true,
}: DriverBreakdownRowProps) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { sortBy } = useDriverBreakdown();
  const { locale, dataTranslations } = useDatalab();

  if (!category) return;

  return (
    <Box>
      <Stack space={{ mobile: 'xsmall', desktop: 'small' }}>
        {showCategoryName && (
          <Text weight="strong">
            {datalabHelper.getTranslationFor(dataTranslations, category.name)}
          </Text>
        )}
        {showDriverTitle &&
          driverOptionName !== undefined &&
          driverOptionName.length > 0 && (
            <Box paddingBottom="xxsmall">
              <Text weight="strong" size="small">
                {datalabHelper.getTranslationFor(
                  dataTranslations,
                  driverOptionName,
                )}
              </Text>
            </Box>
          )}

        {category.driverOptions
          .sort((a) => (a.name === sortBy ? -1 : 1))
          .map((categoryDriverOption) => (
            <BarChartItem
              key={`BAR_${driverOptionName}_${category.name}_${categoryDriverOption.name}`}
              value={categoryDriverOption.percentage}
              maxValue={100}
              label={datalabHelper.getTranslationFor(
                dataTranslations,
                categoryDriverOption.name,
              )}
              color={getBarColor(categoryDriverOption.name)}
              width={`${
                (categoryDriverOption.percentage / maxResultPercentage) * 100 -
                1
              }%`}
              locale={locale}
              showInfoBox
              infoBoxContent={<Text>{t_tal(tooltipValue)}</Text>}
            />
          ))}
      </Stack>
    </Box>
  );
};

interface SampleDataAlertMessageProps {
  primaryDriverOptionName: string;
  secondaryDriverOptions: SecondaryDriverOption[];
}
const SampleDataInfoMessage = ({
  primaryDriverOptionName,
  secondaryDriverOptions,
}: SampleDataAlertMessageProps) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { dataTranslations } = useDatalab();

  const getDriverOptionNames = (
    primary: string,
    secondary: string | undefined,
  ) =>
    datalabHelper.getTranslationFor(dataTranslations, primary) +
    (secondary
      ? `, ${datalabHelper.getTranslationFor(dataTranslations, secondary)}`
      : '');

  const getTranslationFilter = (
    primaryName: string,
    secondaryDriverOption: SecondaryDriverOption,
  ) => ({
    Strong: (children: ReactNode) => <Strong>{children}</Strong>,
    u: (children: ReactNode) => <u>{children}</u>,
    filter: getDriverOptionNames(primaryName, secondaryDriverOption?.name),
  });

  const hasNotice = secondaryDriverOptions.some(
    (item) => item.hasLackOfSampleSizeData || item.hasMissingData,
  );
  if (!hasNotice) return;
  return (
    <Box marginBottom="small">
      <Stack space="gutter">
        {secondaryDriverOptions.map((item) => (
          <>
            {item.hasLackOfSampleSizeData && (
              <InfoMessage key={`INFO_SAMPLE_SIZE_${item.name}`}>
                {t_tal(
                  'Sample size for selected filters is small, so there is greater margin of error.',
                  getTranslationFilter(primaryDriverOptionName, item),
                )}
              </InfoMessage>
            )}

            {item.hasMissingData && (
              <InfoMessage key={`INFO_NO_DATA_${item.name}`}>
                {t_tal(
                  "No data available yet for selected filters. We'll show this when we have enough responses.",
                  getTranslationFilter(primaryDriverOptionName, item),
                )}
              </InfoMessage>
            )}
          </>
        ))}
      </Stack>
    </Box>
  );
};

const InfoMessage = ({ children }: { children: ReactNode }) => (
  <Box>
    <Text size="standard" icon={<IconInfo />} tone="info">
      {children}
    </Text>
  </Box>
);
