import { useEffect, useState } from 'react';
import { useAuth, useConfig } from 'src/client/shared/hooks';
import {
  CMS_PAGE_DISPLAYED_EVENT,
  CONTENT_ELEMENT_PRESSED_EVENT,
  type CoreAnalyticsData,
  deHyphen,
  getLoggedInStatus,
  getPageTrackingData,
  setPreviousSiteSection,
  SOCIAL_LINK_IDS,
  trackEvent,
  type TrackingData,
} from '@seek/cmsu-analytics';

const trackingHandler = (e: MouseEvent) => {
  e.preventDefault();
  const currentTarget = e.currentTarget as HTMLButtonElement;
  const elementAction = currentTarget.ariaLabel?.toLocaleLowerCase() || '';

  trackEvent(CONTENT_ELEMENT_PRESSED_EVENT, {
    elementDesign: 'button',
    elementAction,
    elementText: elementAction,
  });
};

const usePageTracking = (
  currentPage: string,
  slug?: string,
  previousSiteSection?: string,
  sectionsData?: Record<string, string>,
  drivers?: Array<Record<string, unknown>>,
) => {
  const { authenticationStatus, loginId, seekAdvertiserId } = useAuth();
  const { section, site } = useConfig();

  useEffect(() => {
    SOCIAL_LINK_IDS.forEach((id) => {
      const buttonId = document.getElementById(id);
      buttonId?.addEventListener('click', trackingHandler);
    });
    const { isPending } = getLoggedInStatus(authenticationStatus);
    const isCandidate = site.startsWith('candidate');

    if (!isPending) {
      const hasSection = section !== 'page';
      const sectionData: Record<string, string | undefined> = {
        siteSection: hasSection
          ? deHyphen(
              sectionsData?.siteSubsection
                ? sectionsData?.siteSection
                : section,
            )
          : 'other',
        ...(sectionsData?.siteSubsection && {
          siteSubsection: sectionsData.siteSubsection,
        }),
      };

      setPreviousSiteSection(previousSiteSection ?? currentPage);

      const isLoggedIn = authenticationStatus === 'authenticated';

      const trackingProps = {
        [isCandidate ? 'loginId' : 'userId']: loginId,
        ...(seekAdvertiserId ? { seekAdvertiserId } : {}),
      };

      trackEvent(CMS_PAGE_DISPLAYED_EVENT, {
        currentPage,
        ...sectionData,
        isLoggedIn,
        ...(isLoggedIn ? trackingProps : {}),
        ...(drivers ? { drivers } : {}),
      });
    }
    return () => {
      SOCIAL_LINK_IDS.forEach((id) => {
        const buttonId = document.getElementById(id);
        buttonId?.removeEventListener('click', trackingHandler);
      });
    };
  }, [
    authenticationStatus,
    loginId,
    currentPage,
    slug,
    section,
    previousSiteSection,
    seekAdvertiserId,
    site,
    sectionsData?.siteSubsection,
    sectionsData?.siteSection,
    drivers,
  ]);
};

const usePageTrackingData = (currentPage: string): CoreAnalyticsData => {
  const { authenticationStatus, loginId, seekAdvertiserId } = useAuth();
  const { isLoggedIn } = getLoggedInStatus(authenticationStatus);
  const { section, site } = useConfig();
  const hasSection = section !== 'page';
  const sectionData: Record<string, string> = {
    siteSection: hasSection ? deHyphen(section) : 'other',
  };

  const [pageTrackingData, setPageTrackingData] = useState<
    Partial<TrackingData>
  >({
    brand: '',
    isLoggedIn: false,
    loginId: undefined,
    siteCountry: '',
    siteLanguage: '',
    zone: '',
    currentPage: '',
    siteSection: '',
  });

  useEffect(() => {
    const trackingData = getPageTrackingData();
    setPageTrackingData(trackingData);
  }, [authenticationStatus, currentPage, section]);
  const isCandidate = site.startsWith('candidate');

  const trackingProps = {
    [isCandidate ? 'loginId' : 'userId']: loginId,
    ...(seekAdvertiserId ? { seekAdvertiserId } : {}),
  };
  return {
    ...pageTrackingData,
    ...sectionData,
    isLoggedIn,
    currentPage,
    ...(isLoggedIn ? trackingProps : {}),
  } as CoreAnalyticsData;
};

export { usePageTracking, usePageTrackingData };
