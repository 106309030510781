import type {
  ActionGroupFragment,
  BannerItemFragment,
  HeadingFragment,
  ParagraphFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Heading, Stack } from 'braid-design-system';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { Paragraph } from '../Paragraph/Paragraph';
import type { BraidHeadingProps } from '../types';
import { bannerItemContainer, bannerItemInnerContainer } from './style.css';
import { DecorationRenderer } from '../../shared/decorations';

interface BannerItemProps {
  item: BannerItemFragment;
}

export const BannerItem = ({ item }: BannerItemProps) => {
  const {
    c_actionGroup,
    backgroundColor,
    borderRadius,
    heading,
    paragraph,
    bannerItemDecoration,
    disableHorizontalPadding = false,
  } = item;
  // TODO: Make the padding flexible through hygraph
  return (
    <Box
      background={backgroundColor || undefined}
      borderRadius={{
        mobile: 'none',
        desktop: borderRadius || 'standard',
      }}
      paddingX={
        disableHorizontalPadding
          ? undefined
          : { tablet: 'xlarge', mobile: 'medium' }
      }
      paddingY={'xlarge'}
      style={{
        position: 'relative',
        overflow: 'hidden', // Prevents the decoration from overflowing
      }}
      className={disableHorizontalPadding && bannerItemContainer}
    >
      <Box
        className={disableHorizontalPadding && bannerItemInnerContainer}
        paddingX={
          disableHorizontalPadding
            ? { mobile: 'small', desktop: 'medium' }
            : undefined
        }
      >
        <BannerItemContent heading={heading} paragraph={paragraph} />
        {c_actionGroup && <BannerItemActionGroup actionGroup={c_actionGroup} />}
        <DecorationRenderer decorationType={bannerItemDecoration} />
      </Box>
    </Box>
  );
};

const BannerItemContent = ({
  heading,
  paragraph,
}: {
  heading: HeadingFragment | null | undefined;
  paragraph: ParagraphFragment | null | undefined;
}) => (
  <Box zIndex={1} position={'relative'}>
    <Stack space="medium">
      <Heading
        level={heading?.level.toString() as BraidHeadingProps['level']}
        weight={heading?.weight ?? 'regular'}
      >
        {heading?.text}
      </Heading>
      <Paragraph {...paragraph} content={paragraph?.Paragraph_text?.raw} />
    </Stack>
  </Box>
);

const BannerItemActionGroup = ({
  actionGroup,
}: {
  actionGroup: ActionGroupFragment;
}) => {
  const { actions, display, inline } = actionGroup;
  return (
    <Box
      paddingTop={{ mobile: 'small', desktop: 'medium' }}
      position={'relative'}
      zIndex={1}
    >
      <ActionGroup actions={actions} display={display} inline={inline} />
    </Box>
  );
};
