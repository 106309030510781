import { Box, IconHelp, Text, TooltipRenderer } from 'braid-design-system';
import { useId, type FC } from 'react';
import { BarChartItem, type BarChartItemProps } from './BarChartItem';

export type BarChartItemWithToggleDisplay = Pick<
  BarChartItemProps,
  'label' | 'value' | 'color'
> & {
  hidden?: boolean;
};

const BAR_MIN_WIDTH = 23; // 23px

type BarChartGroupProps = {
  label: string;
  /**
   * The shape of items in the BarChartGroup.
   * Each item can have a `label`, `value`, `color`, and an optional `hidden` flag.
   * The `valueFormatter` property can be used to format the value.
   */
  items: BarChartItemWithToggleDisplay[];
  tooltip?: Parameters<typeof TooltipRenderer>[0]['tooltip'];
} & Pick<BarChartItemProps, 'valueFormatter'> &
  Pick<BarChartItemProps, 'locale'>;

/**
 * BarChartGroup component displays a group of bar chart items.
 *
 * @remarks
 * The percentage value is displayed by default. This can be changed via the `valueFormatter` property.
 */
export const BarChartGroup: FC<BarChartGroupProps> = ({
  tooltip,
  label,
  items,
  valueFormatter,
  locale,
}) => {
  const id = useId();
  const visibleItems = items.filter((item) => !item.hidden);
  // we use sum of items instead of visible items because we need to calculate the max value for the bar chart
  const maxTotalValue = items.reduce((sum, item) => sum + item.value, 0);
  const renderBarChartItems = () =>
    visibleItems.map((item, index) => (
      <Box
        key={item.label}
        style={{ flex: `${item.value / maxTotalValue} 0 ${BAR_MIN_WIDTH}px` }}
      >
        <BarChartItem
          {...item}
          showLabel={false}
          showValue={true}
          showTooltip
          valuePosition="bottom"
          valueFormatter={valueFormatter}
          valueFontSize={'small'}
          maxValue={maxTotalValue}
          borderRadiusLeft={index === 0 ? '4px' : '0px'}
          borderRadiusRight={index === visibleItems.length - 1 ? '4px' : '0px'}
          locale={locale}
        />
      </Box>
    ));

  const renderTooltip = () =>
    tooltip && (
      <TooltipRenderer id={id} tooltip={tooltip}>
        {({ triggerProps }) => (
          <Box
            aria-label="Help"
            {...triggerProps}
            style={{ marginLeft: '2px', marginTop: '-6.5px' }}
          >
            <IconHelp size="small" />
          </Box>
        )}
      </TooltipRenderer>
    );

  return (
    <Box>
      {label && (
        <Box marginBottom="xsmall">
          <Text size="small" component="label">
            {label}
          </Text>
        </Box>
      )}
      <Box style={{ display: 'flex', gap: '4px' }}>
        {renderBarChartItems()}
        {renderTooltip()}
      </Box>
    </Box>
  );
};
