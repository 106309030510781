import type { CustomDecoration } from '@seek/cmsu-cms-connect';
import { Box } from 'braid-design-system';
import {
  DecorationRenderer,
  getDecorationRendererBoxProps,
} from './DecorationRenderer';

interface BoxWithDecoratorProps extends React.ComponentProps<typeof Box> {
  customDecoration?: CustomDecoration | null;
}

export const BoxWithDecorator = ({
  customDecoration,
  children,
  ...boxProps
}: BoxWithDecoratorProps) => (
  <Box
    {...boxProps}
    style={{
      ...boxProps.style,
      ...getDecorationRendererBoxProps({ customDecoration }).style,
    }}
    position="relative"
  >
    {customDecoration && (
      <DecorationRenderer decorationType={customDecoration} />
    )}
    <Box zIndex={1} position="relative">
      {children}
    </Box>
  </Box>
);
