import type { Article } from '@seek/cmsu-cms-connect';
import { Badge } from 'braid-design-system';
import type { ArticleItemFieldVisibility } from '../ArticleItem/articleItemHelper';

type Props = {
  article: Article;
  fieldVisibility: ArticleItemFieldVisibility;
};

export const ArticleItemCategory = ({ article, fieldVisibility }: Props) => {
  const category =
    fieldVisibility.showCategory &&
    article.category &&
    Boolean(article.category.name)
      ? article.category.name
      : undefined;

  if (!category) return;

  return (
    <Badge tone="neutral" data={{ testid: 'ArticleCategoryTestId' }}>
      {article.category?.name || 'Category'}
    </Badge>
  );
};
