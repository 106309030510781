import React from 'react';
import { Link } from 'braid-design-system';
import { mapLinkActionHelper } from '@seek/apac-candidate-header-footer';
import { trackEvent } from '@seek/cmsu-analytics';

export const linkRenderer = {
  Link: ({ href, ...props }: { href: string }) => (
    <Link href={href} {...props} />
  ),
  sendLinkEvent: ({
    linkContext: { position, source, openingOrClosing },
  }: {
    linkContext: {
      position: string;
      source: any;
      openingOrClosing?: any;
      isExpanded?: boolean;
    };
  }) => {
    trackEvent('navigation_pressed', {
      linkName: 'navigation_pressed',
      linkPosition: position,
      linkText: source,
      ...mapLinkActionHelper(openingOrClosing, {
        key: 'linkAction',
        opening: 'expand',
        closing: 'collapse',
      }),
    });
  },
};
